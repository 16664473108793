/* eslint-disable react-hooks/rules-of-hooks */

import {
  TransactionInterface_UNSTABLE,
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable
} from 'recoil'
import { produce } from 'immer'
import { extend, pick } from 'lodash'

import {
  runbookCurrentVersionState,
  runbookIdState,
  runbookPermission,
  runbookResponseState_INTERNAL,
  runbookState,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from 'main/recoil/runbook'
import { updateAllChangedTasks } from 'main/recoil/data-access'
import { RunbookShowRunbook } from 'main/services/queries/types'
import { getTasks, TaskListResponseType } from 'main/services/queries/use-tasks'
import { getRunbookVersion, GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'
import {
  RunbookMergeResponse,
  RunbookResponseMetaPermissions,
  RunbookUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModelType } from 'main/data-access/models/types'

// @ts-ignore
export const useGetActiveRunbook: ActiveRunbookModelType['get'] = key => {
  switch (key) {
    case 'id':
      return useRecoilValue(runbookIdState) as RunbookShowRunbook['id']
    case 'current_version':
      return useRecoilValue(runbookCurrentVersionState) as RunbookShowRunbook['current_version']
    default:
      const runbook = useRecoilValue(runbookState)
      return Array.isArray(key) ? pick(runbook, key) : runbook
  }
}

export const useGetActiveRunbookCallback: ActiveRunbookModelType['getCallback'] = keyOrKeys =>
  // @ts-ignore
  useRecoilCallback(({ snapshot }) => async () => {
    switch (keyOrKeys) {
      case 'id':
        return await snapshot.getPromise(runbookIdState)
      case 'current_version':
        return await snapshot.getPromise(runbookCurrentVersionState)
      default:
        const runbook = await snapshot.getPromise(runbookState)
        return Array.isArray(keyOrKeys) ? pick(runbook, keyOrKeys) : runbook
    }
  })

export const useGetActiveRunbookCallbackSync: ActiveRunbookModelType['getCallbackSync'] = keyOrKeys =>
  // @ts-ignore
  useRecoilCallback(({ snapshot }) => () => {
    switch (keyOrKeys) {
      case 'id':
        return snapshot.getLoadable(runbookIdState).getValue()
      case 'current_version':
        return snapshot.getLoadable(runbookCurrentVersionState).getValue()
      default:
        const runbook = snapshot.getLoadable(runbookState).getValue()
        return Array.isArray(keyOrKeys) ? pick(runbook, keyOrKeys) : runbook
    }
  })

// @ts-ignore
export const useActiveRunbookCan: ActiveRunbookModelType['can'] = permission => {
  return useRecoilValue(runbookPermission({ attribute: permission }))
}

// @ts-ignore
export const useActiveRunbookOnAction: ActiveRunbookModelType['onAction'] = action => {
  return action === 'merge' ? useProcessRunbookMergeResponse() : useProcessRunbookUpdateResponse()
}

export const useActiveRunbookCanAction: ActiveRunbookModelType['canAction'] = action => {
  return useActiveRunbookCan(action as keyof RunbookResponseMetaPermissions)
}

export const useActiveRunbookCanActionCallback: ActiveRunbookModelType['canActionCallback'] = action =>
  useRecoilCallback(({ snapshot }) => async () => {
    return await snapshot.getPromise(runbookPermission({ attribute: action as keyof RunbookResponseMetaPermissions }))
  })

export const useActiveRunbookCanActionCallbackSync: ActiveRunbookModelType['canActionCallbackSync'] = action =>
  useRecoilCallback(({ snapshot }) => () => {
    return snapshot
      .getLoadable(runbookPermission({ attribute: action as keyof RunbookResponseMetaPermissions }))
      .getValue()
  })

export const useActiveRunbookLoadableValue: ActiveRunbookModelType['getLoadable'] = () =>
  useRecoilValueLoadable(runbookState)

export const useActiveRunbookLoadableCallback: ActiveRunbookModelType['getLoadableCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(runbookState)
  )

export const useProcessRunbookUpdateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookUpdateResponse) => {
    updateRunbookData(transactionInterface)(response.runbook)
    updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)
  })
}

const updateRunbookData =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (responseRunbook: Partial<RunbookShowRunbook>) => {
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, responseRunbook)
      })
    )
  }

export const useProcessRunbookMergeResponse = () => {
  const updatePageData = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      ({
        taskData,
        runbookVersionData,
        runbookData
      }: {
        taskData: TaskListResponseType
        runbookVersionData: GetRunbookVersionResponse
        runbookData: RunbookShowRunbook
      }) => {
        set(
          taskListResponseState_INTERNAL,
          produce(taskData, draft => {
            extend(draft, taskData)
          })
        )

        set(
          runbookVersionResponseState_INTERNAL,
          produce(runbookVersionData, draft => {
            extend(draft, runbookVersionData)
          })
        )

        set(runbookResponseState_INTERNAL, prev =>
          produce(prev, draft => {
            extend(draft.runbook, runbookData)
          })
        )
      }
  )

  return useRecoilCallback(() => async (response: RunbookMergeResponse) => {
    const responseRunbook = response.runbook
    const responseRunbookId = responseRunbook.id
    const responseRunbookCurrentVersionId = responseRunbook.current_version?.id || responseRunbook.runbook_version_id

    const [taskData, runbookVersionData] = await Promise.all([
      getTasks(responseRunbookId, responseRunbookCurrentVersionId),
      getRunbookVersion(responseRunbookId, responseRunbookCurrentVersionId)
    ])

    updatePageData({
      taskData,
      runbookVersionData,
      runbookData: responseRunbook
    })
  })
}
