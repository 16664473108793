/* eslint-disable react-hooks/rules-of-hooks */
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { extend, keyBy, pick } from 'lodash'
import { eventManager } from 'event-manager'
import { produce } from 'immer'

import {
  runbookVersionResponseState_INTERNAL,
  streamsFlattenedState,
  streamsInternalIdLookupState,
  streamsLookupState,
  streamsPermission,
  streamsPermittedState,
  streamsState,
  streamState
} from 'main/recoil/runbook'
import { StreamChangedStream, StreamListStream } from 'main/services/queries/types'
import { StreamModelType } from 'main/data-access/models/types'
import { setChangedTasks } from 'main/recoil/data-access'
import { filterSelector } from 'main/recoil/shared/filters'
import {
  RunbookStreamCreateResponse,
  RunbookStreamDestroyResponse,
  RunbookStreamUpdateResponse
} from 'main/services/api/data-providers/runbook-types'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const getStream: StreamModelType['get'] = (identifier, keyOrKeys) => {
  let stream: StreamListStream
  if (typeof identifier === 'number') {
    stream = useRecoilValue(streamState({ id: identifier }))
  } else {
    stream = useRecoilValue(streamsInternalIdLookupState)[identifier.internal_id]
  }

  return getStreamReturn(stream, keyOrKeys)
}

export const getStreamCallback: StreamModelType['getCallback'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => async (identifier: any) => {
    let stream: StreamListStream
    if (typeof identifier === 'number') {
      stream = await snapshot.getPromise(streamState({ id: identifier }))
    } else {
      stream = (await snapshot.getPromise(streamsInternalIdLookupState))[identifier.internal_id]
    }
    return getStreamReturn(stream, keyOrKeys)
  })
}

export const getStreamCallbackSync: StreamModelType['getCallbackSync'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => (identifier: any) => {
    let stream: StreamListStream
    if (typeof identifier === 'number') {
      stream = snapshot.getLoadable(streamState({ id: identifier })).getValue()
    } else {
      stream = snapshot.getLoadable(streamsInternalIdLookupState).getValue()[identifier.internal_id]
    }

    return getStreamReturn(stream, keyOrKeys)
  })
}

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

// @ts-ignore
export const getStreams: StreamModelType['getAll'] = (scope, keyOrKeys) => {
  let streams: StreamListStream[]
  switch (scope?.scope) {
    case 'permitted':
      streams = useRecoilValue(streamsPermittedState)
      break
    case 'flattened':
      streams = useRecoilValue(streamsFlattenedState)
      break
    default:
      streams = useRecoilValue(streamsState)
      break
  }

  return getStreamsReturn(streams, keyOrKeys)
}

export const getStreamsCallback: StreamModelType['getAllCallback'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const streams = await snapshot.getPromise(streamsState)
    return getStreamsReturn(streams, keyOrKeys)
  })
}

export const getStreamsCallbackSync: StreamModelType['getAllCallbackSync'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => () => {
    const streams = snapshot.getLoadable(streamsState).getValue()
    return getStreamsReturn(streams, keyOrKeys)
  })
}

/* -------------------------------------------------------------------------- */
/*                                  Lookup                                    */
/* -------------------------------------------------------------------------- */

export const getStreamsLookup: StreamModelType['getLookup'] = () => {
  return useRecoilValue(streamsLookupState)
}

export const getStreamsLookupCallback: StreamModelType['getLookupCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const streamsLookup = await snapshot.getPromise(streamsLookupState)
    return streamsLookup
  })
}

export const getStreamsLookupCallbackSync: StreamModelType['getLookupCallbackSync'] = () => {
  return useRecoilCallback(({ snapshot }) => () => {
    const streamsLookup = snapshot.getLoadable(streamsLookupState).getValue()
    return streamsLookup
  })
}

/* -------------------------------------------------------------------------- */
/*                                     Can                                    */
/* -------------------------------------------------------------------------- */

export const canStream: StreamModelType['can'] = permission => {
  return useRecoilValue(streamsPermission({ attribute: permission }))
}

/* -------------------------------------------------------------------------- */
/*                                     Action                                    */
/* -------------------------------------------------------------------------- */

// @ts-ignore
export const onActionStream: StreamModelType['onAction'] = actionKey => {
  switch (actionKey) {
    case 'create':
      return useProcessStreamCreateResponse()
    case 'update':
      return useProcessStreamUpdateResponse()
    case 'destroy':
      return useProcessStreamDeleteResponse()
  }
}

/* -------------------------------- Internal -------------------------------- */

function getStreamReturn(stream: StreamListStream, keyOrKeys: any) {
  if (!keyOrKeys) return stream
  if (Array.isArray(keyOrKeys)) return pick(stream, keyOrKeys)
  // @ts-ignore
  return stream[keyOrKeys as StreamListStream]
}

const getStreamsReturn = (streams: StreamListStream[], keyOrKeys: any) => {
  if (!keyOrKeys) return streams

  return streams.map(stream => {
    if (Array.isArray(keyOrKeys)) return pick(stream, keyOrKeys)
    // @ts-ignore
    return stream[keyOrKeys]
  })
}

// NOTE: all the streams updates currently need to update the people panel through the even manager. Once that is
// no longer the case, we can transactionalize these updates.

const useProcessStreamCreateResponse = () => {
  return useRecoilCallback(({ snapshot, set }) => async (data: RunbookStreamCreateResponse) => {
    const prevRunbookVersionResponse = await snapshot.getPromise(runbookVersionResponseState_INTERNAL)
    const nextRunbookVersionResponse = produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
      const newStreamListItem = data.meta.changed_streams.find(s => s.id === data.stream.id)
      if (newStreamListItem) {
        // Note: we are not adding data.stream directly as it uses the show serializer
        addStream(newStreamListItem, draftRunbookVersionResponse.meta.streams)
      }

      updateChangedStreams(data.meta.changed_streams, draftRunbookVersionResponse.meta.streams)
      updateVersionStreamsCount(draftRunbookVersionResponse.runbook_version.streams_count, 1)
    })

    set(runbookVersionResponseState_INTERNAL, nextRunbookVersionResponse)

    updatePeoplePanel(nextRunbookVersionResponse.meta.streams)
  })
}

const useProcessStreamUpdateResponse = () => {
  return useRecoilCallback(({ snapshot, set }) => async (data: RunbookStreamUpdateResponse) => {
    const prevRunbookVersionResponse = await snapshot.getPromise(runbookVersionResponseState_INTERNAL)
    const nextRunbookVersionResponse = produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
      updateChangedStreams(data.meta.changed_streams, draftRunbookVersionResponse.meta.streams)
    })
    const runbookComponents = nextRunbookVersionResponse.meta.runbook_components
    const runbookComponentLookup = keyBy(runbookComponents, 'id')

    set(runbookVersionResponseState_INTERNAL, nextRunbookVersionResponse)

    setChangedTasks(set)({ changedTasks: data.meta.changed_tasks, runbookComponentLookup })

    updatePeoplePanel(nextRunbookVersionResponse.meta.streams)
  })
}

const useProcessStreamDeleteResponse = () => {
  return useRecoilCallback(({ set, snapshot }) => async (data: RunbookStreamDestroyResponse) => {
    const prevRunbookVersionResponse = await snapshot.getPromise(runbookVersionResponseState_INTERNAL)
    const nextRunbookVersionResponse = produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
      updateChangedStreams(data.meta.changed_streams, draftRunbookVersionResponse.meta.streams)
      removeDeletedStream(data.stream.id, draftRunbookVersionResponse.meta.streams)
      updateVersionStreamsCount(draftRunbookVersionResponse.runbook_version.streams_count, -1)
    })
    const runbookComponents = nextRunbookVersionResponse.meta.runbook_components
    const runbookComponentLookup = keyBy(runbookComponents, 'id')

    set(runbookVersionResponseState_INTERNAL, nextRunbookVersionResponse)

    setChangedTasks(set)({ changedTasks: data.meta.changed_tasks, runbookComponentLookup: runbookComponentLookup })

    // need to unfilter from a stream that no longer exists if necessary
    const streamFilterValue = (await snapshot.getPromise(filterSelector({ attribute: 'stream' }))) as number[]
    if (streamFilterValue?.includes(data.stream.internal_id)) {
      set(
        filterSelector({ attribute: 'stream' }),
        streamFilterValue.filter(internalId => internalId !== data.stream.internal_id)
      )
    }

    updatePeoplePanel(nextRunbookVersionResponse.meta.streams)
  })
}

const updatePeoplePanel = (streams: StreamListStream[]) => {
  // NOTE: we're using the event emitter here to communicate with people panel
  // specifically which is tightly reliant on external sources for updating data.
  // This is a temporary solution until we can refactor the people panel.
  eventManager.emit('runbook-streams-updated', {
    streams: streams.map(stream => {
      return { id: stream.id, name: stream.name }
    })
  })
}

// TODO: settings_substreams_inherit_color logic
const updateChangedStreams = (changedStreams: StreamChangedStream[], existingStreams: StreamListStream[]) => {
  changedStreams.forEach(changedStream => {
    // Loop through changed streams, if it exists in the master data (incl as a substream), update
    const index = existingStreams.findIndex(stream => stream.id === changedStream.id)
    if (index > -1) {
      // Not a substream
      extend(existingStreams[index], changedStream)
    } else {
      // Substream
      for (let i = 0; i < existingStreams.length; i++) {
        const subStreamIndex =
          existingStreams[i].children?.findIndex(subStream => subStream.id === changedStream.id) || -1

        if (subStreamIndex > -1) {
          extend(existingStreams[i].children[subStreamIndex], changedStream)
          break
        }
      }
    }
  })
}

const removeDeletedStream = (deletedStreamId: number, existingStreams: StreamListStream[]) => {
  const deletedIndex = existingStreams.findIndex(stream => stream.id === deletedStreamId)

  if (deletedIndex > -1) {
    existingStreams.splice(deletedIndex, 1)
  } else {
    for (let i = 0; i < existingStreams.length; i++) {
      const subStreamIndex = existingStreams[i].children?.findIndex(subStream => subStream.id === deletedStreamId) || -1

      if (subStreamIndex > -1) {
        existingStreams[i].children.splice(subStreamIndex, 1)
        break
      }
    }
  }
}

// TODO: fix typings with streams during refactor
const addStream = (stream: StreamChangedStream, existingStreams: StreamListStream[]) => {
  if (stream.parent_id) {
    const parentStream = existingStreams.find(st => st.id === stream.parent_id)

    if (!parentStream) {
      console.warn('Parent stream not found for stream with parent_id', stream)
      return
    }
    // @ts-ignore
    parentStream.children = parentStream.children || []
    // @ts-ignore
    parentStream.children.push(stream)
  } else {
    // @ts-ignore
    existingStreams.push(stream)
  }
}

const updateVersionStreamsCount = (property: number, count: number) => {
  property = Math.max(0, (property ?? 0) + count)
}
