/* eslint-disable react-hooks/rules-of-hooks */

import {
  useActiveRunbookCan,
  useActiveRunbookCanAction,
  useActiveRunbookCanActionCallback,
  useActiveRunbookCanActionCallbackSync,
  useActiveRunbookLoadableCallback,
  useActiveRunbookLoadableValue,
  useActiveRunbookOnAction,
  useGetActiveRunbook,
  useGetActiveRunbookCallback,
  useGetActiveRunbookCallbackSync
} from '../hooks'
import { ActiveRunbookModelType } from './types'

export const ActiveRunbookModel: ActiveRunbookModelType = {
  get: useGetActiveRunbook,
  getCallback: useGetActiveRunbookCallback,
  getCallbackSync: useGetActiveRunbookCallbackSync,

  onAction: useActiveRunbookOnAction,
  // TODO: these canActions mimic the permissions at the moment. When we have more update / merge functionality
  // in react runbook, update these functions so they take account of whether the runbook is in planning mode /
  // dynamic etc. At the time of writing I can't confidently say that that will always be the case
  canAction: useActiveRunbookCanAction,
  canActionCallback: useActiveRunbookCanActionCallback,
  canActionCallbackSync: useActiveRunbookCanActionCallbackSync,

  getLoadable: useActiveRunbookLoadableValue,
  getLoadableCallback: useActiveRunbookLoadableCallback,

  can: useActiveRunbookCan
}
