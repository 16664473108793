import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { LoadingPanel, useNotify } from '@cutover/react-ui'
import { RunbookEdit, RunbookSubmitData } from 'main/components/shared/runbook-edit/runbook-edit'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useRunbookEdit } from 'main/services/queries/use-runbook-edit'
import { usePermissions } from 'main/services/queries/use-permissions'
import {
  useAccount,
  useAccountCustomFieldGroups,
  useAccountCustomFields,
  useAccountCustomFieldUsers,
  useAccountRunbookTypes
} from 'main/services/api/data-providers/account/account-data'
import { usePermittedResources } from 'main/services/queries/use-permitted-resources'
import { useRunbookTypeById } from 'main/components/shared/hooks/runbook'
import { useUpdateRunbook } from 'main/services/queries/use-update-runbook'
import { useLanguage } from 'main/services/hooks'

export const RunbookEditPanel = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | string[] | null>(null)
  const notify = useNotify()
  const { t } = useLanguage('runbook', { keyPrefix: 'editPanel' })

  const { mutateAsync: updateRunbook } = useUpdateRunbook()
  const { accountId: slug } = useParams()
  const [{ runbookId, type }, { closeRightPanel }] = useRightPanelTypeState('runbook-edit')
  const isRunbookEditPanel = type === 'runbook-edit'
  const { runbookTypes } = useAccountRunbookTypes()
  const { account } = useAccount()
  const permissions = usePermissions('runbook-edit')
  const canUpdate = permissions('update')
  const { customFieldsLookup } = useAccountCustomFields()
  const { customFieldUsers } = useAccountCustomFieldUsers()
  const { customFieldGroupsLookup, customFieldGroups } = useAccountCustomFieldGroups()
  const { data: runbookData } = useRunbookEdit(runbookId, slug)
  const runbook = runbookData?.runbook
  const { data: permittedProjectsData, isLoading: isLoadingPermittedProjects } = usePermittedResources({
    resource: 'runbook',
    accountId: account?.id
  })
  const runbookType = useRunbookTypeById(runbook?.runbook_type_id)

  const customFieldProps = {
    customFieldsLookup,
    customFieldUsers,
    customFieldGroupsLookup,
    customFieldGroups
  }

  const handleSubmit = async ({ data, payload, timezone }: RunbookSubmitData) => {
    setSubmitting(true)
    updateRunbook(
      {
        ...data,
        runbook: payload,
        timezone
      },
      {
        onSuccess: () => {
          notify.success(t('updateSuccess'))
          setSubmitting(false)
        },
        onError: response => {
          if (response.errors) {
            setErrorMessage(response.errors)
          }
          notify.error(t('updateError'))
          setSubmitting(false)
        }
      }
    )
  }

  return isRunbookEditPanel &&
    runbook &&
    permittedProjectsData?.projects &&
    runbookType &&
    runbookTypes &&
    closeRightPanel !== null ? (
    <RunbookEdit
      runbook={runbookData.runbook}
      runbookType={runbookType}
      runbookTypes={runbookTypes}
      permittedProjectsData={permittedProjectsData}
      isLoading={isLoadingPermittedProjects}
      onClose={closeRightPanel}
      isRunbookPage={false}
      readOnly={!canUpdate}
      customFieldProps={customFieldProps}
      account={account}
      isSubmitting={isSubmitting}
      apiErrorMessage={errorMessage}
      onSubmit={handleSubmit}
    />
  ) : (
    <LoadingPanel />
  )
}
