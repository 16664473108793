import { useParams } from 'react-router-dom'

import { ScrollContent } from '@cutover/react-ui'
import { RunbookWidgetCollection } from 'main/components/dashboards/dashboard-widget-collection'
import {
  useAccountCustomFields,
  useAccountMetaProperty,
  useAppliedFilters,
  useCurrentUserId,
  useFeaturedComments,
  useGlobalConfigProperty,
  useRunbookVersionUsers,
  useTaskList
} from 'main/recoil/data-access'
import { ActiveAccountModel, ActiveRunbookModel, StreamModel, TaskTypeModel } from 'main/data-access'

export const Dashboard = () => {
  const { dashboardId } = useParams()
  const accountId = ActiveAccountModel.get('id')
  const currentUserId = useCurrentUserId()
  const customFields = useAccountCustomFields()
  const runbook = ActiveRunbookModel.get()
  const streams = StreamModel.getAll()
  const taskTypes = TaskTypeModel.getAll()
  const tasks = useTaskList()
  const taskStages = useGlobalConfigProperty({ attribute: 'taskStageTypes' })
  const dashboards = useAccountMetaProperty({ attribute: 'dashboards' })
  const dashboard = dashboards.find(dashboard => dashboard.id === Number(dashboardId))
  const filtersState = useAppliedFilters()
  const comments = useFeaturedComments()

  const components =
    dashboard?.dashboard_components.map(component => {
      return {
        ...component,
        type: component.component_type,
        custom_fields: customFields,
        current_user: currentUserId,
        runbook,
        streams,
        task_types: taskTypes,
        tasks,
        stage_types: taskStages,
        users: useRunbookVersionUsers,
        comments,
        filters: component.filters && JSON.parse(component.filters),
        task_filters: {
          account_id: accountId.toString(),
          dashboard_id: dashboardId,
          runbook_id: runbook.id.toString(),
          template_type: runbook.template_type,
          timezone: runbook.timezone,
          ...filtersState
        }
      }
    }) || []

  return <ScrollContent>{tasks && <RunbookWidgetCollection components={components} media={'screen'} />}</ScrollContent>
}
