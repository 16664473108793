/* eslint-disable react-hooks/rules-of-hooks */

import { RunbookTeam } from 'main/services/queries/types'
import {
  CollectionModelInterface,
  CollectionModelInterfaceWithCollectionAccessBy,
  CollectionModelInterfaceWithPermissions
} from './types'
import { RunbookTeamsPermissionsResponse } from 'main/recoil/runbook'
import {
  useAllTeamsByTaskId,
  useAllTeamsValue,
  useAllTeamsValueCallback,
  useAllTeamsValueCallbackSync,
  useGetAllTeamsByCallback,
  useGetAllTeamsByCallbackSync,
  useRunbookTeamsLookup,
  useRunbookTeamsLookupCallback,
  useRunbookTeamsLookupCallbackSync,
  useRunbookTeamsPermission,
  useTeamsByIds,
  useTeamValue,
  useTeamValueCallback,
  useTeamValueCallbackSync
} from '../hooks'

export const RunbookTeamsModel: CollectionModelInterface<
  RunbookTeam,
  Extract<RunbookTeam, 'id'>,
  undefined,
  'filtered'
> &
  CollectionModelInterfaceWithPermissions<RunbookTeamsPermissionsResponse> &
  CollectionModelInterfaceWithCollectionAccessBy<RunbookTeam, { taskId?: number; ids?: number[] }> = {
  get: id => useTeamValue(id),
  getCallback: () => useTeamValueCallback(),
  getCallbackSync: () => useTeamValueCallbackSync(),

  getAll: useAllTeamsValue,
  getAllCallback: () => useAllTeamsValueCallback(),
  getAllCallbackSync: () => useAllTeamsValueCallbackSync(),

  getAllBy: ({ taskId, ids }) => {
    if (typeof taskId === 'number') return useAllTeamsByTaskId(taskId)
    if (ids) return useTeamsByIds(ids)

    return useAllTeamsValue()
  },
  getAllByCallback: useGetAllTeamsByCallback,
  getAllByCallbackSync: useGetAllTeamsByCallbackSync,

  getLookup: useRunbookTeamsLookup,
  getLookupCallback: useRunbookTeamsLookupCallback,
  getLookupCallbackSync: useRunbookTeamsLookupCallbackSync,

  can: permission => useRunbookTeamsPermission({ attribute: permission })
}
