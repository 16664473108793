import { useRecoilCallback, useRecoilValue } from 'recoil'

import { CustomFieldGroupModelType } from 'main/data-access/models/types'
import { accountCustomFieldGroupsState, customFieldGroupsLookup } from 'main/recoil/runbook'

// @ts-ignore
export const useGetCustomFieldGroup: CustomFieldGroupModelType['get'] = (identifier: number) => {
  const lookup = useRecoilValue(customFieldGroupsLookup)
  return lookup?.[identifier]
}

// @ts-ignore
export const useGetCustomFieldGroupCallback: CustomFieldGroupModelType['getCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async (identifier: number) => {
    const lookup = await snapshot.getPromise(customFieldGroupsLookup)
    return lookup?.[identifier]
  })
}

// @ts-ignore
export const useGetCustomFieldGroupCallbackSync: CustomFieldGroupModelType['getCallbackSync'] = () => {
  return useRecoilCallback(({ snapshot }) => (identifier: number) => {
    const lookup = snapshot.getLoadable(customFieldGroupsLookup).getValue()
    return lookup?.[identifier]
  })
}

export const useGetCustomFieldGroups: CustomFieldGroupModelType['getAll'] = () =>
  useRecoilValue(accountCustomFieldGroupsState)

export const useGetCustomFieldGroupsCallback: CustomFieldGroupModelType['getAllCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldGroupsState)
  )

export const useGetCustomFieldGroupsCallbackSync: CustomFieldGroupModelType['getAllCallbackSync'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(accountCustomFieldGroupsState).getValue()
  )

export const useGetCustomFieldGroupLookup: CustomFieldGroupModelType['getLookup'] = () =>
  useRecoilValue(customFieldGroupsLookup) ?? []

export const useGetCustomFieldGroupLookupCallback: CustomFieldGroupModelType['getLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldGroupsState)
  )

export const useGetCustomFieldGroupLookupCallbackSync: CustomFieldGroupModelType['getLookupCallbackSync'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(accountCustomFieldGroupsState).getValue()
  )
