/* eslint-disable react-hooks/rules-of-hooks */
import { useRecoilCallback, useRecoilTransaction_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { produce } from 'immer'
import { extend } from 'lodash'

import { getRunbookVersion as getRunbookVersionRequest } from 'main/services/queries/use-runbook-versions'
import { ActiveRunbookVersionModelType } from 'main/data-access/models/types'
import {
  runbookIdState,
  runbookResponseState_INTERNAL,
  runbookState,
  runbookVersionIdState,
  runbookVersionPermission,
  runbookVersionResponseState_INTERNAL,
  runbookVersionState
} from 'main/recoil/runbook'
import {
  RunbookVersionConvertToTemplateResponse,
  RunbookVersionCreateResponse,
  RunbookVersionImportResponse
} from 'main/services/api/data-providers/runbook-types'
import { updateCurrentVersion } from './shared-updates'
import { useTaskListRefetch } from 'main/recoil/data-access'
import { Run, RunbookTemplateType } from 'main/services/queries/types'
import { useNavigateUpdateRunbookVersion } from 'main/services/routing'

const handleUnknownKey = (type: string, fnName: string): never => {
  throw new Error(`Unknown argument passed to ${fnName}: ${type}`)
}

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

// @ts-ignore
export const getRunbookVersion: ActiveRunbookVersionModelType['get'] = key => {
  const rbv = useRecoilValue(runbookVersionState)
  return key === 'id' ? rbv.id : rbv
}

export const getRunbookVersionCallback: ActiveRunbookVersionModelType['getCallback'] = key =>
  // @ts-ignore
  useRecoilCallback(({ snapshot }) => async () => {
    const rbv = await snapshot.getPromise(runbookVersionState)
    return key === 'id' ? rbv.id : rbv
  })

export const getRunbookVersionCallbackSync: ActiveRunbookVersionModelType['getCallbackSync'] = key =>
  // @ts-ignore
  useRecoilCallback(({ snapshot }) => () => {
    const rbv = snapshot.getLoadable(runbookVersionState).getValue()
    return key === 'id' ? rbv.id : rbv
  })

/* -------------------------------------------------------------------------- */
/*                                  Loadable                                  */
/* -------------------------------------------------------------------------- */

export const getRunbookVersionLoadable: ActiveRunbookVersionModelType['getLoadable'] = () =>
  useRecoilValueLoadable(runbookVersionState)

export const getRunbookVersionLoadableCallback: ActiveRunbookVersionModelType['getLoadableCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(runbookVersionState)
  )

/* -------------------------------------------------------------------------- */
/*                                     Can                                    */
/* -------------------------------------------------------------------------- */
export const canRunbookVersion: ActiveRunbookVersionModelType['can'] = key => {
  return useRecoilValue(runbookVersionPermission({ attribute: key }))
}

/* -------------------------------------------------------------------------- */
/*                                   Reload                                   */
/* -------------------------------------------------------------------------- */
export const reloadRunbookVersion: ActiveRunbookVersionModelType['reload'] = () =>
  useRecoilCallback(({ snapshot, set }) => async () => {
    const rbId = await snapshot.getPromise(runbookIdState)
    const rbvId = await snapshot.getPromise(runbookVersionIdState)

    set(runbookVersionResponseState_INTERNAL, await getRunbookVersionRequest(rbId, rbvId))
  })

export const reloadRunbookVersionSync: ActiveRunbookVersionModelType['reloadSync'] = () => () => {
  window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
}

/* -------------------------------------------------------------------------- */
/*                                   Action                                   */
/* -------------------------------------------------------------------------- */

const canConvertToTemplate = (templateType: RunbookTemplateType, run: Run | null) => {
  const isPlanning = !['rehearsal', 'live'].includes(run?.run_type || '')
  return isPlanning && templateType === 'off'
}

export const canActionRunbookVersion: ActiveRunbookVersionModelType['canAction'] = actionKey => {
  switch (actionKey) {
    case 'convert_to_template':
      const { template_type } = useRecoilValue(runbookState)
      const { run } = getRunbookVersion()

      return canConvertToTemplate(template_type, run)
    case 'runbook_version_import':
      return canRunbookVersion('import')
    case 'create':
      // TODO: WARNING: not yet implemented as a feature at time of writing; this hasn't been double checked.
      return canRunbookVersion('create')
    default:
      return handleUnknownKey(actionKey, 'canActionRunbookVersion')
  }
}

export const canActionRunbookVersionCallback: ActiveRunbookVersionModelType['canActionCallback'] = actionKey => {
  const runbookVersionValueCb = getRunbookVersionCallback()

  return useRecoilCallback(({ snapshot }) => async () => {
    switch (actionKey) {
      case 'convert_to_template':
        const rbv = await runbookVersionValueCb()
        const rb = await snapshot.getPromise(runbookState)
        return canConvertToTemplate(rb.template_type, rbv.run)
      case 'create':
        // TODO: WARNING: not yet implemented as a feature at time of writing; this hasn't been double checked.
        return await snapshot.getPromise(runbookVersionPermission({ attribute: 'create' }))
      case 'runbook_version_import':
        return await snapshot.getPromise(runbookVersionPermission({ attribute: 'import' }))
      default:
        return handleUnknownKey(actionKey, 'canActionRunbookVersionCallback')
    }
  })
}

export const canActionRunbookVersionCallbackSync: ActiveRunbookVersionModelType['canActionCallbackSync'] =
  actionKey => {
    const runbookVersionValueCbSync = getRunbookVersionCallbackSync()

    return useRecoilCallback(({ snapshot }) => () => {
      switch (actionKey) {
        case 'convert_to_template':
          const rbv = runbookVersionValueCbSync()
          const rb = snapshot.getLoadable(runbookState).getValue()
          return canConvertToTemplate(rb.template_type, rbv.run)
        case 'create':
          // TODO: WARNING: not yet implemented as a feature at time of writing; this hasn't been double checked.
          return snapshot.getLoadable(runbookVersionPermission({ attribute: 'create' })).getValue()
        case 'runbook_version_import':
          return snapshot.getLoadable(runbookVersionPermission({ attribute: 'import' })).getValue()
        default:
          return handleUnknownKey(actionKey, 'canActionRunbookVersionCallback')
      }
    })
  }

// @ts-ignore
export const onActionRunbookVersion: ActiveRunbookVersionModelType['onAction'] = actionKey => {
  switch (actionKey) {
    case 'create':
      return useProcessRunbookVersionCreateResponse()
    case 'convert_to_template':
      return useProcessRunbookVersionConvertToTemplateResponse()
    case 'runbook_version_import':
      return useProcessRunbookVersionCsvImportResponse()
  }
}

const useProcessRunbookVersionCreateResponse = () => {
  const navigateToNewVersion = useNavigateUpdateRunbookVersion()
  const process = useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookVersionCreateResponse) => {
    const { set } = transactionInterface

    updateCurrentVersion(transactionInterface)(response.runbook_version)

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
      })
    )
  })

  return (response: RunbookVersionCreateResponse) => {
    process(response)
    const currentVersionId = (response as RunbookVersionCreateResponse).runbook_version.id
    navigateToNewVersion(currentVersionId)
  }
}

const useProcessRunbookVersionConvertToTemplateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookVersionConvertToTemplateResponse) => {
    const { set } = transactionInterface

    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        draftRunbookResponse.runbook.is_template = response.meta.runbook_is_template
        draftRunbookResponse.runbook.template_status = response.meta.runbook_template_status
        if (response.meta.runbook_is_template) draftRunbookResponse.runbook.template_type = 'default'
      })
    )

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
        extend(draftRunbookVersionResponse.meta.permissions, response.meta.permissions)
      })
    )
  })
}

const useProcessRunbookVersionCsvImportResponse = () => {
  const refetchRunbookVersion = reloadRunbookVersion()
  // TODO: replace with new task hook when completed
  const refetchTasks = useTaskListRefetch()

  // FIXME: seems wrong. we don't updeate the runbook state's current_version and runbook_version_id
  return useRecoilCallback(({ set }) => async (data: RunbookVersionImportResponse) => {
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, data.runbook_version)
      })
    )

    await refetchRunbookVersion()
    await refetchTasks()
  })
}
