import { useCallback, useMemo } from 'react'

import { customFieldValidation } from './custom-field-validation'
import { SearchableCustomFieldOption } from 'main/services/api/data-providers/user/user-channel-response-types'
import {
  CustomField,
  CustomFieldApplyToSlug,
  CustomFieldDisplayType,
  CustomFieldGroup,
  FieldValue,
  FieldValuesAttributes,
  IntegrationActionItem
} from 'main/services/queries/types'

type CustomFieldFormProps = {
  applyToSlugs: CustomFieldApplyToSlug[]
  customFieldsLookup?: Record<CustomField['id'], CustomField>
  customFieldGroups?: CustomFieldGroup[]
  alwaysNotRequired?: boolean
  excludeTransientFieldValues?: boolean // used in duplicate runbooks
}

export const useCustomFieldForm = ({
  applyToSlugs,
  customFieldsLookup = {},
  fieldValues = [],
  customFieldGroups = [],
  alwaysNotRequired = false,
  constraintContext = {},
  integrationActionItem,
  excludeTransientFieldValues = false
}: CustomFieldFormProps & {
  fieldValues?: FieldValue[]
  constraintContext?: Record<string, any>
  integrationActionItem?: IntegrationActionItem
}) => {
  const { getFieldValues, fieldValueValidation, buildFieldValuesAttributesRequestData, getCustomFieldData } =
    useCustomFieldFormCallback({
      applyToSlugs,
      customFieldsLookup,
      customFieldGroups,
      alwaysNotRequired,
      excludeTransientFieldValues
    })

  const fieldData = getCustomFieldData(integrationActionItem, constraintContext)

  return {
    fieldValueValidation,
    buildFieldValuesAttributesRequestData,
    initialFieldValues: getFieldValues(fieldValues),
    data: fieldData
  }
}

/**
 * Custom field hook with callback functions to dynamically update field values.
 * For instance, the task edit form must be updated in response to an integration
 * updating the field values of a task.
 */
export const useCustomFieldFormCallback = ({
  applyToSlugs,
  customFieldsLookup = {},
  customFieldGroups = [],
  alwaysNotRequired = false,
  excludeTransientFieldValues = false
}: CustomFieldFormProps) => {
  /**
   * Create unique keys for field values based on the custom field id.
   * For searchable custom fields, group primary and dependent field values as
   * an array under the primary custom field key.
   */
  const getFieldValues = useCallback(
    (fieldValues: FieldValue[]) => {
      const result: Record<CustomField['id'], any> = {}
      for (const obj of fieldValues) {
        const key = obj.custom_field_id
        const referenceCF = customFieldsLookup[key]

        if (excludeTransientFieldValues && referenceCF.options?.transient) {
          continue
        }
        if (referenceCF.type === 'SearchableCustomField' || referenceCF.type === 'MultiSearchableCustomField') {
          result[key] = result[key] || { value: [] }
          let target = result[key].value.find((field: SearchableCustomFieldOption) => field.primaryKey === obj.value)
          if (!target) {
            target = {
              id: obj.id,
              primaryKey: obj.value || '',
              updatedAt: obj.updated_at,
              values: {}
            }
            result[key].value.push(target)
          }
          target.values[referenceCF.name || ''] = obj.value
        } else if (referenceCF.type === 'DependentCustomField' && referenceCF.source_custom_field_id) {
          const sourceCF = customFieldsLookup[referenceCF.source_custom_field_id]
          const sourceCFKey = sourceCF.id
          result[sourceCFKey] = result[sourceCFKey] || { value: [] }

          // TODO: use a field value lookup
          const sourceFV = fieldValues.find(fv => fv.id === obj.primary_field_value_id)
          if (sourceFV) {
            let target = result[sourceCFKey].value.find(
              (field: SearchableCustomFieldOption) => field.primaryKey === sourceFV.value
            )
            if (!target) {
              target = {
                id: obj.id,
                primaryKey: sourceFV.value || '',
                updatedAt: sourceFV.updated_at,
                values: {}
              }
              result[sourceCFKey].value.push(target)
            }
            target.values[referenceCF.name || ''] = obj.value
          }
        } else {
          result[key] = obj
        }
      }
      return result
    },
    [customFieldsLookup]
  )

  const cfIdToGroupIds = useMemo(
    () =>
      customFieldGroups.reduce<Record<CustomField['id'], CustomFieldGroup['id'][]>>((acc, next) => {
        ;(next.custom_field_ids || []).forEach(id => {
          if (!acc[id]) {
            acc[id] = []
          }
          acc[id].push(next.id)
        })

        return acc
      }, {}),
    [customFieldGroups]
  )

  const getCustomFieldData = useCallback(
    (integrationActionItem?: IntegrationActionItem, constraintContext: {} = {}) => {
      return Object.keys(customFieldsLookup).reduce<{
        groupedCustomFields: Record<CustomFieldGroup['id'], CustomField[]>
        customFields: CustomField[]
        integrationGroupedCustomFields: Record<IntegrationActionItem['id'], CustomField[]>
        context: { includesRequiredCfs: boolean }
      }>(
        (acc, next) => {
          const cf = customFieldsLookup[next as unknown as number]
          const hasIntegrationActionItem =
            integrationActionItem && cf.constraint && cf.constraint.hasOwnProperty('integration_action_item_id')

          if (!applyToSlugs.includes(cf.apply_to.slug) || cf.archived) {
            return acc
          }

          // in order to identify remote custom field data; not processable in reactApp
          if (cf.parent_field_id) {
            console.warn(
              'Warning: This form includes a remote custom field. Remote custom field support will be deprecated soon. Custom field name: ',
              cf.name
            )
          }

          if (hasIntegrationActionItem) {
            if (!cf.constraint.integration_action_item_id.includes(Number(integrationActionItem.id))) {
              return acc
            }
          } else if (!matchesConstraints(cf.constraint, constraintContext)) {
            return acc
          }

          if (!acc.context.includesRequiredCfs && cf.required) acc.context.includesRequiredCfs = true

          if (cfIdToGroupIds[cf.id]) {
            cfIdToGroupIds[cf.id].forEach(groupId => {
              if (!acc.groupedCustomFields[groupId]) {
                acc.groupedCustomFields[groupId] = []
              }
              acc.groupedCustomFields[groupId].push(cf)
            })
            return acc
          }

          if (hasIntegrationActionItem) {
            if (!acc.integrationGroupedCustomFields[integrationActionItem.id]) {
              acc.integrationGroupedCustomFields[integrationActionItem.id] = []
            }
            acc.integrationGroupedCustomFields[integrationActionItem.id].push(cf)
            return acc
          }

          acc.customFields = [...acc.customFields, cf]
          return acc
        },
        {
          groupedCustomFields: {},
          customFields: [],
          integrationGroupedCustomFields: {},
          context: { includesRequiredCfs: false }
        }
      )
    },
    [customFieldsLookup, applyToSlugs, cfIdToGroupIds]
  )

  // WARNING: Please note that when updating custom fields, you should use the property
  // 'field_values_attributes' rather than 'field_values'
  const buildFieldValuesAttributesRequestData = useCallback(
    (fieldValues: Record<CustomField['id'], FieldValue> = {}, isDuplicate = false) => {
      return Object.keys(fieldValues).reduce<FieldValuesAttributes[]>((acc, next) => {
        const id = next as unknown as number
        const cf = customFieldsLookup[id] as CustomField
        const fv = fieldValues[id] as FieldValue

        if (isDuplicate) {
          fv.id = null
        }
        const cfSlug =
          cf.type === 'SearchableCustomField' || cf.type === 'MultiSearchableCustomField'
            ? 'searchable'
            : cf.field_type.slug
        switch (cfSlug) {
          case 'text':
            if (!fv.value) {
              if (fv.custom_field_id === cf.id) {
                return [...acc, createFieldValueWithDestroyFlag(fv)]
              }
              return acc
            }
            return [
              ...acc,
              {
                custom_field_id: cf.id,
                data_source_value_id: null,
                value: fv.value,
                id: fv.id || null
              }
            ]
          case 'searchable':
            if ((!fv.value && fv.field_option_id === undefined) || (!fv.value && fv.field_option_id === null)) {
              if (fv.custom_field_id === cf.id) {
                return [...acc, createFieldValueWithDestroyFlag(fv)]
              }
              return acc
            }
            const scfValues = fv.value as unknown as SearchableCustomFieldOption[]
            const result: FieldValuesAttributes[] = []

            scfValues.forEach(scfValue => {
              const option: FieldValuesAttributes = {
                custom_field_id: cf.id,
                data_source_value_id: scfValue.dataSourceValueId,
                value: scfValue.primaryKey,
                id: isDuplicate ? null : scfValue.id
              }
              if (scfValue.destroy) {
                option._destroy = true
              }
              result.push(option)
            })
            return [...acc, ...result]
          case 'user_select':
          case 'checkboxes':
            if (!fv.value) {
              return acc
            } else if (fv.value === '[]' && fv.id) {
              if (fv.custom_field_id === cf.id) {
                return [...acc, createFieldValueWithDestroyFlag(fv)]
              }
              return acc
            } else {
              return [
                ...acc,
                {
                  custom_field_id: cf.id,
                  data_source_value_id: null,
                  value: fv.value ? fv.value : '[]',
                  id: fv.id || null
                }
              ]
            }
          case 'textarea':
          case 'datetime':
          case 'endpoint': // TODO: remove when no longer supported
          case 'temporary': // TODO: remove when confirmation from integrations team that no one is using it
            if (!fv.value) {
              if (fv.custom_field_id === cf.id) {
                return [...acc, createFieldValueWithDestroyFlag(fv)]
              }
              return acc
            }
            return [
              ...acc,
              {
                custom_field_id: cf.id,
                data_source_value_id: null,
                value: fv.value,
                id: fv.id || null
              }
            ]

          case 'select_menu':
          case 'radiobox':
            if (fv.field_option_id === undefined || fv.field_option_id === null) {
              if (fv.custom_field_id === cf.id) {
                return [...acc, createFieldValueWithDestroyFlag(fv)]
              }
              return acc
            }
            return [
              ...acc,
              {
                custom_field_id: cf.id,
                data_source_value_id: null,
                field_option_id: fv.field_option_id.toString(),
                id: fv.id || null
              }
            ]
          default:
            return unhandledCFTypeError(cf.field_type.slug)
        }
      }, [])
    },
    [customFieldsLookup]
  )

  const validation = useMemo(
    () => customFieldValidation(customFieldsLookup, alwaysNotRequired),
    [alwaysNotRequired, customFieldsLookup]
  )

  return {
    fieldValueValidation: validation,
    buildFieldValuesAttributesRequestData,
    getFieldValues: (fieldValues: FieldValue[]) => getFieldValues(fieldValues),
    getCustomFieldData: (integrationActionItem?: IntegrationActionItem, constraintContext?: {}) =>
      getCustomFieldData(integrationActionItem, constraintContext)
  }
}

const unhandledCFTypeError = (type: CustomFieldDisplayType): never => {
  throw new Error(`Custom field type ${type} not handled. If this is expected please handle it by returning null`)
}

const matchesConstraints = (cfConstraint: any, constraintContext: Record<string, any>) => {
  if (!constraintContext || !cfConstraint) {
    return true
  }

  if (constraintContext.hasOwnProperty('task_type_id') && cfConstraint.hasOwnProperty('task_type_id')) {
    return cfConstraint.task_type_id.includes(Number(constraintContext.task_type_id))
  }

  if (
    constraintContext.hasOwnProperty('integration_action_item_id') &&
    cfConstraint.hasOwnProperty('integration_action_item_id')
  ) {
    return cfConstraint.integration_action_item_id.includes(Number(constraintContext.integration_action_item_id))
  }

  // TODO: revisit and exlude event_type_id constraints when events are deprecated?
  if (constraintContext.hasOwnProperty('event_type_id') && cfConstraint.hasOwnProperty('event_type_id')) {
    return cfConstraint.event_type_id.includes(Number(constraintContext.event_type_id))
  }

  if (constraintContext.hasOwnProperty('runbook_type_id') && cfConstraint.hasOwnProperty('runbook_type_id')) {
    return cfConstraint.runbook_type_id.includes(Number(constraintContext.runbook_type_id))
  }

  return false
}

const createFieldValueWithDestroyFlag = (fv: FieldValue) => ({
  custom_field_id: fv.custom_field_id,
  data_source_value_id: null,
  _destroy: 'true',
  id: fv.id || null
})
