/* eslint-disable react-hooks/rules-of-hooks */

import { RunbookViewStateType } from 'main/recoil/runbook'
import {
  useCanInitiateBulkEditActions,
  useCanInitiateBulkEditActionsCallback,
  useCanInitiateBulkEditActionsCallbackSync,
  useCanInitiateBulkProgressionActions,
  useCanInitiateBulkProgressionActionsCallback,
  useCanInitiateBulkProgressionActionsCallbackSync,
  useIntegrationRequestAdd,
  useIntegrationRequestRemove,
  useIntegrationRequestValue,
  useIntegrationRequestValueCallback,
  useIntegrationRequestValueCallbackSync,
  useLoadingIdAdd,
  useLoadingIdAddBulk,
  useLoadingIdRemove,
  useLoadingIdRemoveBulk,
  useLoadingIdsValue,
  useLoadingIdsValueCallback,
  useLoadingIdsValueCallbackSync,
  useMenuClear,
  useMenuClose,
  useMenuOpen,
  useMenuToggleTaskAction,
  useMenuToggleTaskDependencies,
  useMenuValue,
  useMenuValueCallback,
  useMenuValueCallbackSync,
  useModalClose,
  useModalContinue,
  useModalOpen,
  useModalUpdate,
  useModalValue,
  useModalValueCallback,
  useModalValueCallbackSync,
  useSelectedIdAdd,
  useSelectedIdBulkRemove,
  useSelectedIdRemove,
  useSelectedIdsOverwrite,
  useSelectedIdsRemoveAll,
  useSelectedIdsSelectAll,
  useSelectedIdsToggleAll,
  useSelectedIdsValue,
  useSelectedIdsValueCallback,
  useSelectedIdsValueCallbackSync,
  useSelectedIdToggle,
  useTaskCreateCloseForm,
  useTaskCreateOpenForm,
  useTaskCreateSetName,
  useTaskCreateToggleForm,
  useTaskCreateValue,
  useTaskCreateValueCallback,
  useTaskCreateValueCallbackSync
} from '../hooks'
import { ViewModelInterface } from './types'

type RunbookViewModelPermissions = 'initiate:bulkProgressionActions' | 'initiate:bulkEditActions'
type RunbookViewModelActionHandlers = {
  'selectedIds:add': ReturnType<typeof useSelectedIdAdd>
  'selectedIds:remove': ReturnType<typeof useSelectedIdRemove>
  'selectedIds:bulkRemove': ReturnType<typeof useSelectedIdBulkRemove>
  'selectedIds:toggle': ReturnType<typeof useSelectedIdToggle>
  'selectedIds:overwrite': ReturnType<typeof useSelectedIdsOverwrite>
  'selectedIds:selectAll': ReturnType<typeof useSelectedIdsSelectAll>
  'selectedIds:removeAll': ReturnType<typeof useSelectedIdsRemoveAll>
  'selectedIds:toggleAll': ReturnType<typeof useSelectedIdsToggleAll>
  'loadingIds:add': ReturnType<typeof useLoadingIdAdd>
  'loadingIds:remove': ReturnType<typeof useLoadingIdRemove>
  'loadingIds:bulkAdd': ReturnType<typeof useLoadingIdAddBulk>
  'loadingIds:bulkRemove': ReturnType<typeof useLoadingIdRemoveBulk>
  'modal:open': ReturnType<typeof useModalOpen>
  'modal:close': ReturnType<typeof useModalClose>
  'modal:continue': ReturnType<typeof useModalContinue>
  'modal:update': ReturnType<typeof useModalUpdate>
  'taskMenu:open': ReturnType<typeof useMenuOpen>
  'taskMenu:toggle:actions': ReturnType<typeof useMenuToggleTaskAction>
  'taskMenu:toggle:dependencies': ReturnType<typeof useMenuToggleTaskDependencies>
  'taskMenu:close': ReturnType<typeof useMenuClose>
  'taskMenu:clear': ReturnType<typeof useMenuClear>
  'integrationRequest:add': ReturnType<typeof useIntegrationRequestAdd>
  'integrationRequest:remove': ReturnType<typeof useIntegrationRequestRemove>
  'taskCreate:toggle': ReturnType<typeof useTaskCreateToggleForm>
  'taskCreate:open': ReturnType<typeof useTaskCreateOpenForm>
  'taskCreate:close': ReturnType<typeof useTaskCreateCloseForm>
  'taskCreate:setName': ReturnType<typeof useTaskCreateSetName>
}

const handleUnknownKey = (type: keyof RunbookViewStateType, fnName: string): never => {
  throw new Error(`Unknown argument passed to ${fnName}: ${type}`)
}

export const RunbookViewModel: ViewModelInterface<
  RunbookViewStateType,
  RunbookViewModelActionHandlers,
  RunbookViewModelPermissions
> = {
  get: <TKey extends keyof RunbookViewStateType>(key: TKey) => {
    switch (key) {
      case 'loadingIds':
        return useLoadingIdsValue() as RunbookViewStateType[TKey]
      case 'selectedIds':
        return useSelectedIdsValue() as RunbookViewStateType[TKey]
      case 'modal':
        return useModalValue() as RunbookViewStateType[TKey]
      case 'menu':
        return useMenuValue() as RunbookViewStateType[TKey]
      case 'integrationRequest':
        return useIntegrationRequestValue() as RunbookViewStateType[TKey]
      case 'taskCreate':
        return useTaskCreateValue() as RunbookViewStateType[TKey]
      default:
        return handleUnknownKey(key, 'get')
    }
  },
  getCallback: <TKey extends keyof RunbookViewStateType>(key: TKey) => {
    switch (key) {
      case 'loadingIds':
        return useLoadingIdsValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'selectedIds':
        return useSelectedIdsValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'modal':
        return useModalValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'menu':
        return useMenuValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'integrationRequest':
        return useIntegrationRequestValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      case 'taskCreate':
        return useTaskCreateValueCallback() as () => Promise<RunbookViewStateType[TKey]>
      default:
        return handleUnknownKey(key, 'getCallback')
    }
  },
  getCallbackSync: <TKey extends keyof RunbookViewStateType>(key: TKey) => {
    switch (key) {
      case 'loadingIds':
        return useLoadingIdsValueCallbackSync() as () => RunbookViewStateType[TKey]
      case 'selectedIds':
        return useSelectedIdsValueCallbackSync() as () => RunbookViewStateType[TKey]
      case 'modal':
        return useModalValueCallbackSync() as () => RunbookViewStateType[TKey]
      case 'menu':
        return useMenuValueCallbackSync() as () => RunbookViewStateType[TKey]
      case 'integrationRequest':
        return useIntegrationRequestValueCallbackSync() as () => RunbookViewStateType[TKey]
      case 'taskCreate':
        return useTaskCreateValueCallbackSync() as () => RunbookViewStateType[TKey]
      default:
        return handleUnknownKey(key, 'getCallbackSync')
    }
  },
  onAction: <TAction extends keyof RunbookViewModelActionHandlers>(action: TAction) => {
    switch (action) {
      case 'selectedIds:add':
        return useSelectedIdAdd() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:remove':
        return useSelectedIdRemove() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:bulkRemove':
        return useSelectedIdBulkRemove() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:overwrite':
        return useSelectedIdsOverwrite() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:toggle':
        return useSelectedIdToggle() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:selectAll':
        return useSelectedIdsSelectAll() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:removeAll':
        return useSelectedIdsRemoveAll() as RunbookViewModelActionHandlers[TAction]
      case 'selectedIds:toggleAll':
        return useSelectedIdsToggleAll() as RunbookViewModelActionHandlers[TAction]

      case 'loadingIds:add':
        return useLoadingIdAdd() as RunbookViewModelActionHandlers[TAction]
      case 'loadingIds:bulkAdd':
        return useLoadingIdAddBulk() as RunbookViewModelActionHandlers[TAction]
      case 'loadingIds:bulkRemove':
        return useLoadingIdRemoveBulk() as RunbookViewModelActionHandlers[TAction]
      case 'loadingIds:remove':
        return useLoadingIdRemove() as RunbookViewModelActionHandlers[TAction]

      case 'modal:open':
        return useModalOpen() as RunbookViewModelActionHandlers[TAction]
      case 'modal:close':
        return useModalClose() as RunbookViewModelActionHandlers[TAction]
      case 'modal:continue':
        return useModalContinue() as RunbookViewModelActionHandlers[TAction]
      case 'modal:update':
        return useModalUpdate() as RunbookViewModelActionHandlers[TAction]

      case 'taskMenu:close':
        return useMenuClose() as RunbookViewModelActionHandlers[TAction]
      case 'taskMenu:open':
        return useMenuOpen() as RunbookViewModelActionHandlers[TAction]
      case 'taskMenu:clear':
        return useMenuClear() as RunbookViewModelActionHandlers[TAction]
      case 'taskMenu:toggle:actions':
        return useMenuToggleTaskAction() as RunbookViewModelActionHandlers[TAction]
      case 'taskMenu:toggle:dependencies':
        return useMenuToggleTaskDependencies() as RunbookViewModelActionHandlers[TAction]

      case 'integrationRequest:add':
        return useIntegrationRequestAdd() as RunbookViewModelActionHandlers[TAction]
      case 'integrationRequest:remove':
        return useIntegrationRequestRemove() as RunbookViewModelActionHandlers[TAction]

      case 'taskCreate:open':
        return useTaskCreateOpenForm() as RunbookViewModelActionHandlers[TAction]
      case 'taskCreate:toggle':
        return useTaskCreateToggleForm() as RunbookViewModelActionHandlers[TAction]
      case 'taskCreate:close':
        return useTaskCreateCloseForm() as RunbookViewModelActionHandlers[TAction]
      case 'taskCreate:setName':
        return useTaskCreateSetName() as RunbookViewModelActionHandlers[TAction]

      default:
        return handleUnknownKey(action, 'onAction')
    }
  },
  canAction: action => {
    switch (action) {
      case 'initiate:bulkEditActions':
        return useCanInitiateBulkEditActions()
      case 'initiate:bulkProgressionActions':
        return useCanInitiateBulkProgressionActions()
    }
  },
  canActionCallback: action => {
    switch (action) {
      case 'initiate:bulkEditActions':
        return useCanInitiateBulkEditActionsCallback()
      case 'initiate:bulkProgressionActions':
        return useCanInitiateBulkProgressionActionsCallback()
    }
  },
  canActionCallbackSync: action => {
    switch (action) {
      case 'initiate:bulkEditActions':
        return useCanInitiateBulkEditActionsCallbackSync()
      case 'initiate:bulkProgressionActions':
        return useCanInitiateBulkProgressionActionsCallbackSync()
    }
  }
}
