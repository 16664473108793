import { useMutation, useQuery } from 'react-query'

import { InstanceSettingsRequestType, InstanceSettingsResponseType } from './instance-settings-types'
import { apiClient } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'

export function useInstanceSettingsQuery() {
  return useQuery<InstanceSettingsResponseType, Error>(QueryKeys.InstanceSettings, async () => {
    const { data } = await apiClient.get<InstanceSettingsResponseType>({
      url: 'instance_settings',
      responseProperty: 'instance_settings'
    })

    return data
  })
}

export function useUpdateInstanceSettingsMutation() {
  return useMutation<InstanceSettingsResponseType, Error, InstanceSettingsRequestType>(
    QueryKeys.InstanceSettings,
    async settings => {
      const { data } = await apiClient.put<InstanceSettingsRequestType, InstanceSettingsResponseType>({
        url: 'instance_settings',
        data: settings,
        requestProperty: 'instance_settings',
        responseProperty: 'instance_settings'
      })

      // Our api client uses the indicated response type passed into the `put` type params but adds null | undefined right now.
      // This needs to be fixed.
      return data as InstanceSettingsResponseType
    }
  )
}
