import { ReactElement, useEffect } from 'react'

import { useCurrentUser } from 'main/recoil/data-access'
import { CsvImportResponse } from 'main/services/api/data-providers/user/user-channel-response-types'
import { useUserWebsocket } from 'main/services/hooks'
import { RunbookViewModel } from 'main/data-access'
/*
  While Workspace is still using context provider for websockets,
  just listen to the existing channel in this subscriber on the runbook level
  Eventually we will use this to subscribe in the entire app
*/
export const UserChannelSubscriber = ({ children }: { children: ReactElement }) => {
  const { listen } = useUserWebsocket()
  const { id: currentUserId } = useCurrentUser()
  const updateModalData = RunbookViewModel.onAction('modal:update')

  const handleUserChannelResponse = (response: CsvImportResponse) => {
    if (!response) return
    switch (response?.meta?.headers?.request_method) {
      case 'runbook_version_import_success':
        updateModalData({ status: 'success' })
        break
      case 'runbook_version_import_error':
        updateModalData({ status: 'error', context: response.errors })
        break
      default:
    }
  }

  useEffect(() => {
    if (!currentUserId) return
    listen(data => handleUserChannelResponse(data as CsvImportResponse))
  }, [currentUserId])

  return children
}
