/* eslint-disable react-hooks/rules-of-hooks */

import { StreamModelType } from './types'
import {
  canStream,
  getStream,
  getStreamCallback,
  getStreamCallbackSync,
  getStreams,
  getStreamsCallback,
  getStreamsCallbackSync,
  getStreamsLookup,
  getStreamsLookupCallback,
  getStreamsLookupCallbackSync,
  onActionStream
} from '../hooks/recoil'

export const StreamModel: Omit<StreamModelType, 'canAction' | 'canActionCallback' | 'canActionCallbackSync'> = {
  can: canStream,
  get: getStream,
  getCallback: getStreamCallback,
  getCallbackSync: getStreamCallbackSync,
  getAll: getStreams,
  getAllCallback: getStreamsCallback,
  getAllCallbackSync: getStreamsCallbackSync,
  getLookup: getStreamsLookup,
  getLookupCallback: getStreamsLookupCallback,
  getLookupCallbackSync: getStreamsLookupCallbackSync,
  onAction: onActionStream
}
