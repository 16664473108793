import { useQuery } from 'react-query'

import { apiClient, ApiError } from '../api'
import { QueryKeys } from './query-keys'

type CustomPromptsType = {
  name: string
  description: string
}[]

type ImprovementPromptsResponseType = {
  runbook_improvement_prompts: CustomPromptsType
}

type SuggestionsResponseType = {
  suggestions: {
    [key: string]: string
  }
}

export function useRunbookGenerateSuggestions(runbookId: number | null, prompt: string, options: any) {
  return useQuery<SuggestionsResponseType, ApiError>(
    QueryKeys.RunbookGenerateSuggestions,
    async () => {
      const { data } = await apiClient.get<SuggestionsResponseType>({
        url: `runbooks/${runbookId}/generate_suggestions`,
        params: {
          prompt: prompt
        }
      })

      return data
    },
    options
  )
}

export function useRunbookImprovementPrompts() {
  return useQuery<CustomPromptsType, ApiError>(QueryKeys.RunbookImprovementPrompts, async () => {
    const { data } = await apiClient.get<ImprovementPromptsResponseType>({
      url: 'runbooks/improvement_prompts'
    })

    return data.runbook_improvement_prompts
  })
}
