import { useEffect } from 'react'

import { useDatadog } from './use-datadog'
import { useHeap } from './use-heap'
import { useGlobalConfig } from 'main/services/hooks'
import { Intercom } from './intercom'

// This is an empty rendering component rather than a hook so that we can optionally render it
// depending on the react_login feature flag
export const SupportAndAnalytics = () => {
  const { intercomAppId } = useGlobalConfig()
  const { initializeUserAnalytics } = useHeap()

  useDatadog()
  useEffect(() => {
    initializeUserAnalytics()
  }, [])

  return intercomAppId ? <Intercom /> : <></>
}
