import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import parse from 'html-react-parser'
import { Controller, useForm } from 'react-hook-form'

import { Box, CheckboxGroup, Form, Message, Modal, RadioboxGroup, Text, TextInput } from '@cutover/react-ui'
import { RunbookValidation } from './runbook-validation'
import { useFeature, useLanguage } from 'main/services/hooks'
import { RunComms, RunType, TimezoneType } from 'main/services/queries/types'
import { useRunCreate } from 'main/services/queries/use-run'
import { ActiveRunModel } from 'main/data-access'
import { RunbookRunCreateResponse } from 'main/services/api/data-providers/runbook-types'

export type StartRunbookModalProps = {
  onClose: () => void
  runbookId: string | number
  runbookVersionId: string | number
  templateType: 'default' | 'snippet' | 'off'
  hasParentRunbook: boolean
  isLiveRunInParentRunbook: boolean
  isScheduled: boolean
  autoStart?: boolean
  startScheduled?: number | null
  timezone: TimezoneType | null
}

type StartRunbookModalForm = {
  runType: RunType
  comms: RunComms
  message: string | null
  options: ('shiftTime' | 'disableTaskNotify' | 'notify' | 'recalcTimings')[] | null
}

export const StartRunbookModal = ({
  onClose,
  runbookId,
  runbookVersionId,
  templateType,
  hasParentRunbook,
  isLiveRunInParentRunbook,
  isScheduled,
  autoStart,
  startScheduled,
  timezone
}: StartRunbookModalProps) => {
  const [isValidating, setIsValidating] = useState<boolean | null>(null)
  const [errorScore, setErrorScore] = useState(0)
  const [modalStep, setModalStep] = useState<'runbookStart' | 'overrideStart'>('runbookStart')
  const { isEnabled } = useFeature()
  const reactRunbookFeatureFlag = isEnabled('react_runbook')
  const processRunCreateResponse = ActiveRunModel.onAction('create')

  const { t } = useLanguage('runbook')
  const runMutation = useRunCreate({ runbookId })

  const isSnippet = templateType === 'snippet'
  const isTemplate = templateType === 'default'
  const defaultValues: StartRunbookModalForm = {
    runType: isLiveRunInParentRunbook ? 'live' : 'rehearsal',
    comms: 'off',
    message: null,
    options: null
  }

  const methods = useForm<StartRunbookModalForm>({ defaultValues })
  const runType = methods.watch('runType')
  const comms = methods.watch('comms')
  const options = methods.watch('options')
  const message = methods.watch('message')

  const notify = options && options.includes('notify')
  const recalcTimings = options && options.includes('recalcTimings')

  useEffect(() => {
    if (runType === 'live') {
      methods.setValue('comms', 'on')
    } else {
      methods.setValue('comms', 'off')
    }
  }, [runType])

  useEffect(() => {
    if (comms === 'off') {
      if (options) {
        methods.setValue(
          'options',
          options.filter(option => option !== 'notify' && option !== 'disableTaskNotify')
        )
      }
      methods.setValue('message', null)
    }
  }, [comms])

  useEffect(() => {
    if ((!options || !options.includes('notify')) && message) {
      methods.setValue('message', null)
    }

    if (options && !options.includes('recalcTimings') && options.includes('shiftTime')) {
      methods.setValue(
        'options',
        options.filter(option => option !== 'shiftTime')
      )
    }
  }, [options])

  const onFormSubmit = ({ runType, comms, message, options }: StartRunbookModalForm) => {
    const mutationPayload = {
      notify: options?.includes('notify') ?? false,
      message,
      recalc_timings: options?.includes('recalcTimings') ?? false,
      run: {
        run_type: runType,
        disable_task_notify: options?.includes('disableTaskNotify') ?? false,
        comms
      },
      shift_time: options?.includes('shiftTime') ?? false
    }

    runMutation.mutate(mutationPayload, {
      onSuccess: response => {
        if (reactRunbookFeatureFlag) {
          processRunCreateResponse(response as RunbookRunCreateResponse)
        }

        onClose()
      }
    })
  }

  const isSubmitting = runMutation.isLoading

  const getLiveNotAvailableText = () => {
    if (hasParentRunbook && !isLiveRunInParentRunbook) {
      return t('startRunbookModal.notAvailable')
    } else if (isTemplate) {
      return t('startRunbookModal.notAvailableIfTemplate')
    } else if (isSnippet) {
      return t('startRunbookModal.notAvailableIfSnippet')
    }

    return null
  }

  const isRunbookStartModal = modalStep === 'runbookStart'
  const isNextStepAvailable = isRunbookStartModal && autoStart && runType === 'live' && errorScore == 0

  const confirmText = () => {
    if (isValidating) {
      return t('startRunbookModal.validatingText')
    } else if (isNextStepAvailable) {
      return t('startRunbookModal.next')
    } else if (isSubmitting) {
      return t('startRunbookModal.submitingText')
    } else {
      return t('startRunbookModal.submitText')
    }
  }

  const handleClickStartRun =
    errorScore > 0 || isValidating || isSubmitting ? undefined : methods.handleSubmit(onFormSubmit)

  const title = isRunbookStartModal
    ? t('startRunbookModal.heading', { context: isSnippet ? 'snippet' : 'runbook' })
    : t('startRunbookModal.overrideHeading')

  const formatDate = (ts: number, timezone: string): string => {
    const utcDate = new Date(ts * 1000)
    const dateInZone = utcToZonedTime(utcDate, timezone)
    return format(dateInZone, 'EEE, d MMM yyyy HH:mm')
  }

  return (
    <Modal
      title={title}
      open
      onClose={onClose}
      loading={isValidating || isSubmitting}
      confirmText={confirmText()}
      confirmIcon={isNextStepAvailable ? 'chevron-right' : 'play-arrow'}
      onClickConfirm={isNextStepAvailable ? () => setModalStep('overrideStart') : handleClickStartRun}
      onClickBack={isRunbookStartModal ? undefined : () => setModalStep('runbookStart')}
    >
      {runMutation.isError && (
        <Box margin={{ bottom: 'medium' }}>
          <Message type="error" message={runMutation.error.errors} />
        </Box>
      )}
      {isRunbookStartModal ? (
        <>
          <RunbookValidation
            closeModal={onClose}
            isSnippet={isSnippet}
            runbookId={runbookId}
            runbookVersionId={runbookVersionId}
            setIsValidating={setIsValidating}
            setErrorScore={setErrorScore}
          />
          <Form onSubmit={methods.handleSubmit(onFormSubmit)}>
            <Box margin={{ top: '16px' }}>
              <Controller
                name="runType"
                control={methods.control}
                defaultValue={defaultValues.runType || 'rehearsal'}
                render={({ field: { name, onChange, onBlur, ref } }) => (
                  <RadioboxGroup
                    name={name}
                    label={t('startRunbookModal.runType')}
                    direction="row"
                    defaultValue={defaultValues.runType || 'rehearsal'}
                    disabled={isSubmitting}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    options={[
                      {
                        value: 'rehearsal',
                        label: `${t('startRunbookModal.rehearsal')} ${
                          hasParentRunbook && isLiveRunInParentRunbook ? t('startRunbookModal.notAvailable') : ''
                        }`,
                        disabled: hasParentRunbook && isLiveRunInParentRunbook
                      },
                      {
                        value: 'live',
                        label: `${t('startRunbookModal.live')} ${getLiveNotAvailableText() ?? ''}`,
                        disabled: isTemplate || isSnippet || (hasParentRunbook && !isLiveRunInParentRunbook)
                      }
                    ]}
                  />
                )}
              />
              <Controller
                name="comms"
                control={methods.control}
                render={({ field: { name, value, onChange, onBlur, ref } }) => (
                  <RadioboxGroup
                    name={name}
                    label={t('startRunbookModal.comms')}
                    direction="row"
                    defaultValue="off"
                    disabled={isSubmitting}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    options={[
                      { value: 'off', label: t('startRunbookModal.off') },
                      { value: 'test', label: t('startRunbookModal.test') },
                      { value: 'on', label: t('startRunbookModal.on') }
                    ]}
                  />
                )}
              />
              {comms === 'test' && (
                <Box margin={{ bottom: 'medium' }}>
                  <Message type="info" message={t('startRunbookModal.note')} />
                </Box>
              )}
              {(comms !== 'off' || isScheduled) && (
                <Controller
                  name="options"
                  control={methods.control}
                  render={({ field: { onChange } }) => (
                    <CheckboxGroup
                      label={t('startRunbookModal.options')}
                      onChange={event => event && onChange(event.value)}
                      options={[
                        ...(comms !== 'off'
                          ? [
                              {
                                value: 'disableTaskNotify',
                                option: 'disableTaskNotify',
                                label: t('startRunbookModal.disableTaskNotify')
                              },
                              {
                                value: 'notify',
                                option: 'notify',
                                label: t('startRunbookModal.notify', {
                                  item: isSnippet ? 'snippet' : 'runbook'
                                })
                              }
                            ]
                          : []),
                        ...(isScheduled
                          ? [
                              {
                                value: 'recalcTimings',
                                option: 'recalcTimings',
                                label: t('startRunbookModal.recalcTimings')
                              }
                            ]
                          : []),
                        ...(recalcTimings
                          ? [{ value: 'shiftTime', option: 'shiftTime', label: t('startRunbookModal.shiftTime') }]
                          : [])
                      ]}
                    />
                  )}
                />
              )}
              {notify && (
                <TextInput
                  {...methods.register('message')}
                  label={t('startRunbookModal.messageLabel')}
                  disabled={isSubmitting}
                />
              )}
            </Box>
          </Form>
        </>
      ) : (
        <Box>
          <Text>
            {startScheduled &&
              parse(
                t('startRunbookModal.overrideConfirmation', {
                  time: `${formatDate(startScheduled, timezone?.id || '')}`,
                  timezoneName: timezone?.name
                })
              )}
          </Text>
        </Box>
      )}
    </Modal>
  )
}
