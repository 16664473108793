import {
  canActionRunbookVersion,
  canActionRunbookVersionCallback,
  canActionRunbookVersionCallbackSync,
  canRunbookVersion,
  getRunbookVersion,
  getRunbookVersionCallback,
  getRunbookVersionCallbackSync,
  getRunbookVersionLoadable,
  getRunbookVersionLoadableCallback,
  onActionRunbookVersion,
  reloadRunbookVersion,
  reloadRunbookVersionSync
} from '../hooks'
import { ActiveRunbookVersionModelType } from './types'

export const ActiveRunbookVersionModel: ActiveRunbookVersionModelType = {
  get: getRunbookVersion,
  getCallback: getRunbookVersionCallback,
  getCallbackSync: getRunbookVersionCallbackSync,

  getLoadable: getRunbookVersionLoadable,
  getLoadableCallback: getRunbookVersionLoadableCallback,

  onAction: onActionRunbookVersion,

  can: canRunbookVersion,
  canAction: canActionRunbookVersion,
  canActionCallback: canActionRunbookVersionCallback,
  canActionCallbackSync: canActionRunbookVersionCallbackSync,

  reload: reloadRunbookVersion,
  reloadSync: reloadRunbookVersionSync
}
