/* eslint-disable react-hooks/rules-of-hooks */

import { TaskTypeModelType } from './types'
import {
  useTaskType,
  useTaskTypeCallback,
  useTaskTypeCallbackSync,
  useTaskTypeLookup,
  useTaskTypeLookupCallback,
  useTaskTypeLookupCallbackSync,
  useTaskTypes,
  useTaskTypesCallback,
  useTaskTypesCallbackSync
} from 'main/data-access/hooks/recoil/task-type'

export const TaskTypeModel: TaskTypeModelType = {
  get: useTaskType,
  getCallback: useTaskTypeCallback,
  getCallbackSync: useTaskTypeCallbackSync,
  getAll: useTaskTypes,
  getAllCallback: useTaskTypesCallback,
  getAllCallbackSync: useTaskTypesCallbackSync,
  getLookup: useTaskTypeLookup,
  getLookupCallback: useTaskTypeLookupCallback,
  getLookupCallbackSync: useTaskTypeLookupCallbackSync
}
