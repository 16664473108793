import { isEmpty } from 'lodash'
import * as yup from 'yup'

import { toCamelCase } from '@cutover/api'
import { urlRegex } from '@cutover/react-ui'
import { IntegrationSettingFormType } from './integration-setting-types'
import { IntegrationConnectionConfig, LanguageTranslate, useFeature } from 'main/services/hooks'
import { IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'
import { useConnectSettingsQuery } from 'main/services/queries/use-connect-settings-query'

export function buildValidationSchema(
  integration: IntegrationSetting | IntegrationConnectionConfig,
  t: LanguageTranslate
) {
  const settingsSchema = Object.entries(integration.settings).reduce((fields, [setting, fieldInfo]) => {
    // TODO: Investigate this -- These may not all be strings but required does not work properly with `mixed()`
    if (fieldInfo.required) {
      fields[toCamelCase(setting)] = yup.string().required()
    } else {
      fields[toCamelCase(setting)] = yup.string()
    }

    return fields
  }, {} as { [key: string]: yup.StringSchema })

  const schema = yup.object({
    imageUrl: yup
      .string()
      .matches(urlRegex, { message: t('form.validationErrors.imageUrl.invalid'), excludeEmptyString: true })
      .required(t('form.validationErrors.imageUrl.required')),
    name: yup.string().required(t('form.validationErrors.name.required'))
  })

  return isEmpty(integration.settings)
    ? schema
    : schema.shape({
        settings: yup.object(settingsSchema).required()
      })
}

export function buildRequestData(
  values: IntegrationSettingFormType,
  integrationSetting: IntegrationSetting,
  dirtyFields: { [x: string]: any } | undefined
) {
  const removedSensitiveUnchangedValues = Object.assign({}, values)

  for (const [key, value] of Object.entries(integrationSetting.settings)) {
    if (value?.sensitive && !dirtyFields?.settings?.[toCamelCase(key)]) {
      delete removedSensitiveUnchangedValues.settings?.[toCamelCase(key)]
    }
  }

  return {
    ...removedSensitiveUnchangedValues,
    id: integrationSetting.id,
    integration: integrationSetting.integration
  }
}

export function buildDefaultValues(
  integration: IntegrationSetting | IntegrationConnectionConfig
): Partial<IntegrationSettingFormType> {
  const settingsDefaults = !isConnectionConfig(integration)
    ? Object.entries(integration.settings).reduce((fields, [setting, fieldInfo]: [string, any]) => {
        fields[toCamelCase(setting)] = fieldInfo.value || ''

        return fields
      }, {} as { [key: string]: {} | undefined })
    : Object.entries(integration.settings).reduce((fields, [setting, fieldInfo]) => {
        fields[toCamelCase(setting)] = fieldInfo.default || ''

        return fields
      }, {} as { [key: string]: {} | undefined })

  return {
    imageUrl: isConnectionConfig(integration) ? integration.imageUrl : integration.image_url,
    name: integration.name,
    settings: settingsDefaults
  }
}

type MergedSettings = Record<
  string,
  Partial<{ label: string; placeholder: string; required: boolean; value: string; default: string }>
>

// Coerce a partial object with the relevant keys for displaying the form settings fields sourced from either type of
// data that may be used to display the form
export function mergeSettings(integration: IntegrationSetting | IntegrationConnectionConfig) {
  return integration.settings as MergedSettings
}

export function isConnectionConfig(arg: any): arg is IntegrationConnectionConfig {
  return 'klass' in (arg as object)
}

export const ConnectProjectNames = (actionItem: IntegrationActionItem | undefined) => {
  var arrayOfConnectProjectNames: string[] = []
  const { isEnabled } = useFeature()
  const { data: connectSettings } = useConnectSettingsQuery()

  if (isEnabled('connect_settings')) {
    const legacyConnectProject = actionItem?.settings.legacy_connect_project?.toString()

    connectSettings?.connect_settings.forEach(connect_setting => {
      if (connect_setting.status === 'Active'.toLowerCase()) {
        arrayOfConnectProjectNames.push(connect_setting.display_name)
      }
    })

    if (legacyConnectProject && !arrayOfConnectProjectNames.includes(legacyConnectProject)) {
      arrayOfConnectProjectNames = []
      arrayOfConnectProjectNames.push(legacyConnectProject)
      return arrayOfConnectProjectNames
    }
  }
  return arrayOfConnectProjectNames
}
