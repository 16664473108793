import { useState } from 'react'
import parse from 'html-react-parser'
import styled from 'styled-components'

import { Box, Button, Checkbox, Icon, IconName, Text } from '@cutover/react-ui'
import { useCurrentUser, useUpdateFrontendUserSettingState } from 'main/recoil/data-access'
import { useGlobalConfig, useLanguage } from 'main/services/hooks'
import { FrontendUserSettingType } from 'main/services/queries/types'
import { updateFrontendUserSetting } from 'main/services/queries/use-frontend-user-setting'

type LinkTemplateIntroductionProps = {
  onClose: () => void
}

export const LinkTemplateIntroduction = ({ onClose }: LinkTemplateIntroductionProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'linkTemplateIntroductionDetailsModal' })
  const { cutoverApiFullPath } = useGlobalConfig()
  const [userPreferenceHideModal, setUserPreferenceHideModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const currentUser = useCurrentUser()
  const frontEndUserSetting: FrontendUserSettingType = currentUser?.frontend_user_setting || {}
  const updateFrontendUserSettingState = useUpdateFrontendUserSettingState()

  const onContinue = async () => {
    if (userPreferenceHideModal) {
      const payload: FrontendUserSettingType = {
        type: 'FrontendUserSetting',
        data: {}
      }
      payload.data = { ...frontEndUserSetting.data, linked_template_marketing_modal_hidden: true }

      if (frontEndUserSetting.id) {
        payload.id = frontEndUserSetting.id
      }
      setLoading(true)
      const response = await updateFrontendUserSetting(payload)
      if (response && response?.user_setting) {
        updateFrontendUserSettingState({ payload: response.user_setting.data })
      }
      setLoading(false)
    }
    onClose()
  }

  const linkTemplateIntroDescriptionElementArray: { iconName: IconName; description: string }[] = [
    {
      iconName: 'template',
      description: t('descriptionInstantly')
    },

    {
      iconName: 'copy',
      description: t('descriptionCopy')
    },
    {
      iconName: 'play',
      description: t('descriptionRun')
    },
    {
      iconName: 'dashboard',
      description: t('descriptionTrack')
    }
  ]

  return (
    <>
      <Box direction="row" margin={{ bottom: 'medium' }}>
        <Text size={'medium'}>{t('description')}</Text>
      </Box>
      <Box direction={'row'}>
        <Box>
          {linkTemplateIntroDescriptionElementArray.map((linkTemplateIntroDescriptionElement, index) => (
            <LinkTemplateDescriptionComponent
              key={index}
              iconName={linkTemplateIntroDescriptionElement.iconName}
              description={linkTemplateIntroDescriptionElement.description}
            />
          ))}
        </Box>

        <Box>
          <CardImg src={`${cutoverApiFullPath}/img/runbooks/link-template-marketing.svg`} />
        </Box>
      </Box>
      <Box justify="between" direction="row" align="center">
        <Checkbox
          data-testid="doNotShowButton"
          width="auto"
          onChange={e => setUserPreferenceHideModal(e.currentTarget.checked)}
          label={t('doNotShowButton')}
        />
        <Button
          data-testid="submitButton"
          type="submit"
          onClick={onContinue}
          label={isLoading ? t('common:loading') : t('createButton')}
          primary={true}
          loading={isLoading}
          disabled={isLoading}
        />
      </Box>
    </>
  )
}

const LinkTemplateDescriptionComponent = ({ iconName, description }: { iconName: IconName; description: string }) => (
  <Box direction="row" gap={'medium'}>
    <Icon icon={iconName} size={'large'} color={'primary'} />
    <Box margin={{ bottom: 'medium' }}>{parse(description)}</Box>
  </Box>
)

const CardImg = styled.img`
  width: 169px;
  height: 193px;
  border-radius: 14px;
`
