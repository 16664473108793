import { useMutation, useQuery } from 'react-query'

import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'

export type Filter = {
  id: number
  name: string
  query_string: string
  global: boolean
  default: boolean
  user_id: number
  resource_type: string
  saved_view_group_id?: number
  default_saved_view_group_order?: string
}

export type FilterResponse = {
  filters: Filter[]
}

export const useFiltersQuery = (resourceType: string, resourceId: number) => {
  return useQuery<FilterResponse, ApiError>(['filters', resourceType, String(resourceId)], async () => {
    const { data } = await apiClient.get<FilterResponse>({
      url: 'filters',
      params: {
        resource_type: resourceType,
        resource_id: resourceId
      }
    })

    return data
  })
}

/****************** Create filter ***************/

type CreateFilterPayload = {
  filter: {
    name: string
    query_string: string
    resource_type: string
    resource_id: number
  }
}

export const useCreateFilter = () => {
  return useMutation<{ filter: Filter }, ApiError, CreateFilterPayload>(
    ['create-runbook-filter'],
    async (filter: CreateFilterPayload) => {
      const { data } = await apiClient.post<CreateFilterPayload, { filter: Filter }>({
        url: 'filters',
        data: filter
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return data!
    }
  )
}

/****************** Delete filter ***************/

export const useDeleteFilter = () => {
  // @ts-ignore
  return useMutation<void, ApiError, number>(['delete-runbook-filter'], async (id: number) => {
    return await apiClient.delete<{ filter: { id: number } }>({
      url: `filters/${id}`
    })
  })
}

/****************** Update: set default filter ***************/

export const useSetFilterDefault = () => {
  return useMutation(['set-filter-defauklt'], async (id: number) => {
    const { data } = await apiClient.patch({
      url: `filters/${id}/set_as_default`
    })

    return data as Filter
  })
}

/****************** Update: set global filter ***************/

export const useToggleFilterGlobal = () => {
  return useMutation(['toggle-runbook-filter-global'], async (id: number) => {
    const { data } = await apiClient.patch({
      url: `filters/${id}/toggle_global`
    })

    return data as Filter
  })
}
