import { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { Box, IconButton, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { queryClient } from 'main/query-client'
import { SystemParameter } from 'main/services/queries/types/system-parameters'
import {
  useSystemParameter,
  useUpdateSystemParametersMutation
} from 'main/services/queries/use-system-parameters-query'
import { DeleteSystemParameterModal } from '../modals/delete-system-parameter-modal'
import { QueryKeys } from 'main/services/queries/query-keys'
import { FormEditPanel, TextInputField } from 'main/components/shared/form'
import { FormType } from 'main/components/shared/form/form'

export const SystemParameterEditPanel = () => {
  const [{ systemParameterId }, { closeRightPanel }] = useRightPanelTypeState('system-parameters-edit')
  return (
    <>
      {systemParameterId ? (
        <SystemParameterEdit systemParameterId={systemParameterId} onClose={closeRightPanel} key={systemParameterId} />
      ) : null}
    </>
  )
}

type SystemParametersEditType = {
  systemParameterId: number
  onClose: () => void
}

export type SystemParametersFormType = Partial<SystemParameter>

const SystemParameterEdit = ({ systemParameterId, onClose }: SystemParametersEditType) => {
  const formRef = useRef<FormType<{ name: string }>>(null)
  const { t } = useLanguage('systemParameters')
  const { data } = useSystemParameter(systemParameterId)
  const patchMutation = useUpdateSystemParametersMutation()
  const notify = useNotify()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const handleSubmit = async (formData: SystemParametersFormType) => {
    patchMutation.mutate(
      { id: systemParameterId, payload: formData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.SystemParameters])
          notify.success(t('toasters.success'))
          onClose()
        },
        onError: () => {
          notify.error(t('toasters.genericError'))
        }
      }
    )
  }

  useEffect(() => {
    formRef.current?.reset({
      name: data?.system_parameter.name
    })
  }, [data])

  return (
    <FormEditPanel
      ref={formRef}
      onClose={onClose}
      headerItems={[
        <IconButton
          label={t('editPanel.deleteIconLabel')}
          tipPlacement="top"
          icon="trash-o"
          onClick={() => setDeleteModalOpen(true)}
        />
      ]}
      title={t('editPanel.title')}
      onSubmit={handleSubmit}
      schema={yup.object({
        name: yup.string().required().max(25),
        value: yup.string()
      })}
    >
      <Box>
        <TextInputField name="name" label={t('table.columnHeaders.name')} required />
        <TextInputField name="value" label={t('table.columnHeaders.value')} />
        <DeleteSystemParameterModal
          open={isDeleteModalOpen}
          setOpen={setDeleteModalOpen}
          systemParameterId={systemParameterId}
          onSubmit={() => {
            onClose()
          }}
        />
      </Box>
    </FormEditPanel>
  )
}
