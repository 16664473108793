/* eslint-disable react-hooks/rules-of-hooks */
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { extend, pick } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { produce } from 'immer'

import { runState } from 'main/recoil/runbook/models/runbook-version/run'
import { ActiveRunModelType } from 'main/data-access/models/types'
import { Run } from 'main/services/queries/types'
import {
  accountSlugUrlParamState,
  runbookResponseState_INTERNAL,
  runbookVersionUrlParamState,
  runsPermission,
  RunsPermissionsResponse
} from 'main/recoil/runbook'
import { ProgressRunbookResponse } from 'main/recoil/data-access'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const getRun: ActiveRunModelType['get'] = keyOrKeys => {
  let run: Run | undefined
  run = useRecoilValue(runState)
  return getRunReturn(run, keyOrKeys)
}

export const getRunCallback: ActiveRunModelType['getCallback'] = keyOrKeys =>
  useRecoilCallback(({ snapshot }) => async () => {
    const run = await snapshot.getPromise(runState)
    return getRunReturn(run, keyOrKeys)
  })

export const getRunCallbackSync: ActiveRunModelType['getCallbackSync'] = keyOrKeys =>
  useRecoilCallback(({ snapshot }) => () => {
    const run = snapshot.getLoadable(runState).getValue()
    return getRunReturn(run, keyOrKeys)
  })

/* -------------------------------------------------------------------------- */
/*                                  Loadable                                  */
/* -------------------------------------------------------------------------- */

// @ts-ignore
export const getRunLoadable: ActiveRunModelType['getLoadable'] = () => useRecoilValueLoadable(runState)

export const getRunLoadableCallback: ActiveRunModelType['getLoadableCallback'] = () =>
  // @ts-ignore
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(runState)
  )

/* -------------------------------------------------------------------------- */
/*                                     Can                                    */
/* -------------------------------------------------------------------------- */

export const canRun: ActiveRunModelType['can'] = permission => {
  return useRecoilValue(runsPermission({ attribute: permission }))
}

/* -------------------------------------------------------------------------- */
/*                                   Action                                   */
/* -------------------------------------------------------------------------- */

// @ts-ignore
export const onActionRun: ActiveRunModelType['onAction'] = actionKey => {
  switch (actionKey) {
    default:
      return useProcessRunResponseCallback()
  }
}

export const canActionRun: ActiveRunModelType['canAction'] = actionKey => {
  return canRun(actionKey as keyof RunsPermissionsResponse)
}

export const canActionRunCallback: ActiveRunModelType['canActionCallback'] = actionKey => {
  return useRecoilCallback(({ snapshot }) => async () => {
    switch (actionKey) {
      default:
        // TODO: WARNING: not yet implemented as a feature at time of writing; this hasn't been double checked.
        return await snapshot.getPromise(runsPermission({ attribute: actionKey as keyof RunsPermissionsResponse }))
    }
  })
}

export const canActionRunCallbackSync: ActiveRunModelType['canActionCallbackSync'] = actionKey => {
  return useRecoilCallback(({ snapshot }) => () => {
    switch (actionKey) {
      default:
        return snapshot
          .getLoadable(runsPermission({ attribute: actionKey as keyof RunsPermissionsResponse }))
          .getValue()
    }
  })
}

/* -------------------------------- Internal -------------------------------- */

function getRunReturn(run: Run | undefined, keyOrKeys: any) {
  if (!keyOrKeys || !run) return run
  if (Array.isArray(keyOrKeys)) pick(run, keyOrKeys)

  // @ts-ignore
  return run[keyOrKeys]
}

/**
 * With create, pause, and cancel, we always have a new runbook version, which means a new navigational state
 * triggering updates to runbook version and task list data. However, we have to manually update the runbook
 * data in store with the response's runbook.
 *
 * With resume, we get updated runbook data with updated current_version data but the current_version is not
 * newly created (it has the same id).
 *
 * ***********************
 *
 * Our url supports a 'current_version' alias and we want all actions to result in a numerical id
 * format (for now), so we need to navigate sometimes when it is actually a resume.
 *
 * So the logic is: if its current_version url OR the response's version and the url's version are different,
 * navigate and let that take care of re-requesting the rbv and task list data. Update the runbook data store
 * manually.

 * If we're on a numerical version url and we resume, we need to update the data without navigating. In this case
 * just refetch all the data.
 */

const useProcessRunResponseCallback = () => {
  const navigate = useNavigate()

  return useRecoilCallback(({ snapshot, set }) => async (response: ProgressRunbookResponse) => {
    const responseRunbook = response.runbook ?? response.meta.runbook
    const responseRunbookCurrentVersionId = responseRunbook.current_version?.id || responseRunbook.runbook_version_id
    const runbookVersionUrlParam = await snapshot.getPromise(runbookVersionUrlParamState)

    if (runbookVersionUrlParam === 'current_version' || runbookVersionUrlParam !== responseRunbookCurrentVersionId) {
      const accountSlugUrlParam = await snapshot.getPromise(accountSlugUrlParamState)

      navigate(
        `/app/${accountSlugUrlParam}/runbooks/${responseRunbook.id}/${responseRunbookCurrentVersionId}/tasks/react-list`
      )

      // updating the runbook data here even though need to do it across both conditions because in the second we're transacting all 3 data store updates
      set(runbookResponseState_INTERNAL, prev =>
        produce(prev, draft => {
          extend(draft.runbook, responseRunbook)
        })
      )
    }
    // In the resume case, we should just refresh all the data, so this triggers this with proper suspense loading states in the UI
    else {
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'tasks' } }))
    }
  })
}
