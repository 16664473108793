import {
  useGetCustomFieldGroup,
  useGetCustomFieldGroupCallback,
  useGetCustomFieldGroupCallbackSync,
  useGetCustomFieldGroupLookup,
  useGetCustomFieldGroupLookupCallback,
  useGetCustomFieldGroupLookupCallbackSync,
  useGetCustomFieldGroups,
  useGetCustomFieldGroupsCallback,
  useGetCustomFieldGroupsCallbackSync
} from '../hooks/recoil/custom-field-group'
import { CustomFieldGroupModelType } from './types'

export const CustomFieldGroupModel: CustomFieldGroupModelType = {
  get: useGetCustomFieldGroup,
  getCallback: useGetCustomFieldGroupCallback,
  getCallbackSync: useGetCustomFieldGroupCallbackSync,
  getAll: useGetCustomFieldGroups,
  getAllCallback: useGetCustomFieldGroupsCallback,
  getAllCallbackSync: useGetCustomFieldGroupsCallbackSync,
  getLookup: useGetCustomFieldGroupLookup,
  getLookupCallback: useGetCustomFieldGroupLookupCallback,
  getLookupCallbackSync: useGetCustomFieldGroupLookupCallbackSync
}
