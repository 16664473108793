import { useTaskListTask } from 'main/recoil/data-access'
import { AddSnippetModal } from './snippets/add-snippet/add-snippet-modal'
import { LinkTemplateSelectWizard } from './link-template/link-template-select/link-template-select-wizard'
import {
  TaskAbandonConfirmModal,
  TaskActionModal,
  TaskFinishConfirm,
  TaskFixedStartOverride,
  TaskOverride,
  TasksDeleteModal,
  TaskSkip
} from './task-modals'
import { TaskIntegrationAbortModal } from './task-modals/task-integration-abort-modal'
import { ActiveAccountModel, ActiveRunbookModel, ActiveRunbookVersionModel, RunbookViewModel } from 'main/data-access'

export const RunbookProgressionModals = () => {
  const { active: activeModal } = RunbookViewModel.get('modal')
  const modalClose = RunbookViewModel.onAction('modal:close')
  const selectedIdsRemoveAll = RunbookViewModel.onAction('selectedIds:removeAll')

  return (
    <>
      {activeModal?.type === 'snippet-add' && (
        <AddSnippetModal initiallyOpen taskId={activeModal?.id} onClose={modalClose} />
      )}
      {activeModal?.type === 'linked-runbook-add' && (
        <LinkTemplateSelectWizardRecoilWrapper onClose={modalClose} id={activeModal?.id} />
      )}
      {activeModal?.type === 'tasks-delete' && (
        <TasksDeleteModal
          taskIds={activeModal.id}
          open
          onSuccess={() => {
            selectedIdsRemoveAll()
            modalClose()
          }}
          onClose={() => {
            modalClose()
          }}
        />
      )}
      {activeModal?.type === 'task-action' && <TaskActionModal onClose={modalClose} id={activeModal.id} />}
      {activeModal?.type === 'task-override' && <TaskOverride onClose={modalClose} id={activeModal.id} />}
      {activeModal?.type === 'task-override-fixed-start' && (
        <TaskFixedStartOverride id={activeModal.id} onClose={modalClose} />
      )}
      {activeModal?.type === 'task-finish-confirm' && <TaskFinishConfirm onClose={modalClose} id={activeModal.id} />}
      {activeModal?.type === 'tasks-skip' && <TaskSkip onClose={modalClose} ids={activeModal.id} />}
      {activeModal?.type === 'task-abandon-confirm' && (
        <TaskAbandonConfirmModal onClose={modalClose} id={activeModal.id} data={activeModal.data} />
      )}
      {activeModal?.type === 'integration-abort' && (
        <TaskIntegrationAbortModal open onClose={modalClose} taskId={activeModal.taskId} name={activeModal.name} />
      )}
    </>
  )
}

const LinkTemplateSelectWizardRecoilWrapper = ({ id, onClose }: { onClose: () => void; id: number }) => {
  const task = useTaskListTask(id)
  const accountSlug = ActiveAccountModel.get('slug')
  const runbookId = ActiveRunbookModel.get('id')
  const runbookVersionId = ActiveRunbookVersionModel.get('id')

  return (
    <LinkTemplateSelectWizard
      onClose={onClose}
      accountId={accountSlug}
      onSubmit={() => {}}
      task={task}
      runbookId={String(runbookId)}
      runbookVersionId={String(runbookVersionId)}
    />
  )
}
