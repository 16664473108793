import { VolumeTable, VolumeTableProps } from './volume-table'
import { DashboardComponentProps } from '../../types'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import { useFeature } from 'main/services/hooks'

type VolumeTableWidgetProps = DashboardComponentProps<VolumeTableProps>

export const VolumeTableWidget = (props: VolumeTableWidgetProps) => {
  const { media } = props

  switch (media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <ServerSideWidget {...props} />
    case 'print':
      return <ServerSideWidget {...props} />
  }
}

const BrowserWidget = ({ data }: VolumeTableWidgetProps) => {
  const { notes, errors, name } = data
  const { isEnabled } = useFeature()
  const reactWorkspace = isEnabled('react_workspace')

  return (
    <MrdDashboardWidget title={name} notes={notes} errors={errors} fullWidth>
      {data.total ? <VolumeTable {...data} reactWorkspace={reactWorkspace} /> : <></>}
    </MrdDashboardWidget>
  )
}

const ServerSideWidget = ({ data }: VolumeTableWidgetProps) => {
  const { notes, errors, name } = data

  return (
    <MrdDashboardWidget title={name} notes={notes} errors={errors} fullWidth>
      <VolumeTable {...data} />
    </MrdDashboardWidget>
  )
}
