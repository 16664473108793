import { memo } from 'react'
import { useLocation, useMatch } from 'react-router-dom'

import { SubHeaderButton, SubHeaderButtonGroup } from 'main/components/layout/shared/sub-header/sub-header-button'
import { useFeature, useLanguage } from 'main/services/hooks'

export type DisplayType = 'react-list' | 'react-dashboard' | 'list' | 'react-map' | 'map' | 'table'

export const ViewNavigation = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'runbookViewToggle' })
  const { isEnabled } = useFeature()
  const reactNodeMapFeature = isEnabled('react_node_map')
  const location = useLocation()
  const displayMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/:displayType')
  const currentPage = displayMatch?.params.displayType as DisplayType | undefined
  const accountId = displayMatch?.params.accountId
  const runbookId = displayMatch?.params.runbookId
  const runbookVersionId = displayMatch?.params.runbookVersionId

  return (
    <SubHeaderButtonGroup>
      <SubHeaderButton
        label={'List'}
        active={currentPage === 'react-list'}
        navTo={`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/react-list${location.search}`}
        pill={{ label: 'React', size: 'small', color: 'feature-orange' }}
      />
      <SubHeaderButton
        label={t('list')}
        active={currentPage === 'list'}
        navTo={`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/list${location.search}`}
      />
      {reactNodeMapFeature && (
        <SubHeaderButton
          label="Map"
          active={currentPage === 'react-map'}
          navTo={`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/react-map${location.search}`}
          pill={{ label: 'React', size: 'small', color: 'feature-orange' }}
        />
      )}
      <SubHeaderButton
        label={t('nodemap')}
        active={currentPage === 'map'}
        navTo={`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/map${location.search}`}
      />
      <SubHeaderButton
        label={t('table')}
        active={currentPage === 'table'}
        navTo={`/app/${accountId}/runbooks/${runbookId}/${runbookVersionId}/tasks/table${location.search}`}
      />
    </SubHeaderButtonGroup>
  )
})
