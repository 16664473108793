import { forwardRef, ReactNode, useEffect } from 'react'

import { RunbookTypeForm } from './runbook-type-form'
import { useRunbookTypeCreate } from './use-runbook-type'
import { useGlobalConfig } from 'main/services/hooks'

export type RunbookTypeCreateFormProps = {
  onSave?: () => void
  children?: ReactNode
  isGlobalUser: () => boolean
  availableApprovalFlows: []
}

function randomElement<T>(array: T[]) {
  return array[Math.floor(Math.random() * array.length)]
}

export const RunbookTypeCreateForm = forwardRef<HTMLFormElement, RunbookTypeCreateFormProps>(function (props, ref) {
  const { onSave, children, isGlobalUser, availableApprovalFlows } = props
  const { mutate, isError, error, isSuccess } = useRunbookTypeCreate()
  const { runbookTypeIcons, runbookTypeColors } = useGlobalConfig()

  useEffect(() => {
    if (isSuccess && onSave) {
      onSave()
    }
  }, [isSuccess, onSave])

  const handleSave = (data: RunbookTypeForm) => {
    const settings: string[] = data?.settings ?? []

    mutate({
      ...{ ...data, settings: undefined },
      dynamic: settings.includes('dynamic'),
      enableRto: settings.includes('enableRto'),
      incident: settings.includes('incident'),
      restrictCreateToTemplates: settings.includes('restrictCreateToTemplate')
    })
  }

  const serverError = isError
    ? error?.status === 422
      ? error.errors[0]
      : 'There was an error creating the runbook type.'
    : undefined

  return (
    <RunbookTypeForm
      ref={ref}
      mode="create"
      iconIds={runbookTypeIcons}
      iconColors={runbookTypeColors}
      defaultValues={{
        visibility: 'global',
        accountId: null,
        iconName: randomElement(runbookTypeIcons),
        iconColor: randomElement(runbookTypeColors),
        settings: []
      }}
      availableApprovalFlows={availableApprovalFlows}
      serverError={serverError}
      onSave={handleSave}
      isGlobalUser={isGlobalUser}
    >
      {children}
    </RunbookTypeForm>
  )
})
