import { MouseEvent } from 'react'
import { useParams } from 'react-router-dom'

import { LegacyBox as Box, colors, LoadingPanel, LegacyText as Text } from '@cutover/react-ui'
import { RunbookDashboardSchedule } from './runbook-dashboard-schedule'
import { SchedulePanelContent } from './runbook-dashboard-schedule-panel-styles'
import { UserSettingResponse, UserSettingsResponse } from './runbook-dashboard-schedule-types'
import { useRunbookDashboardSchedulesQuery } from '../../../services/queries/use-email-schedule-user-settings'
import { useLanguage } from 'main/services/hooks'

type RunbookDashboardScheduleListProps = {
  onScheduleClick: (event: MouseEvent<HTMLDivElement>, schedule: UserSettingResponse) => void
  data: UserSettingsResponse
  onClose: () => void
  onAdd: () => void
}

export const RunbookDashboardScheduleList = ({
  onScheduleClick,
  onClose,
  onAdd
}: RunbookDashboardScheduleListProps) => {
  const { translate } = useLanguage()
  const { runbookId, dashboardId } = useParams<{ runbookId?: string; dashboardId?: string }>()
  const { data, isLoading } = useRunbookDashboardSchedulesQuery({ runbookId, dashboardId })

  return (
    <>
      {isLoading ? (
        <LoadingPanel />
      ) : (
        <>
          <SchedulePanelContent
            isDirty={false}
            onClose={onClose}
            title={translate('dashboard:shareSchedulePanel:heading')}
            onAdd={onAdd}
          >
            {data && data.user_settings.length > 0 ? (
              data?.user_settings.map((setting: UserSettingResponse) => {
                // 1 schedule per setting
                const schedule = setting.data.schedules[0]

                return (
                  schedule.frequency?.option &&
                  schedule.schedule_time && (
                    <Box padding={[0, 0, 16, 0]} key={setting.id}>
                      <RunbookDashboardSchedule
                        onClick={e => onScheduleClick(e, setting)}
                        id={setting.id}
                        title={schedule.name || ''}
                        numberOfRecipients={setting.meta.recipient_count}
                        scheduleType={schedule.frequency.option}
                        scheduleTime={schedule.schedule_time}
                        timezone={setting.data.timezone}
                        days={schedule.day_options}
                        filter={schedule.filter_name}
                      />
                    </Box>
                  )
                )
              })
            ) : (
              <Box padding={12} css="text-align: center">
                <Text color={colors.primaryGrey}>{translate('dashboard:shareSchedulePanel:noSchedules')}</Text>
              </Box>
            )}
          </SchedulePanelContent>
        </>
      )}
    </>
  )
}
