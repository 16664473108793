import {
  useGetCustomFieldUser,
  useGetCustomFieldUserCallback,
  useGetCustomFieldUserCallbackSync,
  useGetCustomFieldUserLookup,
  useGetCustomFieldUserLookupCallback,
  useGetCustomFieldUserLookupCallbackSync,
  useGetCustomFieldUsers,
  useGetCustomFieldUsersCallback,
  useGetCustomFieldUsersCallbackSync
} from '../hooks/recoil/custom-field-user'
import { CustomFieldUserModelType } from './types'

export const CustomFieldUserModel: CustomFieldUserModelType = {
  get: useGetCustomFieldUser,
  getCallback: useGetCustomFieldUserCallback,
  getCallbackSync: useGetCustomFieldUserCallbackSync,
  getAll: useGetCustomFieldUsers,
  getAllCallback: useGetCustomFieldUsersCallback,
  getAllCallbackSync: useGetCustomFieldUsersCallbackSync,
  getLookup: useGetCustomFieldUserLookup,
  getLookupCallback: useGetCustomFieldUserLookupCallback,
  getLookupCallbackSync: useGetCustomFieldUserLookupCallbackSync
}
