import { createContext, useCallback, useEffect } from 'react'
import { eventManager } from 'event-manager'
import { useLocation, useParams } from 'react-router-dom'

import { ContentWrapper, NoResourceFound, px2rem } from '@cutover/react-ui'
import { RunbookWidgetCollection, StatefulAccountWidgetCollection } from './dashboard-widget-collection'
import { useDashboardQuery } from '../../services/queries/use-dashboard-query'
import { DashboardComponentCollection, DashboardMediaType } from '../dashboards/widgets/types'
import { useBrowserQueryStringToServerQueryString } from '../shared/filter/filter-params'
import { useClearFilterParams } from 'main/components/shared/filter/filter-provider'
import { useFeature } from 'main/services/hooks'
import { DashboardContextType } from 'main/services/queries/types'

type DashboardContextObject = {
  accountId: string
  dashboardId: string
  filterParams: string
}

export const DashboardContext = createContext<DashboardContextObject>({} as unknown as DashboardContextObject)

export const RunbooksDashboardPage = () => {
  const { accountId, dashboardId } = useParams<{ accountId?: string; dashboardId?: string }>()
  const location = useLocation()
  const { isEnabled } = useFeature()
  const reactWorkspace = isEnabled('react_workspace')
  const clearFilters = useClearFilterParams()

  useSetDefaultDashboard({ accountId, dashboardId })

  const filterParams = useBrowserQueryStringToServerQueryString({
    query: location.search,
    defaults: { dashboardId }
  })

  const option = { enabled: Boolean(accountId && dashboardId) }
  const dashboardQuery = useDashboardQuery({ accountId, dashboardKey: dashboardId, filterParams }, option)
  const { data, isLoading, isRefetching } = dashboardQuery

  // TODO: remove useEffect once React Workspace Route feature flag is removed
  useEffect(() => {
    if (!reactWorkspace) {
      const handleReload = () => dashboardQuery.refetch()

      eventManager.on('dashboard-reload', handleReload)
      return () => {
        eventManager.off('dashboard-reload', handleReload)
      }
    }
  }, [])

  const clearAllFilters = useCallback(() => {
    if (reactWorkspace) {
      clearFilters()
    } else {
      eventManager.emit('clear-runbook-filters')
    }
  }, [])

  const showLoading = isLoading || isRefetching || !(accountId && dashboardId)

  return (
    <ContentWrapper
      aria-labelledby="tab-dashboards"
      id="tabpanel-dashboards"
      loading={showLoading}
      hasHeader={false}
      css={reactWorkspace ? 'padding: 0' : ''}
    >
      {accountId && dashboardId && data && (
        <DashboardContext.Provider value={{ accountId, dashboardId, filterParams }}>
          {(data.meta.filtered_results_count ?? 0) > 0 ? (
            <WidgetCollection
              context={data.dashboard.context}
              components={data.dashboard.components}
              media={data.dashboard.media}
              requestId={data.requestId}
            />
          ) : (
            <NoResourceFound clearAllFilters={clearAllFilters} context={'runbook'} css={{ padding: px2rem(12) }} />
          )}
        </DashboardContext.Provider>
      )}
    </ContentWrapper>
  )
}

type WidgetCollectionProps = {
  context: DashboardContextType
  media: DashboardMediaType
  components: DashboardComponentCollection
  requestId?: string
}

const WidgetCollection = ({ context, components, media, requestId }: WidgetCollectionProps) => {
  return context === 'Account' ? (
    <StatefulAccountWidgetCollection components={components} media={media} requestId={requestId} />
  ) : (
    <RunbookWidgetCollection components={components} media={media} />
  )
}

const useSetDefaultDashboard = ({ accountId, dashboardId }: { accountId?: string; dashboardId?: string }) => {
  useEffect(() => {
    if (!accountId || !dashboardId) {
      return
    }
    const existingSettings = localStorage.getItem('last_viewed_workspace_dashboard')
    const parsedExistingSettings = existingSettings ? JSON.parse(existingSettings) : {}

    // If we already have a saved dashboardId for this workspace, overwrite it
    parsedExistingSettings[accountId] = dashboardId

    localStorage.setItem('last_viewed_workspace_dashboard', JSON.stringify(parsedExistingSettings))
  }, [accountId, dashboardId])
}
