import { useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { RunbookViewModel } from 'main/data-access'
import { useTaskProgression } from 'main/recoil/data-access'

export const useTaskListHotkeys = () => {
  const canInitiateBulkEditActions = RunbookViewModel.canActionCallback('initiate:bulkEditActions')
  const canInitiateBulkProgressionActions = RunbookViewModel.canActionCallback('initiate:bulkProgressionActions')
  const selectedIdsRemoveAll = RunbookViewModel.onAction('selectedIds:removeAll')
  const selectedIdsSelectAll = RunbookViewModel.onAction('selectedIds:selectAll')
  const selectedIdsValueCallback = RunbookViewModel.getCallback('selectedIds')
  const modalOpen = RunbookViewModel.onAction('modal:open')

  const { onSkipTasks } = useTaskProgression()

  const handleSkip = useCallback(
    () => async () => {
      if (await canInitiateBulkProgressionActions()) onSkipTasks()
    },
    [canInitiateBulkProgressionActions, onSkipTasks]
  )

  const handleDelete = useCallback(
    () => async () => {
      if (await canInitiateBulkEditActions()) modalOpen({ type: 'tasks-delete', id: await selectedIdsValueCallback() })
    },
    [canInitiateBulkEditActions, modalOpen, selectedIdsValueCallback]
  )

  useHotkeys('meta+a', selectedIdsSelectAll, { preventDefault: true }, [])
  useHotkeys('meta+d', selectedIdsRemoveAll, { preventDefault: true }, [])
  useHotkeys('meta+k', handleSkip, { preventDefault: true }, [])
  useHotkeys('Backspace', handleDelete, { preventDefault: true }, [])
}
