import axios from 'axios'
import { useMutation } from 'react-query'

import { useFeature } from '../hooks'

export type RequestPasswordResetPayload = {
  email: string
  fail_url: string
  redirect_url: string
}

export const useRequestPasswordReset = () => {
  const { isEnabled } = useFeature()

  return useMutation('requestPasswordReset', async (payload: Pick<RequestPasswordResetPayload, 'email'>) => {
    const data = {
      ...payload,
      fail_url: `${window.location.origin}/#/${isEnabled('react_login') ? 'forgotpassword' : 'forgot-password'}`,
      redirect_url: `${window.location.origin}/#/${isEnabled('react_login') ? 'resetpassword' : 'reset-password'}`
    } as RequestPasswordResetPayload

    await axios.post<RequestPasswordResetPayload, {}>('/api/auth/password', data)
  })
}
