import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataSourcesHeader } from './data-sources-header/data-sources-header'
import { DataSourcesEditPanel } from './right-panels/data-sources-edit-panel'
import { Layout } from 'main/components/layout'
import { useDefaultLayout } from 'main/components/layout/layout-hooks'
import { useFeature, useHasPermissionsOrNavigateHome } from 'main/services/hooks'

export const DataSourcesLayout = () => {
  const { isEnabled } = useFeature()
  const navigate = useNavigate()

  const dataSourcesPageEnabled = isEnabled('searchable_fields_admin')

  // TODO: remove once Data Sources page is no longer behind feature flag
  useEffect(() => {
    if (!dataSourcesPageEnabled) {
      navigate('/app/my-cutover')
    }
  }, [dataSourcesPageEnabled])

  const userCanViewPage = useHasPermissionsOrNavigateHome('data_sources')

  useDefaultLayout({
    filterPanel: false,
    subHeader: false,
    rightNav: false
  })

  return userCanViewPage ? (
    <Layout header={<DataSourcesHeader />} filter={null} rightPanels={<DataSourcesEditPanel />} />
  ) : null
}
