import { useRecoilCallback, useRecoilValue } from 'recoil'

import { useTaskListTaskProperty } from 'main/recoil/data-access'
import {
  RunbookTeamsPermissionsResponse,
  taskListTaskState,
  teamsState,
  teamsStateLookup,
  teamState,
  usersTeamsPermissions
} from 'main/recoil/runbook'

export const useTeamValue = (id: number) => useRecoilValue(teamState(id))

export const useTeamValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (id: number) =>
        await snapshot.getPromise(teamState(id))
  )

export const useTeamValueCallbackSync = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      (id: number) =>
        snapshot.getLoadable(teamState(id)).getValue()
  )

export const useAllTeamsByTaskId = (taskId: number) => {
  const lookup = useRecoilValue(teamsStateLookup)
  const teamIds = useTaskListTaskProperty({ id: taskId, attribute: 'runbook_team_ids' })

  return teamIds?.map(id => lookup[id])
}

export const useAllTeamsByTaskIdCallbackSync = () =>
  useRecoilCallback(({ snapshot }) => ({ taskId }: { taskId: number }) => {
    const lookup = snapshot.getLoadable(teamsStateLookup).getValue()
    const teamIds = snapshot.getLoadable(taskListTaskState(taskId)).getValue().runbook_team_ids

    return teamIds?.map(id => lookup[id])
  })

export const useAllTeamsValue = () => {
  return useRecoilValue(teamsState)
}

export const useAllTeamsValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(teamsState)
  )

export const useAllTeamsValueCallbackSync = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(teamsState).getValue()
  )

export const useTeamsByIds = (ids: number[]) => {
  const lookup = useRecoilValue(teamsStateLookup)
  return ids.map(i => lookup[i])
}

export const useGetAllTeamsByCallback = () =>
  useRecoilCallback(({ snapshot }) => async ({ taskId, ids }: { taskId?: number; ids?: number[] } = {}) => {
    const lookup = await snapshot.getPromise(teamsStateLookup)

    if (typeof taskId === 'number') {
      const teamIds = (await snapshot.getPromise(taskListTaskState(taskId))).runbook_team_ids
      return teamIds?.map(id => lookup[id])
    }

    if (ids) return ids.map(i => lookup[i])

    return await snapshot.getPromise(teamsState)
  })

export const useGetAllTeamsByCallbackSync = () =>
  useRecoilCallback(({ snapshot }) => ({ taskId, ids }: { taskId?: number; ids?: number[] } = {}) => {
    const lookup = snapshot.getLoadable(teamsStateLookup).getValue()

    if (typeof taskId === 'number') {
      const teamIds = snapshot.getLoadable(taskListTaskState(taskId)).getValue().runbook_team_ids
      return teamIds?.map(id => lookup[id])
    }

    if (ids) return ids.map(i => lookup[i])

    return snapshot.getLoadable(teamsState).getValue()
  })

export const useRunbookTeamsPermission = <TKey extends keyof RunbookTeamsPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => {
  return useRecoilValue(usersTeamsPermissions({ attribute }))
}

export const useRunbookTeamsLookup = () => useRecoilValue(teamsStateLookup)

export const useRunbookTeamsLookupCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(teamsStateLookup)
  )

export const useRunbookTeamsLookupCallbackSync = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(teamsStateLookup).getValue()
  )
