import { memo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AccordionPanel, Box, Icon, ListItem, Message, Pill, RadioboxGroup, Text } from '@cutover/react-ui'
import { RunbookEditFormType } from './runbook-edit-form'
import { useLanguage } from 'main/services/hooks'
import { CustomField, RTA_STATUSES, RtaResult, RunbookEditRunbook } from 'main/services/queries/types'
import { DurationPickerField, SelectField, TextAreaField } from '../../form'
import { useRouting } from 'main/services/routing/hooks'

type RunbookEditRtoSettingsPanelProps = {
  runbook: RunbookEditRunbook
  customFields: CustomField[]
  disabled?: boolean
  readOnly?: boolean
}

export const RunbookEditRtoPanel = memo(
  ({ runbook, disabled, readOnly, customFields }: RunbookEditRtoSettingsPanelProps) => {
    const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
    const [rtoSource, _setRtoSource] = useState(runbook.rto_source_id ? 'choose_field' : 'define_manually')
    const [rtaStatus, _setRtaStatus] = useState(initialRtaStatus)
    const { setValue, getValues } = useFormContext<RunbookEditFormType>()
    const { toRunbook } = useRouting()
    const navigate = useNavigate()

    const setRtoSource = (rtoSource: string) => {
      if (rtoSource === 'choose_field') {
        setValue('runbook.rto', null)
      } else {
        setValue('runbook.rto_source_id', null)
      }

      _setRtoSource(rtoSource)
    }

    const RtaResultsPanel = () => {
      let rows = runbook.rta_results.map(result => {
        let status = statusForRta(result)

        return (
          <ListItem
            title={result.start_actual.toString()}
            size="small"
            onClick={() => navigate(toRunbook({ accountSlug: 'SA', runbookId: result.runbook_id }))}
            startComponent={<Icon icon="runbook" size="small" />}
            endComponents={[
              <Text color="text-light" size="small">
                {result.rta_duration}
              </Text>,
              <Text color={status.colour} size="small">
                ({result.rta_diff < 0 ? '' : '+'}
                {result.rta_diff})
              </Text>,
              <Pill label={status.name} color={status.colour} size="small" />
            ]}
          />
        )
      })

      return (
        <Box>
          <Text size="small" color="text-light">
            Runbook RTA results
          </Text>
          {rows}
        </Box>
      )
    }

    return (
      <AccordionPanel
        label={t('rtoRta.label')}
        icon="target"
        iconColor={runbook.project.color}
        labelSuffix={<Pill label={rtaStatus.name} color={rtaStatus.colour} size="small" />}
      >
        <RadioboxGroup
          name="toggle_rto_source"
          direction="row"
          label={t('rtoRta.chooseSource')}
          disabled={disabled}
          readOnly={readOnly}
          options={[
            { value: 'define_manually', label: t('rtoRta.manualSource') },
            { value: 'choose_field', label: t('rtoRta.fieldSource') }
          ]}
          defaultValue={rtoSource}
          onChange={event => setRtoSource(event.target.value)}
        />

        <DurationPickerField<RunbookEditFormType>
          label={t('rtoRta.rto.label')}
          disabled={disabled || rtoSource === 'choose_field'}
          readOnly={readOnly}
          value={runbook.rto || undefined}
          name="runbook.rto"
        />

        <SelectField
          required={rtoIsRequired()}
          disabled={disabled || rtoSource === 'define_manually'}
          name="runbook.rto_source_id"
          label={t('rtoRta.rtoSource.label')}
          options={
            customFields ? customFields.map(customField => ({ label: customField.name, value: customField.id })) : []
          }
        />

        <TextAreaField<RunbookEditFormType>
          label={t('rtoRta.startTask.label')}
          required={rtoIsRequired()}
          readOnly={readOnly}
          disabled={disabled}
          defaultValue={runbook.current_version.rto_start_task_id?.toString()}
          name="runbook.runbook_versions_attributes.0.rto_start_task_id"
        />
        <TextAreaField<RunbookEditFormType>
          label={t('rtoRta.endTask.label')}
          required={rtoIsRequired()}
          readOnly={readOnly}
          disabled={disabled}
          defaultValue={runbook.current_version.rto_end_task_id?.toString()}
          name="runbook.runbook_versions_attributes.0.rto_end_task_id"
        />
        {rtaStatus.name === RTA_STATUSES['pending'].name && <Message type="info">{t('rtoRta.rtaResultsInfo')}</Message>}

        {runbook.rta_results.length > 0 && <RtaResultsPanel />}
      </AccordionPanel>
    )

    function initialRtaStatus() {
      if (runbook.rta_results.length > 0) {
        return statusForRta(runbook.rta_results[0])
      } else if (runbook.rto && runbook.current_version.rto_start_task_id && runbook.current_version.rto_end_task_id) {
        return RTA_STATUSES['pending']
      } else return RTA_STATUSES['missing']
    }

    function statusForRta(rta_result: RtaResult) {
      return rta_result.rta_diff < 0 ? RTA_STATUSES['fail'] : RTA_STATUSES['pass']
    }

    function rtoIsRequired() {
      return (
        (getValues([
          'runbook.rto_source_id',
          'runbook.runbook_versions_attributes.0.rto_start_task_id',
          'runbook.runbook_versions_attributes.0.rto_end_task_id'
        ]).filter(el => el !== null).length > 0 &&
          getValues('runbook.rto') !== null) ||
        getValues('runbook.rto_source_id') !== 0
      )
    }
  }
)
