import { useMemo, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { TFunction } from 'react-i18next'
import { isEmpty } from 'lodash'

import { Box, Button, SanitizedString, Text } from '@cutover/react-ui'
import { FieldOption, FieldValue, TaskAction, TaskListTask } from 'main/services/queries/types'
import { IntegrationDebugData } from 'main/components/integrations/types'
import { ExtendedCustomField } from 'main/recoil/runbook/models/account/custom-fields'
import { useLanguage } from 'main/services/hooks'
import { IntegrationDebugModal } from 'main/components/integrations/integration-debug-modal'
import { useCustomFieldLookup, useCustomFieldOptionsLookup, useTaskListLookupState } from 'main/recoil/data-access'

type DisplayAction = {
  author?: string
  bold?: boolean
  content: string
  index: number
  date: number
  overrides?: string
  valueData?: ValueData[]
  debugData?: IntegrationDebugData
}

type ValueData = { fieldName: string; fieldValue: string }

export const TaskActionContent = ({ task }: { task: TaskListTask }) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.actionAccordion' })
  const [selectedDebugData, setSelectedDebugData] = useState<IntegrationDebugData | undefined>(undefined)
  const cfLookup = useCustomFieldLookup()
  const optionsLookup = useCustomFieldOptionsLookup()
  const taskLookup = useTaskListLookupState()

  const displayActions = useMemo(
    () => buildActions({ task, cfLookup, optionsLookup, taskLookup, t }),
    [task, cfLookup, optionsLookup, taskLookup, t]
  )

  return (
    <>
      {displayActions.length ? (
        <>
          {displayActions.map(displayAction => (
            <TaskActionContentInner
              key={`action-${displayAction.index}`}
              displayAction={displayAction}
              onDebugSelection={debugData => setSelectedDebugData(debugData)}
            />
          ))}
          <IntegrationDebugModal
            debugData={selectedDebugData ?? {}}
            open={!!selectedDebugData}
            onDebugClose={() => setSelectedDebugData(undefined)}
          />
        </>
      ) : (
        <Text>{t('noActions')}</Text>
      )}
    </>
  )
}

type TaskActionContentInnerProps = {
  displayAction: DisplayAction
  onDebugSelection: (debugData: IntegrationDebugData) => void
}

const TaskActionContentInner = ({ displayAction, onDebugSelection }: TaskActionContentInnerProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.actionAccordion' })
  const { index, bold, date, content, author, overrides, debugData, valueData } = displayAction

  return (
    <Box direction="row" gap="xxsmall" pad={{ bottom: 'xxsmall' }}>
      <Box data-testid={`date-${index}`} width="xsmall" flex={false}>
        <Text color="text-light">{format(date, 'd MMM HH:mm')}</Text>
      </Box>
      <Box data-testid={`content-${index}`}>
        <Text weight={bold ? 'bold' : 'normal'} css="white-space: normal">
          {content} {author && t('author', { author })} {overrides && t('overrides', { overrides })}
          {debugData && (
            <Button
              plain
              color="primary"
              label={t('debug')}
              css="text-decoration: underline;"
              onClick={() => onDebugSelection(debugData)}
            />
          )}
        </Text>
        {valueData?.map((vd, index) => (
          <Text color="text-light" size="small" key={`${vd.fieldName}-${index}`} css="white-space: normal">
            {vd.fieldName}:{' '}
            <SanitizedString data-testid={`task-action-${vd.fieldName}-value`} size="small" input={vd.fieldValue} />
          </Text>
        ))}
      </Box>
    </Box>
  )
}

const buildActions = ({
  task,
  cfLookup,
  optionsLookup,
  taskLookup,
  t
}: {
  task: TaskListTask
  cfLookup: { [x: number]: ExtendedCustomField }
  optionsLookup: { [x: number]: FieldOption }
  taskLookup: Record<number, TaskListTask>
  t: TFunction<'tasks', 'editPanel.actionAccordion'>
}) => {
  // actions processed in task.task_actions
  const displayActions = processTaskActions({
    actions: task.task_actions ?? [],
    fieldValues: task.field_values ?? [],
    cfLookup,
    optionsLookup,
    t
  })

  const taskSkippedOrAbandoned =
    task.completion_type === 'complete_skipped' || task.completion_type === 'complete_abandoned'
  const taskAbandoned = task.completion_type === 'complete_abandoned'

  // Values in task date fields do not resolve to milliseconds as do
  // task action dates, so we adjust these according to the use case.
  if ((task.start_ready || task.stage === 'startable') && !taskAbandoned) {
    const startReady = {
      bold: true,
      content: t('actions.becomeStartable'),
      // keep base second as date should be before any other action
      date: task.start_ready ? task.start_ready * 1000 : task.start_display * 1000
    } as DisplayAction
    displayActions.push(startReady)
  }

  if (task.start_actual && !taskSkippedOrAbandoned) {
    const startActual = {
      bold: true,
      content: t('actions.started'),
      // bump to next second as date should be after 'approved to start' actions
      date: (task.start_actual + 1) * 1000
    } as DisplayAction
    displayActions.push(startActual)
  }

  if (task.end_actual && taskAbandoned) {
    let taskAbandonedMeta = ''
    const taskCompletionMeta = task.completion_meta ? JSON.parse(task.completion_meta) : undefined

    if (taskCompletionMeta) {
      const taskName = findTaskName(taskLookup, task.id, taskCompletionMeta.abandon_trigger) ?? ''
      taskAbandonedMeta = `${taskCompletionMeta.abandon_trigger} ${taskName}`
    }

    const abandoned = {
      content: t('actions.abandoned', { meta: taskAbandonedMeta }),
      // keep base second as date should be before finish action
      date: task.end_actual * 1000
    } as DisplayAction
    displayActions.push(abandoned)
  }

  if (task.end_actual) {
    const endActual = {
      bold: true,
      content: t('actions.finished'),
      // bump to next second as date should be after 'approved to finish' actions
      date: (task.end_actual + 1) * 1000
    } as DisplayAction
    displayActions.push(endActual)
  }

  const orderedDisplayActions = displayActions.sort((a, b) => {
    return a.date - b.date
  })
  return orderedDisplayActions.map((action, index) => ({ ...action, index }))
}

// depth-first search of taskLookup to find task name with matching internal_id
const findTaskName = (
  taskLookup: Record<number, TaskListTask>,
  startTaskId: number,
  internalIdToFind: number
): string | undefined => {
  const visited: Set<number> = new Set()
  const stack: number[] = [startTaskId]

  while (stack.length > 0) {
    const currentTaskId = stack.pop()

    if (currentTaskId === undefined) {
      break
    }

    if (visited.has(currentTaskId)) {
      continue
    }

    visited.add(currentTaskId)

    const currentTask = taskLookup[currentTaskId]

    if (currentTask.internal_id === internalIdToFind) {
      return currentTask.name
    }

    for (const predecessorId of currentTask.predecessor_ids) {
      stack.push(predecessorId)
    }
  }

  return undefined
}

const processTaskActions = ({
  actions,
  fieldValues,
  cfLookup,
  optionsLookup,
  t
}: {
  actions: TaskAction[]
  fieldValues: FieldValue[]
  cfLookup: { [x: number]: ExtendedCustomField }
  optionsLookup: { [x: number]: FieldOption }
  t: TFunction<'tasks', 'editPanel.actionAccordion'>
}): DisplayAction[] =>
  actions.reduce((acc: DisplayAction[], current: TaskAction) => {
    const actionIndex = current.action

    const action = {} as DisplayAction
    action.content = t(`actions.${actionContentKeys[actionIndex]}`)
    action.date = parseISO(`${current.created_at}Z`).getTime()
    const debugData =
      current.debug_data && !isEmpty(current.debug_data) ? buildDebugData(current.debug_data) : undefined

    switch (actionIndex) {
      case 0:
      case 1:
        action.author = current.author_name
        action.valueData = buildActionValues({ actionId: current.id, fieldValues, cfLookup, optionsLookup })
        action.overrides = current.author_id !== current.user_id ? current.user_name : undefined
        break
      case 2:
        // TODO: 'skipped' task_action is not serialized by the back end when task is skipped.
        // Dipslays default "became startable", "started", and "finished" actions.
        action.author = current.author_name
        break
      case 3:
        if (current.context) {
          action.content = `${current.context.html ? 'HTML' : 'Non-HTML'} emails sent`
          action.valueData = [
            {
              fieldName: '# recipients',
              fieldValue: current.context.recipients ?? 'unknown'
            }
          ]
        }
        break
      case 4:
      case 5:
      case 6:
        // noop
        break
      case 7:
        action.debugData = debugData
        break
      case 8:
        if (debugData) {
          action.debugData = { ...debugData, endpoint_failed: true }
        }
        break
      case 9:
        // noop
        break
      case 10:
        action.debugData = debugData
        break
      default:
        break
    }

    acc.push(action)
    return acc
  }, [])

const actionContentKeys: { [key: number]: string } = {
  0: 'approvedToStart',
  1: 'approvedToFinish',
  2: 'skipped',
  3: 'emailsSent',
  4: 'emailSuccess',
  5: 'emailFailure',
  6: 'endpointFired',
  7: 'endpointSuccessful',
  8: 'endpointFailed',
  9: 'endpointAborted',
  10: 'endpointRunning'
}

const buildDebugData = (data: IntegrationDebugData) => ({
  url: data.url ?? '',
  auth_type: data.auth_type ?? '',
  request_type: data.request_type ?? '',
  request_parameters: data.request_parameters ?? '',
  request_body: data.request_body ?? '',
  request_headers: data.request_headers ?? '',
  response_body: data.response_body ?? '',
  response_headers: data.response_headers ?? '',
  response_status: data.response_status ?? '',
  message_id: data.message_id ?? '',
  modal_title: data.modal_title ?? '',
  endpoint_failed: false
})

const buildActionValues = ({
  actionId,
  fieldValues,
  cfLookup,
  optionsLookup
}: {
  actionId: number
  fieldValues: FieldValue[]
  cfLookup: { [x: number]: ExtendedCustomField }
  optionsLookup: { [x: number]: FieldOption }
}) => {
  const data = [] as ValueData[]
  for (const fv of Object.values(fieldValues)) {
    const customField = cfLookup[fv.custom_field_id]

    if (fv.task_action_id === actionId && !customField.archived) {
      const fieldType = customField.field_type.slug
      let value = ''

      switch (fieldType) {
        case 'select_menu':
        case 'radiobox':
          if (fv.field_option_id) {
            value = optionsLookup[fv.field_option_id].name
          }
          break
        case 'searchable':
        case 'multi_searchable':
        case 'text':
        case 'textarea':
          value = fv.value ?? ''
          break
        case 'checkboxes':
          const selectedOptions = JSON.parse(fv.value ?? '')
          const selectedOptionNames = selectedOptions.map((opt: number) => optionsLookup[opt].name)
          if (selectedOptionNames?.length) {
            value = selectedOptionNames.join(', ')
          }
          break
      }

      if (value.length > 0) {
        data.push({ fieldName: customField.name, fieldValue: value })
      }
    }
  }
  return data
}
