import {
  canActionRun,
  canActionRunCallback,
  canActionRunCallbackSync,
  canRun,
  getRun,
  getRunCallback,
  getRunCallbackSync,
  getRunLoadable,
  getRunLoadableCallback,
  onActionRun
} from '../hooks/recoil/active-run'
import { ActiveRunModelType } from './types'

export const ActiveRunModel: ActiveRunModelType = {
  can: canRun,
  get: getRun,
  getCallback: getRunCallback,
  getCallbackSync: getRunCallbackSync,
  getLoadable: getRunLoadable,
  getLoadableCallback: getRunLoadableCallback,
  onAction: onActionRun,
  canAction: canActionRun,
  canActionCallback: canActionRunCallback,
  canActionCallbackSync: canActionRunCallbackSync
}
