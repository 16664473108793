import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Checkbox, CodeEditor, IconName, Loader, Message, Pill, Select, Text, TextInput } from '@cutover/react-ui'
import { useFeature, useGlobalConfig, useLanguage } from 'main/services/hooks'
import {
  CreateDataSourceModal,
  CreateDataSourceSchema,
  settingFieldsToRemove
} from './modals/create-new-data-source-modal'
import { IntegrationFormSettingFields } from '../integration-settings/shared-integration-components/integration-form-setting-fields'
import { SelectField, TextInputField } from 'main/components/shared/form/form-fields'
import { TestResultsResponse } from 'main/services/queries/use-data-sources'

type CreateDataSourceFormProps = {
  handleSubmitTest?: (formData: CreateDataSourceModal, query: { query_string: string }) => void
  isLoading?: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  testResults?: TestResultsResponse
  testResultsError?: string
  setTestResults?: Dispatch<SetStateAction<TestResultsResponse | undefined>>
  setTestResultsError?: Dispatch<SetStateAction<string | undefined>>
  jsonMappings?: { type: string; mapping: string; primary?: boolean }[]
  setJsonMappings?: Dispatch<SetStateAction<{ type: string; mapping: string; primary?: boolean }[]>>
  primaryKey?: string
  setPrimaryKey?: Dispatch<SetStateAction<string>>
}

export const DataSourcesForm = ({
  isLoading,
  setIsLoading,
  testResults,
  testResultsError,
  setTestResults,
  setTestResultsError,
  handleSubmitTest
}: CreateDataSourceFormProps) => {
  const { watch } = useFormContext<CreateDataSourceSchema>()
  const currentStep = watch('_step')
  const [jsonMappings, setJsonMappings] = useState<{ type: string; mapping: string; primary?: boolean }[]>([])
  const [primaryKey, setPrimaryKey] = useState<string>('')

  switch (currentStep) {
    case 1:
      return (
        <GeneralSettings
          setIsLoading={setIsLoading}
          setTestResults={setTestResults}
          setTestResultsError={setTestResultsError}
          setJsonMappings={setJsonMappings}
        />
      )
    case 2:
      return (
        <MappingSettings
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleSubmitTest={handleSubmitTest}
          setTestResults={setTestResults}
          testResults={testResults}
          testResultsError={testResultsError}
          setJsonMappings={setJsonMappings}
          jsonMappings={jsonMappings}
          setPrimaryKey={setPrimaryKey}
          primaryKey={primaryKey}
        />
      )
    default:
      return null
  }
}

const GeneralSettings = ({
  setTestResults = () => {},
  setJsonMappings = () => {},
  setTestResultsError = () => {}
}: CreateDataSourceFormProps) => {
  const { t } = useLanguage('dataSources')
  const methods = useFormContext<CreateDataSourceSchema>()
  const { requestClientSettings } = useGlobalConfig()
  const { isEnabled } = useFeature()

  // Filter Cutover Connect unless Connect Self-Serve FF is enabled
  if (requestClientSettings) {
    requestClientSettings.authType.fieldOptions = requestClientSettings.authType?.fieldOptions?.filter(fo => {
      if (fo !== 'Cutover Connect' || isEnabled('connect_settings')) {
        return true
      } else {
        return false
      }
    })
  }

  const {
    control,
    resetField,
    reset,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors }
  } = methods

  const isViaConnect = () => {
    return getValues('settings.authType') === 'Cutover Connect'
  }

  const [viaConnect, setViaConnect] = useState<boolean>(isViaConnect())

  useEffect(() => {
    resetField('json_root')
    resetField('json_mappings')
    setJsonMappings([])
    setTestResults(undefined)
    setTestResultsError(undefined)
  }, [reset])

  useEffect(() => {
    setViaConnect(isViaConnect())
  }, [watch('settings.authType')])

  useEffect(() => {
    if (requestClientSettings) {
      for (const [settingKey, settingValue] of Object.entries(requestClientSettings)) {
        if (!settingFieldsToRemove.includes(settingKey)) {
          const formValue = getValues(`settings.${settingKey}`)
          const value = formValue ? formValue : settingValue?.default
          setValue(`settings.${settingKey}`, value)
        }
      }
    }
  }, [watch('settings.authType'), watch('settings.oauthFlow')])

  useEffect(() => {}, [watch('settings.oauthUseClientAssertion'), watch('settings.responseXmlToJson')])

  return (
    <Box>
      <TextInputField<CreateDataSourceSchema>
        name="name"
        label={t('dataSources.formData.name.label')}
        inlineError={errors?.name?.message}
      />
      <IntegrationFormSettingFields settings={requestClientSettings} group={'Authorization'} isDataSource />

      {viaConnect && (
        <>
          <TextInputField<CreateDataSourceSchema>
            name="settings.route_name"
            required
            label={t('dataSources.formData.connectRouteName.label')}
          />
          <TextInputField<CreateDataSourceSchema>
            name="search_parameter_name"
            label={t('dataSources.formData.searchParameterName.label')}
            inlineError={errors?.search_parameter_name?.message}
          />
        </>
      )}

      {!viaConnect && (
        <>
          <Box
            align="center"
            direction="row"
            gap="8px"
            css={`
              > div {
                flex: 1;
              }
            `}
          >
            <TextInputField<CreateDataSourceSchema>
              css={`
                width: 75%;
              `}
              name="url"
              label={t('dataSources.formData.url.label')}
              truncate
              required
              inlineError={errors?.url?.message}
            />
            <SelectField<CreateDataSourceSchema>
              name="http_method"
              label={t('dataSources.formData.httpMethod.label')}
              options={[
                { label: 'GET', value: 'get' },
                { label: 'POST', value: 'post' }
              ]}
              icon={'streams'}
              inlineError={errors?.http_method?.message}
            />
          </Box>
          <TextInputField<CreateDataSourceSchema>
            name="search_parameter_name"
            label={t('dataSources.formData.searchParameterName.label')}
            inlineError={errors?.search_parameter_name?.message}
          />
          {/* TODO: add label prop to CodeEditor component? Bring up in PR*/}
          <Text
            color="text-light"
            css={`
              font-size: 13px;
              font-weight: 400;
            `}
          >
            {t('dataSources.formData.httpHeaders.label')}
          </Text>
          <Controller
            control={control}
            name={'http_headers'}
            render={({ field: { value, onChange } }) => {
              return (
                <CodeEditor
                  value={typeof value === 'string' ? value : JSON.stringify(value)}
                  onChange={onChange}
                  defaultLanguage="json"
                  resize="vertical"
                />
              )
            }}
          />
        </>
      )}
      <Checkbox
        checked={Boolean(getValues('settings.responseXmlToJson'))}
        {...register('settings.responseXmlToJson')}
        label={requestClientSettings.responseXmlToJson.label}
      />
    </Box>
  )
}

const MappingSettings = ({
  isLoading,
  setIsLoading,
  handleSubmitTest,
  testResults,
  testResultsError,
  jsonMappings,
  primaryKey,
  setTestResults = () => {},
  setJsonMappings = () => {},
  setPrimaryKey = () => {}
}: CreateDataSourceFormProps) => {
  const { t } = useLanguage('dataSources')
  const { resetField, control, getValues, setValue, watch, register } = useFormContext<CreateDataSourceSchema>()
  const rootNode = watch('json_root')
  const [primaryKeyOptions, setPrimaryKeyOptions] = useState<Record<string, string>[]>([])
  const [disabled, setDisabled] = useState(true)
  const [attributesStatus, setAttributesStatus] = useState<AttributeFinderStatuses>('default')
  const [data, setData] = useState<string | undefined>(undefined)

  type AttributeFinderStatuses = 'success' | 'default' | 'error'

  useEffect(() => {
    if (testResults) {
      setTestResults(testResults)
      setData(testResults?.body)
    } else if (testResultsError) {
      setData(testResultsError)
    }
  }, [setTestResults, testResults, testResultsError])

  useEffect(() => {
    if (jsonMappings) {
      setJsonMappings(jsonMappings)
    }
  }, [jsonMappings, setJsonMappings])

  useEffect(() => {
    if (jsonMappings) {
      jsonMappings?.forEach((attr: { type: string; mapping: string; primary?: boolean }) => {
        if (attr['mapping'] === primaryKey) {
          attr['primary'] = true
        } else {
          delete attr['primary']
        }
      })
      setValue('json_mappings', JSON.stringify(jsonMappings))
    }
  }, [primaryKey])

  useEffect(() => {
    if (rootNode?.length > 0) {
      setDisabled(false)
      handleOnRootNodeSelection()
    } else {
      setPrimaryKeyOptions([])
      setDisabled(true)
      setAttributesStatus('default')
    }
  }, [rootNode])

  const responseStatus = (testResults: TestResultsResponse) => {
    if (testResults?.status) {
      if (testResults.status >= 200 && testResults.status <= 299) {
        return 'success'
      } else if (testResults.status >= 400 && testResults.status <= 499) {
        return 'warning'
      } else {
        return 'error'
      }
    } else {
      return 'error'
    }
  }

  const handleOnRootNodeSelection = () => {
    setValue('json_root', rootNode)

    if (rootNode === '.') {
      if (data?.[0]) {
        const attributes = Object.keys(data[0])
        buildJsonMappings(attributes)
        buildPrimaryKeyOptions(attributes)
        setAttributesStatus('success')
      } else {
        setAttributesStatus('error')
      }
    } else {
      const nodes = rootNode.split('.')
      const sanitizedNodes = nodes.filter(node => {
        return !!node
      })
      processAttributes(data, sanitizedNodes)
    }
  }

  const processAttributes = (results: any, nodes: string[]) => {
    nodes.forEach(node => {
      const data = results?.[node]
      nodes.shift()
      if (nodes.length !== 0) {
        return processAttributes(data, nodes)
      }
      if (data?.[0] !== undefined) {
        const attributes = Object.keys(data[0])
        buildJsonMappings(attributes)
        buildPrimaryKeyOptions(attributes)
        setAttributesStatus('success')
      } else {
        setAttributesStatus('error')
        setPrimaryKeyOptions([])
      }
    })
  }

  const buildJsonMappings = (attributes: string[]) => {
    const mappings: { type: string; mapping: string; primary?: boolean }[] = []
    attributes.map(attr => {
      mappings.push({ type: 'text', mapping: `${attr}` })
    })
    setJsonMappings(mappings)
  }

  const buildPrimaryKeyOptions = (attributes: string[]) => {
    const options = attributes.map(opt => ({
      label: opt,
      value: opt
    }))
    setPrimaryKeyOptions(options)
  }

  const handleOnPrimaryKeySelection = (value: any) => {
    handleOnRootNodeSelection
    setPrimaryKey(value)
  }

  const handleOnInput = debounce((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setPrimaryKeyOptions([])
      setAttributesStatus('default')
      resetField('json_root')
      setIsLoading(true)
      const formData = getValues()
      const query = {
        query_string: event.target.value
      }
      if (handleSubmitTest) {
        handleSubmitTest(formData, query)
      }
    }
  }, 300)

  const attr_finder_statuses = {
    default: { color: 'message-info', icon: 'heartbeat' },
    success: { color: 'message-success', icon: 'check' },
    error: { color: 'message-error', icon: 'alert' }
  }

  const attr_pill_label =
    rootNode.length == 0
      ? { label: t('dataSources.formData.jsonRoot.attributesDefault') }
      : { label: t('dataSources.formData.jsonRoot.attributesFound', { attributes: primaryKeyOptions?.length }) }

  return (
    <Box>
      <TextInput
        onInput={handleOnInput}
        name={'query'}
        required
        icon={'search'}
        placeholder={t('dataSources.formData.query.placeholder')}
        label={t('dataSources.formData.query.label', { interpolation: { skipOnVariables: true } })}
      />
      {isLoading && (
        <>
          <Loader />
        </>
      )}
      {!isLoading && (testResults || testResultsError) && (
        <>
          <Box margin={{ bottom: 'small' }}>
            <Message
              type={
                responseStatus(testResults as TestResultsResponse) === 'success'
                  ? 'success'
                  : responseStatus(testResults as TestResultsResponse) === 'warning'
                  ? 'warning'
                  : 'error'
              }
              message={t(
                `dataSources.modals.createDataSource.test.result.${responseStatus(testResults as TestResultsResponse)}`,
                {
                  status: testResults?.status ?? 500
                }
              )}
            />
          </Box>
          {!isLoading && responseStatus(testResults as TestResultsResponse) === 'success' && (
            <>
              <CodeEditor
                data-testid="test-form-code-editor"
                defaultLanguage="json"
                value={JSON.stringify(data, null, 2)}
              />
              <Box direction="row" gap="small" justify="stretch">
                <TextInput
                  css={`
                    width: 100%;
                  `}
                  {...register('json_root')}
                  label={t('dataSources.formData.jsonRoot.label')}
                  tooltipText={t('dataSources.formData.jsonRoot.tooltip')}
                  required
                />
                <Pill
                  css={`
                    align-self: center;
                  `}
                  icon={attr_finder_statuses[attributesStatus]?.icon as IconName}
                  color={attr_finder_statuses[attributesStatus]?.color}
                  {...attr_pill_label}
                />
              </Box>
              <Controller
                name={'json_mappings'}
                control={control}
                render={() => (
                  <Select
                    disabled={disabled}
                    options={primaryKeyOptions}
                    label={t('dataSources.formData.jsonMapping.label')}
                    icon={'streams'}
                    required
                    onChange={value => handleOnPrimaryKeySelection(value)}
                  />
                )}
              />
            </>
          )}
        </>
      )}
    </Box>
  )
}
