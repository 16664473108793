import { ToastContainer } from '@cutover/react-ui'
import { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import ResizeObserver from 'resize-observer-polyfill'
import { AppProps as SSPAAppProps } from 'single-spa'
import { AppTypes } from 'single-spa/app-types'

import { container } from './app/Config/IOC'
import CurrentPageComponent from 'app/Routing/CurrentPage/CurrentPageComponent'
import { ContainerProvider } from 'app/Shared/Helpers/InjectionHelpers'
import { ReactAppConnector } from 'main/connectors/react-app-connector'
import { ReactAppLegacyConnector } from 'main/connectors/react-app-legacy-connector'
import { AppProviders } from 'main/context/app-providers'
import { useFeature } from 'main/services/hooks'

if (!window.ResizeObserver) window.ResizeObserver = ResizeObserver

type ReactAppProps = SSPAAppProps & {
  name: AppTypes.ReactLegacySession | AppTypes.ReactLegacyNoSession
}

type ReactAppState = { hasError: boolean; appName: string }

export class ReactAppLegacy extends Component<ReactAppProps, ReactAppState> {
  constructor(props: ReactAppProps) {
    super(props)
    this.state = { hasError: false, appName: props.name }
  }

  public componentDidCatch() {
    this.setState({ hasError: true })
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }
    return (
      <ContainerProvider container={container}>
        <AppProviders fullScreen={false} reactApp="legacy">
          <Routing name={this.props.name} />
          <ToastContainer />
        </AppProviders>
      </ContainerProvider>
    )
  }
}

const Routing = ({ name }: { name: AppTypes }) => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Connector
            mainNavWidth={name === AppTypes.ReactLegacyNoSession ? 0 : undefined}
            mainHeaderHeight={name === AppTypes.ReactLegacyNoSession ? 0 : undefined}
          />
        }
      >
        <Route path="*" element={<CurrentPageComponent />} />
      </Route>
    </Routes>
  )
}

type ConnectorProps = {
  mainHeaderHeight?: number
  mainNavWidth?: number
}

const Connector = (props: ConnectorProps) => {
  const { isEnabled } = useFeature()

  if (isEnabled('react_workspace')) {
    return <ReactAppLegacyConnector {...props} />
  } else {
    return <ReactAppConnector {...props} />
  }
}
