/* eslint-disable react-hooks/rules-of-hooks */

import {
  useActiveAccountLoadableCallback,
  useActiveAccountLoadableValue,
  useGetActiveAccount,
  useGetActiveAccountCallback,
  useGetActiveAccountCallbackSync
} from '../hooks/recoil/active-account'
import { ActiveAccountModelType } from './types'

export const ActiveAccountModel: Omit<ActiveAccountModelType, 'can'> = {
  get: useGetActiveAccount,
  getCallback: useGetActiveAccountCallback,
  getCallbackSync: useGetActiveAccountCallbackSync,
  getLoadable: useActiveAccountLoadableValue,
  getLoadableCallback: useActiveAccountLoadableCallback
}
