import { ListItem, Pill, TaskItemIcon, TaskItemIconProps } from '@cutover/react-ui'
import { stageIconName, taskTypeIcon } from 'main/components/runbook/pages/task-list/task-item/task-list-item-props'
import { useTaskListTask } from 'main/recoil/data-access'
import { StreamModel, TaskTypeModel } from 'main/data-access'

export const TaskLineItem = ({
  taskId,
  onClick,
  onClickRemove,
  active,
  role,
  disabled,
  errors = []
}: {
  errors?: string[]
  taskId: number
  onClick?: () => void
  onClickRemove?: () => void
  active?: boolean
  role?: string
  disabled?: boolean
}) => {
  const task = useTaskListTask(taskId)
  const { name, stream_id, internal_id, task_type_id } = task
  const stream = StreamModel.get(stream_id)
  const taskType = TaskTypeModel.get(task_type_id)

  const iconProps = {
    color: stream.color,
    icon: taskTypeIcon(taskType.icon, task.stage),
    inProgress: task.stage === 'in-progress',
    isOpaque: task.stage === 'complete',
    stageIcon: stageIconName({
      completionType: task.completion_type,
      stage: task.stage,
      startFixed: task.start_fixed
    })
  }

  return taskType && stream ? (
    <ListItem
      hasError={errors.length > 0}
      tabIndex={undefined}
      size="small"
      startComponent={<TaskItemIcon iconSize="xsmall" {...(iconProps as unknown as TaskItemIconProps)} />}
      endComponents={errors.map(e => (
        <Pill color="error" label={e} />
      ))}
      onClick={onClick}
      onClickRemove={onClickRemove}
      active={active}
      a11yTitle={name}
      title={`#${internal_id} ${name}`}
      role={role ?? 'listitem'}
      disabled={disabled}
    />
  ) : null
}
