import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { pick } from 'lodash'

import { ActiveAccountModelType } from 'main/data-access/models/types'
import { accountState } from 'main/recoil/runbook'
import { Account } from 'main/services/queries/types'

export const useGetActiveAccount: ActiveAccountModelType['get'] = keyOrKeys => {
  const account = useRecoilValue(accountState)
  return getAccountReturn(account, keyOrKeys)
}

export const useGetActiveAccountCallback: ActiveAccountModelType['getCallback'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const account = await snapshot.getPromise(accountState)
    return getAccountReturn(account, keyOrKeys)
  })
}

export const useGetActiveAccountCallbackSync: ActiveAccountModelType['getCallbackSync'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => () => {
    const account = snapshot.getLoadable(accountState).getValue()
    return getAccountReturn(account, keyOrKeys)
  })
}

export const useActiveAccountLoadableValue: ActiveAccountModelType['getLoadable'] = () =>
  useRecoilValueLoadable(accountState)

export const useActiveAccountLoadableCallback: ActiveAccountModelType['getLoadableCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(accountState)
  )

/* -------------------------------- Internal -------------------------------- */

const getAccountReturn = (account: Account, keyOrKeys: any) => {
  if (!keyOrKeys) return account
  if (Array.isArray(keyOrKeys)) return pick(account, keyOrKeys)
  // @ts-ignore
  return account[keyOrKeys]
}
