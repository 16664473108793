import { useCallback } from 'react'

import {
  RunbookResponse,
  RunbookVersionConvertToTemplateResponse,
  RunbookVersionCreateResponse,
  RunbookVersionImportResponse
} from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookVersionModel } from 'main/data-access'

export const useProcessRunbookVersionResponse = () => {
  const processRunbookVersionCsvImportResponse = ActiveRunbookVersionModel.onAction('runbook_version_import')
  const processRunbookVersionCreateResponse = ActiveRunbookVersionModel.onAction('create')
  const processRunbookVersionConvertToTemplateResponse = ActiveRunbookVersionModel.onAction('convert_to_template')

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          processRunbookVersionCreateResponse(response as RunbookVersionCreateResponse)
          break
        case 'runbook_version_import':
          processRunbookVersionCsvImportResponse(response as RunbookVersionImportResponse)
          break
        case 'convert_to_template':
          processRunbookVersionConvertToTemplateResponse(response as RunbookVersionConvertToTemplateResponse)
          break
        default:
          return
      }
    },
    [processRunbookVersionCsvImportResponse, processRunbookVersionCreateResponse]
  )
}
