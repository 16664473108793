import { useMatch } from 'react-router-dom'

import { Box, Pill, useTheme } from '@cutover/react-ui'
import { useSidebarNavigate, useViewPermissions } from '../hooks'
import { useSidebarNavContext } from '../nav-context'
import { NavItem, SidebarSection } from '../nav-item'
import { useFeature, useLanguage } from 'main/services/hooks'

export const SectionLinks = ({ compact }: { compact: boolean }) => {
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'defaultNav' })
  const viewPermissions = useViewPermissions()
  const showAccessManagement = viewPermissions.access
  const { leftSidebarSize, toggleLeftSidebar } = useSidebarNavContext()
  const navigate = useSidebarNavigate()
  const { isEnabled } = useFeature()
  const isHomeActive = useMatch('app/my-cutover')
  const isUsersActive = useMatch('app/access/users/list')
  const isMyTasksActive = useMatch('app/my-tasks')
  const theme = useTheme()

  const handleClickLink = (link: 'home' | 'users' | 'myTasks') => {
    const isActive =
      (link === 'home' && isHomeActive) ||
      (link === 'users' && isUsersActive) ||
      (link === 'myTasks' && isMyTasksActive)
    if (!isActive) {
      if (link === 'home') {
        navigate('/app/my-cutover')
      } else if (link === 'myTasks') {
        navigate('/app/my-tasks?run_type=live')
      } else {
        navigate('/app/access/users/list')
      }
    } else if (leftSidebarSize === 'full') {
      toggleLeftSidebar()
    }
  }

  return (
    <SidebarSection>
      <NavItem
        compact={compact}
        label={t('home')}
        isActive={!!isHomeActive}
        icon="home"
        onClick={() => handleClickLink('home')}
      />
      {isEnabled('my_tasks') && (
        <NavItem
          compact={compact}
          label={t('myWork')}
          isActive={!!isMyTasksActive}
          icon="task-list"
          onClick={() => handleClickLink('myTasks')}
          suffix={
            <Box pad="medium">
              <Pill label="New" color="feature-pink" size="small" themeType={theme.dark ? 'dark' : 'light'} />
            </Box>
          }
        />
      )}
      {showAccessManagement && (
        <NavItem
          label={t('users')}
          compact={compact}
          isActive={!!isUsersActive}
          icon="user-list"
          onClick={() => handleClickLink('users')}
        />
      )}
    </SidebarSection>
  )
}
