import { isValidElement, memo, ReactNode } from 'react'
import { ClickEvent, MenuDivider, MenuHeader as ReactMenuHeader, MenuItem as ReactMenuItem } from '@szhsin/react-menu'
import styled from 'styled-components/macro'
import { LiteralUnion } from 'type-fest'

import { Box } from '../layout'
import { Text } from '../typography'
import { Icon, IconName } from '../icon'
import { ListItem } from '../list'
import { ColorProp, themeColor } from '../theme'

// Add the more common/standard menu item customizations here e.g., groupings, headings
// WARNING: DO NOT use this to add props here to create menus items that deviate from the standard
// list item UI. We'll add a render prop to account for custom renders assuming that is what we want to supports

export type MenuListItemProps = {
  label: string
  width?: 'small' | 'medium'
  selected?: boolean
  type?: 'checkbox'
  checked?: boolean
  disabled?: boolean
  // NOTE: this is just a visual cue -- treats like a non selecting checkbox. The renderer adds the click hadler that handles the logic for whether the (flat) items includes its children
  /** visual cue to show a down arrow at the end indicating it has child items */
  expandable?: boolean
  expanded?: boolean
  destructive?: boolean
  icon?: IconName | ReactNode
  level?: number
  color?: LiteralUnion<ColorProp, string>
  // props from menu lib
  onClick?: (props: ClickEvent) => void
  // prop from menu lib. It's useful for helping identify which menu item is clicked when you listen the onItemClick event on root menu component.
  value?: any
  'data-testid'?: string
  iconColor?: string
  appendDivider?: boolean
  a11yTitle?: string
}

// TODO: refactor color prop stuff after most scenarios are implemented
export const MenuListItem = memo(
  ({
    label,
    selected,
    level,
    icon,
    color,
    expandable,
    expanded,
    destructive,
    width = 'small',
    iconColor,
    appendDivider,
    a11yTitle,
    ...props
  }: MenuListItemProps) => {
    return (
      <>
        <ReactMenuItem
          {...props}
          css={`
            cursor: ${props.disabled ? 'default' : undefined};
          `}
        >
          {renderProps => {
            const { checked, type, disabled } = renderProps
            const isCheckboxIcon = type === 'checkbox' && !expandable
            const hasStartIcon = icon || isCheckboxIcon

            // TODO list item icon should be standard colors based on list item state, should not define here
            const baseColor = (color || 'text-light') as any
            const computedIconColor = iconColor ? iconColor : disabled ? 'text-disabled' : (selected || isCheckboxIcon && checked) ? 'primary' : type === 'checkbox' ? 'text-light' : baseColor // prettier-ignore
            const startIcon = type === 'checkbox' && checked ? 'checkbox-checked' : type === 'checkbox' ? 'checkbox' : (icon as IconName) // prettier-ignore
            const expandableIconColor = selected ? 'primary' : disabled ? 'text-disabled' : baseColor

            return (
              <ListItem
                size="medium"
                width={width === 'small' ? '196px' : '320px'}
                selected={selected}
                disabled={disabled}
                hasError={destructive}
                level={level}
                title={label}
                onClick={() => {}} // Presence of onClick applies a hover effect
                a11yTitle={a11yTitle}
                startComponent={
                  isValidElement(icon) ? (
                    icon
                  ) : hasStartIcon ? (
                    <Icon color={destructive ? 'error' : computedIconColor} icon={startIcon} />
                  ) : undefined
                }
                // Note: this is currently only used on the user role select. Should migrate to a 'submenu' concept
                endComponents={
                  expandable
                    ? [
                        <Icon
                          size="medium"
                          icon={expanded ? 'caret-down' : 'caret-right'}
                          color={expandableIconColor}
                        />
                      ]
                    : undefined
                }
              />
            )
          }}
        </ReactMenuItem>
        {appendDivider && <MenuListItemDivider data-testid="menu-item-divider" />}
      </>
    )
  }
)

export const MenuListItemDivider = styled(MenuDivider)`
  padding-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${themeColor('bg-2')};
`

export const MenuListItemHeader = memo(({ label, ...props }: MenuListItemProps) => {
  return (
    <ReactMenuHeader>
      <Box data-testid={props['data-testid']} width="100%">
        {/* TODO: theming for weights vs numbers */}
        <Text color="text-light" weight={600} size="13px" css="line-height: 32px;">
          {label}
        </Text>
      </Box>
    </ReactMenuHeader>
  )
})
