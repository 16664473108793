import { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { unescape } from 'lodash'
import { useFormContext } from 'react-hook-form'

import { IconButton } from '@cutover/react-ui'
import { FormEditPanel, SelectField, TextAreaField, TextInputField, UserSelectField } from 'main/components/shared/form'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { StreamListStream } from 'main/services/queries/types'
import { getStream, StreamUpdatePayload, updateStream } from 'main/services/queries/use-stream'
import {
  StreamShowPermissions,
  useAccountSlugUrlParamState,
  useGetStreamShowPermissions
} from 'main/recoil/data-access'
import { RagStatusFields } from 'main/components/shared/runbook-edit/runbook-edit-form/rag-status-fields'
import { StreamDeleteModal, StreamUnableToDeleteModal } from '../../modals/stream/stream-delete-modal'
import { RunbookStreamUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModel, ActiveRunbookVersionModel, StreamModel, TaskTypeModel } from 'main/data-access'

export const StreamEditPanel = memo(() => {
  const [{ streamId }, { closeRightPanel }] = useRightPanelTypeState('stream-edit')

  return <>{streamId && <StreamEdit streamId={streamId} closeRightPanel={closeRightPanel} />}</>
})

const StreamEdit = ({ streamId, closeRightPanel }: { streamId: number; closeRightPanel: any }) => {
  const streamInitialData = StreamModel.get(streamId)

  return (
    <>{streamInitialData && <StreamEditForm key={streamId} data={streamInitialData} onClose={closeRightPanel} />}</>
  )
}

type StreamEditFormType = yup.InferType<typeof validationSchema>

export const StreamEditForm = memo(({ data: listStream, onClose }: { data: StreamListStream; onClose: () => void }) => {
  const { t } = useTranslation('runbook', { keyPrefix: 'streamEditPanel' })
  const handleUpdatedStream = StreamModel.onAction('update')
  const getStreamShowPermissions = useGetStreamShowPermissions()
  const [can, setPermissions] = useState<StreamShowPermissions | null>(null)
  const [unableToDeleteModalOpen, setUnableToDeleteModalOpen] = useState(false)
  const [tasksCount, setTasksCount] = useState<number | null>(null)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const streamId = listStream.id

  const runbookId = ActiveRunbookModel.get('id')
  const runbookVersionId = ActiveRunbookVersionModel.get('id')

  const getDefaultValues = useCallback(async () => {
    const resp = await getStream({ runbookId, runbookVersionId, streamId })

    // Note: tasks_count is not part of the listStream serializer, therefore have to get it here
    setTasksCount(resp.stream.tasks_count)

    setPermissions(getStreamShowPermissions(resp))

    return resp.stream ?? {}
  }, [runbookId, runbookVersionId, streamId, getStreamShowPermissions])

  // Note/Todo:
  // Angular currently opens a 'reassign' modal when stream editors are removed that have tasks but no other runbook roles
  // We also send 'users_to_remove_from_runbook' which doesn't make sense as the API should be doing this automatically
  // Reassigning tasks of users that are removed is something that should be done the same way via streams or people panel
  const handleSubmit = async (data: StreamUpdatePayload) =>
    updateStream({ streamId, runbookVersionId, runbookId, ...data })

  const handleClickDelete = () => {
    const hasChildren = listStream?.children?.length > 0
    if (hasChildren) {
      setUnableToDeleteModalOpen(true)
    } else {
      // Note: tasks_count is not part of the listStream serializer, therefore have to get it here
      setDeleteModalOpen(true)
    }
  }

  const onCloseDeleteModal = () => {
    setUnableToDeleteModalOpen(false)
    setDeleteModalOpen(false)
  }

  return (
    <>
      {deleteModalOpen && (
        <StreamDeleteModal
          streamName={listStream.name}
          streamId={listStream.id}
          tasksCount={tasksCount ?? 0}
          onClose={onCloseDeleteModal}
        />
      )}
      {unableToDeleteModalOpen && <StreamUnableToDeleteModal onClose={onCloseDeleteModal} />}
      <FormEditPanel<StreamEditFormType, StreamUpdatePayload>
        onClose={onClose}
        onSubmit={handleSubmit}
        onSuccess={(response: RunbookStreamUpdateResponse) => handleUpdatedStream(response)}
        transformer={dataTransformer}
        defaultValues={getDefaultValues}
        successMessage={t('successMessage')}
        readOnly={!can?.update}
        disabled={!can?.update}
        headerItems={
          can?.destroy
            ? [<IconButton label={t('delete')} tipPlacement="top" icon="delete" onClick={handleClickDelete} />]
            : undefined
        }
        title={listStream.parent_id ? t('titleSubstream') : t('title')}
        schema={validationSchema}
      >
        <StreamEditFormFields permissions={can} isChild={!!listStream.parent_id} />
      </FormEditPanel>
    </>
  )
})

const StreamEditFormFields = ({ permissions: can, isChild }: { permissions: any; isChild: boolean }) => {
  const { t } = useTranslation('runbook', { keyPrefix: 'streamEditPanel' })
  const { watch } = useFormContext<StreamEditFormType>()
  const roleTypes = watch('role_types')
  const accountSlug = useAccountSlugUrlParamState()
  const taskTypes = TaskTypeModel.getAll(['id', 'name'])

  const taskTypeOptions = useMemo(() => {
    return taskTypes ? taskTypes.map(({ id, name }) => ({ label: unescape(name), value: id })) : []
  }, [taskTypes])

  return (
    <>
      <TextAreaField<StreamEditFormType> name="name" label={t('fields.name')} autoFocus />
      <TextAreaField<StreamEditFormType> name="description" label={t('fields.description')} />
      {!isChild &&
        roleTypes &&
        roleTypes.map((roleType, index) => {
          return (
            <UserSelectField<StreamEditFormType>
              data-testid={`role-type-${roleType.id}`}
              key={`role_types.${roleType.id}`}
              name={`role_types.${index}.users`}
              accountId={accountSlug}
              // @ts-ignore
              defaultValue={roleType.users}
              label={roleType.name}
              helpText={roleType.description || undefined}
              // TODO: fix without hard coding required state https://cutover.atlassian.net/browse/CFE-1578
              required={false}
            />
          )
        })}
      <RagStatusFields<StreamEditFormType>
        name="status"
        statusMessageFieldName="status_message"
        disabled={!can?.update}
        readOnly={!can?.update}
      />
      <SelectField<StreamEditFormType>
        name="default_task_type_id"
        options={taskTypeOptions}
        label={t('fields.defaultTaskType')}
        data-testid="default-task-type-select"
      />
      <TextInputField<StreamEditFormType> name="tasks_count" label={t('fields.tasksCount')} readOnly />
    </>
  )
}

const dataTransformer = (data: StreamEditFormType): StreamUpdatePayload => {
  return {
    stream: {
      name: data.name.trim(),
      description: data.description,
      default_task_type_id: data.default_task_type_id,
      //color: data.color
      status: data.status,
      status_message: data.status_message,
      roles:
        data.role_types
          ?.map(role =>
            (role.users || []).map(user => ({
              id: user.role_id,
              resource_id: data.id,
              resource_type: 'Stream',
              role_type_id: role.id,
              subject_id: user.id,
              subject_type: 'User'
            }))
          )
          .flat() ?? []
    },
    users_to_remove_from_runbook: [] // See comment above handleSubmit
  }
}

const validationSchema = yup.object({
  id: yup.number().required(),
  description: yup.string().nullable(),
  name: yup.string().required(),
  //color: yup.string().notRequired(),
  tasks_count: yup.number().notRequired(),
  default_task_type_id: yup.number().nullable(),
  status: yup.string().oneOf(['off', 'red', 'amber', 'green']).required(),
  status_message: yup
    .string()
    .nullable()
    .when('status', {
      is: (val: string) => val !== 'off',
      then: () => yup.string().required()
    }),
  role_types: yup.array().of(
    yup.object({
      id: yup.number().required(),
      description: yup.string().nullable(),
      name: yup.string(),
      users: yup.array().of(
        yup.object({
          role_id: yup.number(),
          id: yup.number().required()
        })
      )
    })
  )
})
