import { useRef } from 'react'
import * as yup from 'yup'

import { Message } from '@cutover/react-ui'
import { DateTimePickerField, FormModal } from 'main/components/shared/form'
import { RunbookUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import { useLanguage } from 'main/services/hooks'
import { RunbookUpdatePayload, updateRunbook } from 'main/services/queries/use-runbook'
import { ActiveRunbookModel } from 'main/data-access'

type ScheduledStartRunbookModalProps = {
  onClose: () => void
  startScheduled: number | null
}

export const ScheduledStartRunbookModal = ({ onClose, startScheduled }: ScheduledStartRunbookModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'scheduledStartRubookModal' })
  const runbook = ActiveRunbookModel.get()
  const { id: runbookId, timezone, linked_runbook_details: linkedRunbookDetails } = runbook
  const processRunbookUpdateResponse = ActiveRunbookModel.onAction('update')

  const isLinkedRunbookChild = linkedRunbookDetails && !!linkedRunbookDetails.id
  // startScheduled should not reevaluate
  const hasStartScheduled = useRef<boolean>(!!startScheduled)
  const handleSuccess = (response?: RunbookUpdateResponse) => {
    if (response?.runbook) {
      onClose()
      processRunbookUpdateResponse(response)
    }
  }

  const transformer = ({ start_scheduled }: { start_scheduled?: Date | null }): RunbookUpdatePayload => {
    return {
      runbook: {
        runbook_versions_attributes: [
          {
            start_scheduled: start_scheduled ?? null
          }
        ]
      },
      timezone: timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }

  const handleSubmit = async (payload: RunbookUpdatePayload) => {
    return await updateRunbook(runbookId, payload)
  }

  return (
    <FormModal<{ start_scheduled?: Date | null }, RunbookUpdatePayload>
      onClose={onClose}
      defaultValues={{
        start_scheduled:
          hasStartScheduled.current && isLinkedRunbookChild ? new Date((startScheduled as number) * 1000) : null
      }}
      open
      title={hasStartScheduled.current && !isLinkedRunbookChild ? t('title.remove') : t('title.add')}
      schema={yup.object({ start_scheduled: hasStartScheduled.current ? yup.date().nullable() : yup.date() })}
      confirmText={t('common:saveButton')}
      onSubmit={handleSubmit}
      transformer={transformer}
      onSuccess={handleSuccess}
      hideFooter={isLinkedRunbookChild}
    >
      {isLinkedRunbookChild && !hasStartScheduled.current ? (
        <Message role="alert" message={t('unscheduledChildWarning')} type="warning" />
      ) : !isLinkedRunbookChild && hasStartScheduled.current ? (
        t('removeScheduledStartText')
      ) : (
        <DateTimePickerField
          name="start_scheduled"
          label={t('label.scheduledStart')}
          readOnly={isLinkedRunbookChild}
          fixed
          fixedWidth
        />
      )}
    </FormModal>
  )
}
