/* eslint-disable react-hooks/rules-of-hooks */
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { CurrentRunbookVersionModelType } from 'main/data-access/models/types'
import { runbookCurrentVersionState } from 'main/recoil/runbook'

export const getCurrentRunbookVersion: CurrentRunbookVersionModelType['get'] = () =>
  useRecoilValue(runbookCurrentVersionState)
export const getCurrentRunbookVersionCallback: CurrentRunbookVersionModelType['getCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookCurrentVersionState)
  )
export const getCurrentRunbookVersionCallbackSync: CurrentRunbookVersionModelType['getCallbackSync'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(runbookCurrentVersionState).getValue()
  )
