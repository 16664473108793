import {
  getCurrentRunbookVersion,
  getCurrentRunbookVersionCallback,
  getCurrentRunbookVersionCallbackSync
} from '../hooks/recoil/current-runbook-version'
import { CurrentRunbookVersionModelType } from './types'

export const CurrentRunbookVersionModel: CurrentRunbookVersionModelType = {
  get: getCurrentRunbookVersion,
  getCallback: getCurrentRunbookVersionCallback,
  getCallbackSync: getCurrentRunbookVersionCallbackSync
}
