import { cloneElement, ReactElement } from 'react'

import { IconButton, Menu, MenuListItem } from '@cutover/react-ui'
import { useIsRunbookIncident, useRunbookRunbookType } from 'main/recoil/data-access'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CurrentRunbookVersionModel
} from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { ModalActiveType } from 'main/recoil/runbook'

export const RunbookSettingsMenu = ({
  onClickOption
}: {
  onClickOption: (option: ModalActiveType['type']) => void
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'runbookSettingsMenu' })

  /* ------------------------------- Permissions ------------------------------ */
  const canUpdate = ActiveRunbookModel.can('update')
  const canDuplicate = ActiveRunbookModel.can('duplicate')
  const canImport = ActiveRunbookVersionModel.can('import')
  // const canCreate = useRunbookVersionPermission({ attribute: 'create' })
  // const canDelete = useRunbookVersionPermission({ attribute: 'destroy' })

  /* ------------------------------- Properties ------------------------------- */
  const { template_type: templateType, linked_runbook_details: linkedRunbookDetails } = ActiveRunbookModel.get()

  const run = ActiveRunModel.get()
  const { is_current: isCurrent } = ActiveRunbookVersionModel.get()
  const isRunbookIncident = useIsRunbookIncident()
  const { start_scheduled: startScheduled } = CurrentRunbookVersionModel.get()
  const isRunbookTypeDisabled = useRunbookRunbookType().disabled
  // const { template_version } = ActiveRunbookVersionModel.get()
  // const hasTemplateVersions = template_version !== 1

  /* --------------------------- Helper properties -------------------------- */
  const runType = run?.run_type
  const isTemplate = templateType === 'default'
  const isSnippet = templateType === 'snippet'
  const isRunbook = templateType === 'off'
  const isRehearsal = runType === 'rehearsal'
  const isLiveRun = runType === 'live'
  const resourceType = isSnippet ? 'Snippet' : 'Runbook'
  // const isPaused = runbookStage === 'paused'
  const isPlanning = !isRehearsal && !isLiveRun
  const isLinkedRunbookChild = linkedRunbookDetails && !!linkedRunbookDetails.id

  /* ----------------------- Show/hide option conditions ---------------------- */
  const showMakeTemplateOption = ActiveRunbookVersionModel.canAction('convert_to_template')
  const showDuplicateOption =
    !isRunbookTypeDisabled && canDuplicate && isCurrent && (isRunbookIncident ? isTemplate : true)
  const showAddScheduledStart = canUpdate && isPlanning && (!startScheduled || isLinkedRunbookChild) && isRunbook
  const showRemoveScheduledStart = canUpdate && isPlanning && startScheduled && !isLinkedRunbookChild

  /* --------- Options (array of arrays to represent grouped options) --------- */
  const groupedOptions = [
    [
      showMakeTemplateOption && (
        <MenuListItem
          a11yTitle={t('options.makeTemplate')}
          label={t('options.makeTemplate')}
          icon="template"
          onClick={() => onClickOption('runbook-make-template')}
          data-testid="menu-item-settings-make-template"
        />
      ),
      showDuplicateOption && (
        <MenuListItem
          label={
            isTemplate
              ? t('options.createRunbookFromTemplate')
              : t('options.duplicate', { resourceType: resourceType.toLowerCase() })
          }
          a11yTitle={
            isTemplate
              ? t('options.createRunbookFromTemplate')
              : t('options.duplicate', { resourceType: resourceType.toLowerCase() })
          }
          icon="copy"
          onClick={() => onClickOption('runbook-duplicate')}
          data-testid="menu-item-settings-duplicate"
        />
      )
    ].filter(Boolean),
    [
      canImport && (
        <MenuListItem
          label={t('options.importTasks')}
          icon="upload"
          onClick={() => onClickOption('tasks-csv-import')}
          data-testid="menu-item-settings-import"
        />
      )
      // !isSnippet && (
      //   <MenuListItem
      //     label={t('options.importRunbook')}
      //     icon="add-to-list"
      //     onClick={() => console.log('Importing a runbook')}
      //   />
      // )
    ].filter(Boolean),
    [
      showAddScheduledStart && (
        <MenuListItem
          label={isLinkedRunbookChild ? t('options.scheduledStart') : t('options.addScheduledStart')}
          icon="time"
          onClick={() => onClickOption('runbook-schedule')}
          data-testid="menu-item-settings-scheduled-start"
        />
      ),
      showRemoveScheduledStart && (
        <MenuListItem
          label={t('options.removeScheduledStart')}
          icon="time"
          onClick={() => onClickOption('runbook-schedule')}
          data-testid="menu-item-settings-remove-scheduled-start"
        />
      )
    ].filter(Boolean)
  ]

  return (
    <>
      <Menu
        align="start"
        menuStyle={{ width: '320px' }}
        trigger={
          <IconButton
            icon="settings"
            tertiary
            label={t('iconLabel')}
            tipPlacement="bottom"
            size="medium"
            disableTooltip
            onClick={e => e.stopPropagation()}
          />
        }
      >
        {groupedOptions.map((group, groupIndex) =>
          group.map((element, elementIndex) =>
            // casting because linting doesn't respond to filter(Boolean)
            cloneElement(element as ReactElement, {
              key: `${groupIndex}-${elementIndex}`,
              // Append divider if the option is the last in its group but not in the last group
              appendDivider:
                !!groupedOptions[groupIndex + 1] &&
                groupedOptions[groupIndex + 1].length !== 0 &&
                !group[elementIndex + 1]
            })
          )
        )}
      </Menu>
    </>
  )
}

/* -------------------------------------------------------------------------- */
/*          OLD CODE, PLEASE DELETE WHEN OPTION HAS BEEN IMPLEMENTED          */
/* -------------------------------------------------------------------------- */

// {/* {canArchive && !isPaused && (
//       <MenuListItem label={t('options.archive')} icon="archive" onClick={() => console.log('Duplicate!')} />
//     )} */}
//   {/* For portable runbooks */}
//   {/* {isTemplate && (
//       <MenuListItem
//         label={t('options.downloadTemplate')}
//         icon="download"
//         onClick={() => console.log('With portable runbooks enabled!')}
//       />
//     )} */}

//   {/* TODO see https://cutover.atlassian.net/browse/CFE-1372 */}
//   {/* <VersionOptions
//       isTemplate={isTemplate}
//       hasTemplateVersions={hasTemplateVersions}
//       isCurrent={isCurrent}
//       resourceType={resourceType}
//       canCreate={canCreate}
//       canDelete={canDelete}
//     /> */}
//   {/* <MenuListItem label={t('options.auditLog')} icon="page-text" onClick={() => console.log('Audit log!!')} /> */}
//   {/* <MenuListItem label={t('options.reload')} icon="refresh" onClick={() => console.log('reload!')} /> */}

// TODO see https://cutover.atlassian.net/browse/CFE-1372
// const VersionOptions = ({ isTemplate, hasTemplateVersions, isCurrent, canCreate, canDelete }: VersionOptionsProps) => {
//   const { t } = useLanguage('runbook', { keyPrefix: 'runbookSettingsMenu' })
//   return (
//     <>
//       {canCreate &&
//         (isTemplate ? (
//           <MenuListItem
//             label={t('options.newTemplateVersion')}
//             icon="save"
//             onClick={() => console.log('New template!')}
//           />
//         ) : isCurrent ? (
//           <MenuListItem label={t('options.saveVersion')} icon="save" onClick={() => console.log('Saving a version')} />
//         ) : (
//           <MenuListItem
//             label={t('options.restoreVersion')}
//             icon="save"
//             onClick={() => console.log('Restoring this version')}
//           />
//         ))}

//       {canDelete && isTemplate && hasTemplateVersions && (
//         <MenuListItem
//           label={t('options.deleteDraftVersion')}
//           icon="delete"
//           onClick={() => console.log('Deleting a draft')}
//         />
//       )}
//       {/* <MenuListItem
//         label={t('options.saveAsSavedView', { resourceType: resourceType.toLowerCase() })}
//         icon="save"
//         onClick={() => console.log('Saved as a saved view')}
//       /> */}
//       <MenuListItem label={t('options.viewVersion')} icon="history" onClick={() => console.log('view version!!')} />
//     </>
//   )
// }
