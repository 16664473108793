import { memo, useCallback } from 'react'

import { DateTimePicker } from '@cutover/react-ui'
import { AnyOrNoneControls, ValueType } from './any-or-none-fields'
import { useCustomField, useCustomFieldFilterState } from 'main/recoil/data-access'

export const CustomFieldDateSelectControl = memo(({ id }: { id: number }) => {
  const customField = useCustomField(id)
  const [urlVal, setUrlVal] = useCustomFieldFilterState(id)
  const urlAsArr = (urlVal === '*' ? ['*'] : urlVal ?? []) as any[]
  const internalVals = transformUrlArray(urlAsArr)
  const [dateFrom, dateTo] = internalVals

  const handleChangeFrom = useCallback((date: Date | null) => {
    setUrlVal((prev = []) => {
      // @ts-ignore
      if (prev === '*') {
        prev = []
      }
      // @ts-ignore
      const [_df, dt, allOrNone] = prev
      const nextVal = [date ? date.toISOString() : null, dt, ...(allOrNone === 0 ? [0] : [])]
      if (nextVal.every(isEmpty)) {
        return undefined
      } else {
        return nextVal
      }
    })
  }, [])

  const handleChangeTo = useCallback((date: Date | null) => {
    setUrlVal((prev = []) => {
      // @ts-ignore
      if (prev === '*') {
        prev = []
      }
      const [df, _dt, allOrNone] = prev
      const nextVal = [df, date ? date.toISOString() : null, ...(allOrNone === 0 ? [0] : [])]
      if (nextVal.every(isEmpty)) {
        return undefined
      } else {
        return nextVal
      }
    })
  }, [])

  const handleChangeAllOrNone = useCallback((val: ValueType = []) => {
    if (val.includes('*')) {
      setUrlVal('*')
      return
    }

    if (val === undefined || !val.length || val.every(isEmpty)) {
      setUrlVal(undefined)
      return
    }

    const [df, dt, none] = val

    // @ts-ignore
    setUrlVal(none === 0 ? [df, dt, none] : [df, dt])
  }, [])

  return (
    <>
      <DateTimePicker
        a11yTitle={`${customField.display_name || customField.name} from`} // TODO: i18n
        value={dateFrom as any}
        onChange={handleChangeFrom}
        label="From"
      />
      <DateTimePicker
        a11yTitle={`${customField.display_name || customField.name} to`} // TODO: i18n
        value={dateTo as any}
        onChange={handleChangeTo}
        label="To"
      />
      <AnyOrNoneControls value={transformUrlDateStringArray(urlAsArr)} setValue={handleChangeAllOrNone} />
    </>
  )
})

const transformUrlArray = (val: any[] = []) => {
  if (val[0] === '*') {
    return [null, null, '*']
  } else {
    const [df, dt, none] = val
    return [df ? new Date(df) : null, dt ? new Date(dt) : null, ...(none === 0 ? [0] : [null])]
  }
}

const transformUrlDateStringArray = (val: any[] = []) => {
  if (val[0] === '*') {
    return [null, null, '*']
  } else {
    const [df, dt, none] = val
    return [df ?? null, dt ?? null, ...(none === 0 ? [0] : [])]
  }
}

const isEmpty = (val: any) => {
  return val === undefined || val === null
}
