import { useState } from 'react'

import { Box, Icon, LoadingPanel, Text } from '@cutover/react-ui'
import { useRunbookGenerateSuggestions } from 'main/services/queries/use-runbook-ai-suggestions'
import { useLanguage } from 'main/services/hooks'

type AiSuggestionSubItemProps = {
  prompt: string
  runbookId: number
}

export const AiSuggestionItem = ({ prompt, runbookId }: AiSuggestionSubItemProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'aiSuggestionsPanel' })
  const [startGenerating, setStartGenerating] = useState(true)
  const [suggestions, setSuggestions] = useState([])

  const { isFetching } = useRunbookGenerateSuggestions(runbookId, prompt, {
    enabled: startGenerating,
    onSuccess: (response: any) => {
      setStartGenerating(false)
      setSuggestions(response.suggestions)
    },
    onError: () => {
      setStartGenerating(false)
    }
  })

  return (
    <>
      <Box css="word-wrap: break-word; white-space: normal; padding-bottom: 20px;">
        {`${t('subPanelText')}\n'${prompt}'`}
      </Box>
      {isFetching ? (
        <LoadingPanel />
      ) : (
        suggestions &&
        Object.entries(suggestions)?.map(([title, suggestion]) => (
          <Box direction="row" pad="xxsmall" key={`${title}-${suggestion}`}>
            <Box flex={false} css="padding-top: 4px;">
              <Icon icon="lightbulb" />
            </Box>

            <Box direction="column" pad="xxsmall">
              <Text weight="bold" css="white-space: normal; overflow-wrap: break-word;">
                {title}
              </Text>
              <Text color="text-light" css="white-space: normal; overflow-wrap: break-word;">
                {suggestion}
              </Text>
            </Box>
          </Box>
        ))
      )}
    </>
  )
}
