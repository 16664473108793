import { useRecoilCallback, useRecoilValue } from 'recoil'
import { pick } from 'lodash'

import {
  accountGlobalTaskTypeDefaultState,
  accountTaskTypeLookup,
  accountTaskTypes,
  getAccountTaskType
} from 'main/recoil/runbook'
import { TaskTypeModelType } from 'main/data-access/models/types'
import { TaskType } from 'main/services/queries/types'

export const useTaskType: TaskTypeModelType['get'] = (identifier, keyOrKeys) => {
  const taskType = useTaskTypeOrDefault(identifier)
  return getTaskTypeReturn(taskType, keyOrKeys)
}

export const useTaskTypeCallback: TaskTypeModelType['getCallback'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => async (identifier: number) => {
    const taskType = await snapshot.getPromise(getAccountTaskType(identifier))
    return getTaskTypeReturn(taskType, keyOrKeys)
  })
}

export const useTaskTypeCallbackSync: TaskTypeModelType['getCallbackSync'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => (identifier: number) => {
    const taskType = snapshot.getLoadable(getAccountTaskType(identifier)).getValue()
    return getTaskTypeReturn(taskType, keyOrKeys)
  })
}

export const useTaskTypes: TaskTypeModelType['getAll'] = keyOrKeys => {
  const taskTypes = useAccountTaskTypes()
  return getTaskTypesReturn(taskTypes, keyOrKeys)
}

export const useTaskTypesCallback: TaskTypeModelType['getAllCallback'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const taskTypes = await snapshot.getPromise(accountTaskTypes)
    return getTaskTypesReturn(taskTypes, keyOrKeys)
  })
}

export const useTaskTypesCallbackSync: TaskTypeModelType['getAllCallbackSync'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => () => {
    const taskTypes = snapshot.getLoadable(accountTaskTypes).getValue()
    return getTaskTypesReturn(taskTypes, keyOrKeys)
  })
}

export const useTaskTypeLookup: TaskTypeModelType['getLookup'] = () => {
  return useRecoilValue(accountTaskTypeLookup)
}

export const useTaskTypeLookupCallback: TaskTypeModelType['getLookupCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const taskTypeLookup = await snapshot.getPromise(accountTaskTypeLookup)
    return taskTypeLookup
  })
}

export const useTaskTypeLookupCallbackSync: TaskTypeModelType['getLookupCallbackSync'] = () => {
  return useRecoilCallback(({ snapshot }) => () => {
    const taskTypeLookup = snapshot.getLoadable(accountTaskTypeLookup).getValue()
    return taskTypeLookup
  })
}

/* -------------------------------- Internal -------------------------------- */

const useTaskTypeOrDefault = (id?: number | undefined) => {
  const taskType = useRecoilValue(getAccountTaskType(id as number))
  const defaultTaskType = useRecoilValue(accountGlobalTaskTypeDefaultState)

  return taskType ?? defaultTaskType
}

const getTaskTypeReturn = (taskType: TaskType, keyOrKeys: any) => {
  if (!keyOrKeys) return taskType
  if (Array.isArray(keyOrKeys)) return pick(taskType, keyOrKeys)
  // @ts-ignore
  return taskType[keyOrKeys]
}

const useAccountTaskTypes = () => {
  return useRecoilValue(accountTaskTypes)
}

const getTaskTypesReturn = (taskTypes: TaskType[], keyOrKeys: any) => {
  if (!keyOrKeys) return taskTypes

  return taskTypes.map(taskType => {
    if (Array.isArray(keyOrKeys)) return pick(taskType, keyOrKeys)
    // @ts-ignore
    return taskType[keyOrKeys]
  })
}
