import { ReactNode, useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useUnmount } from 'react-use'
import { useQueryClient } from 'react-query'

import { useWebsockets } from 'main/services/hooks'
import { useCurrentUserId } from 'main/recoil/data-access'
import { getInfiniteRunbookCacheKey } from 'main/services/queries/use-runbooks-infinite'
import { RunbookListRunbook } from 'main/services/queries/types'

export const AccountChannelSubscriber = ({ children }: { children?: ReactNode }) => {
  const { subscribe, findExistingSubscription } = useWebsockets()
  const { accountId: accountSlug, runbookId } = useParams<{ accountId?: string; runbookId?: string }>()
  const currentUserId = useCurrentUserId()
  const processAccountResponse = useHandleAccountResponse()

  const websocketRef = useRef<ActionCable.Channel | undefined>()

  useEffect(() => {
    websocketRef.current?.unsubscribe?.()

    if (!accountSlug || runbookId) return

    const existingRunbookChannel = findExistingSubscription('AccountChannel', accountSlug)

    if (existingRunbookChannel) return

    websocketRef.current = subscribe('AccountChannel', accountSlug, {
      received: response => {
        const [sameTab, sameUser] = [
          response.meta?.headers?.request_hash === window.sessionStorage.getItem('browserHash'),
          response.meta?.headers?.request_user_id === currentUserId
        ]

        if (sameTab && sameUser) return

        processAccountResponse(response)
      }
    })
  }, [accountSlug, runbookId])

  useUnmount(() => {
    websocketRef.current?.unsubscribe?.()
  })

  return <>{children}</>
}

const useHandleAccountResponse = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (response: {
      meta: {
        headers: { request_class: string; request_method: string; request_user_id: number }
        allowed_users: number[]
      }
      runbook: RunbookListRunbook
    }) => {
      if (response.meta.headers.request_class === 'Runbook') {
        queryClient.invalidateQueries(getInfiniteRunbookCacheKey())
      }
    },
    []
  )
}
