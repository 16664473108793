import { ReactNode, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { eventManager } from 'event-manager'

import { operations, useComponentPropsStateHandler } from './apps-component-props-helper'
import { AppFieldValues, AppsChannelResponse } from 'main/components/apps/apps-types'
import {
  useAppsModalState,
  useCurrentUser,
  useSetAppHeaderView,
  useSetAppsComponentProps,
  useSetAppsFieldValues,
  useSetAppsModalLoadingState,
  useSetAppViews
} from 'main/recoil/data-access'
import { useWebsockets } from 'main/services/hooks'

const APPS_USER_CHANNEL_NAME = 'AppUserChannel'

export const AppsUserChannelSubscriber = ({ children }: { children: ReactNode }) => {
  const componentPropsStateHandler = useComponentPropsStateHandler()
  const setAppViews = useSetAppViews()
  const [appModalState, setAppModal] = useAppsModalState()
  const setModalLoading = useSetAppsModalLoadingState()
  const [isTemplate, setIsTemplate] = useState<boolean | undefined>(false)
  const setComponentProps = useSetAppsComponentProps()
  const setHeaderApp = useSetAppHeaderView()
  const websockets = useWebsockets()
  const user = useCurrentUser()

  const [searchParams, _setSeachParams] = useSearchParams()
  const runbookIdInParams = useParams().runbookId
  const runbookId =
    window.location.href.indexOf('/app/integration_action_item') != -1
      ? searchParams.get('runbook_id')
      : runbookIdInParams

  const subscriptionId = user.id
  const setAppFieldValues = useSetAppsFieldValues()
  const appsUserChannelSubscription = websockets.findExistingSubscription(APPS_USER_CHANNEL_NAME, subscriptionId)

  useEffect(() => {
    const handleRunbookData = ({
      runbook
    }: {
      permissions: { [x: string]: number[] }
      runbook: { is_template: boolean }
    }) => {
      setIsTemplate(runbook.is_template)
    }
    eventManager.on('runbook-data', handleRunbookData)

    return () => {
      eventManager.off('runbook-data', handleRunbookData)
      appsUserChannelSubscription?.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (!runbookId || runbookId !== appModalState.resourceId) {
      setAppModal({ ...appModalState, open: false })
    }

    if (appsUserChannelSubscription && isTemplate) {
      appsUserChannelSubscription?.unsubscribe()
    }

    if (!appsUserChannelSubscription && !isTemplate) {
      websockets.subscribe(APPS_USER_CHANNEL_NAME, subscriptionId, {
        received: data => {
          updateAppViewsState(data.response)
        }
      })
    }
  }, [runbookId, appModalState.resourceId, isTemplate, subscriptionId])

  const updateAppViewsState = (response: AppsChannelResponse) => {
    const appId = response.app_id
    const resourceId = response.resource_id
    const view = response.view
    const state = response.state
    const type = view.type
    const context = `${resourceId}-${appId}`

    if (response.session_id && response.session_id !== window.sessionStorage.getItem('browserHash')) {
      return null
    }

    componentPropsStateHandler({ response, context, setComponentProps })

    if (view.close_modal) {
      setAppModal({ view: undefined, appId, resourceId, open: false })
    }

    if (!operations.includes(type)) {
      if (state) {
        setAppFieldValues(prevValues => {
          const updatedState = { ...prevValues, [context]: state }
          return updatedState as AppFieldValues
        })
      }

      switch (type) {
        // TODO: if you send a modal to a page directly it has a white background.
        case 'modal':
          if (!view.close_modal) {
            setModalLoading(false)
            setAppModal({ view, appId, resourceId, open: true })
          }
          break
        case 'page':
          response.view.content?.forEach(content => {
            if (content.type === 'modal') {
              setModalLoading(false)
              setAppModal({
                view: { ...content, appId, resourceId, id: '' },
                appId,
                resourceId,
                open: true
              })
            }
          })

          setAppViews(views => {
            const updatedView = {
              ...response.view,
              appId,
              resourceId
            }
            return { ...views, [context]: updatedView }
          })
          break

        // TODO: following are identical in apps-resource-channel-subscriber.tsx
        case 'header':
          setHeaderApp({ view, appId, resourceId })
          break
        case 'panel':
          setAppViews(views => {
            const existingView = views[context]
            const updatedView = {
              ...response.view,
              appId,
              resourceId,
              visible: existingView?.visible,
              order: existingView?.order
            }
            return { ...views, [context]: updatedView }
          })
          break
        default:
          console.warn(`User resource channel does not accept ${type} type nodes.`)
      }
    }
  }

  return <>{children}</>
}
