import { useRecoilCallback, useRecoilValue } from 'recoil'

import { CustomFieldUserModelType } from 'main/data-access/models/types'
import { accountCustomFieldUsers, customFieldUserLookup } from 'main/recoil/runbook'

// @ts-ignore
export const useGetCustomFieldUser: CustomFieldUserModelType['get'] = (identifier: number) => {
  const lookup = useRecoilValue(customFieldUserLookup)
  return lookup?.[identifier]
}

// @ts-ignore
export const useGetCustomFieldUserCallback: CustomFieldUserModelType['getCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async (identifier: number) => {
    const lookup = await snapshot.getPromise(customFieldUserLookup)
    return lookup?.[identifier]
  })
}

// @ts-ignore
export const useGetCustomFieldUserCallbackSync: CustomFieldUserModelType['getCallbackSync'] = () => {
  return useRecoilCallback(({ snapshot }) => (identifier: number) => {
    const lookup = snapshot.getLoadable(customFieldUserLookup).getValue()
    return lookup?.[identifier]
  })
}

export const useGetCustomFieldUsers: CustomFieldUserModelType['getAll'] = () => useRecoilValue(accountCustomFieldUsers)

export const useGetCustomFieldUsersCallback: CustomFieldUserModelType['getAllCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldUsers)
  )

export const useGetCustomFieldUsersCallbackSync: CustomFieldUserModelType['getAllCallbackSync'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(accountCustomFieldUsers).getValue()
  )

export const useGetCustomFieldUserLookup: CustomFieldUserModelType['getLookup'] = () =>
  useRecoilValue(customFieldUserLookup) ?? []

export const useGetCustomFieldUserLookupCallback: CustomFieldUserModelType['getLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldUsers)
  )

export const useGetCustomFieldUserLookupCallbackSync: CustomFieldUserModelType['getLookupCallbackSync'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(accountCustomFieldUsers).getValue()
  )
