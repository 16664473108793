import { ReactNode, SyntheticEvent } from 'react'
import { Box } from 'grommet'
import styled from 'styled-components/macro'

import { resolveColor, themeColor } from '../theme'

type TaskItemLayoutProps = {
  'data-testid': string
  onClick?: (e: SyntheticEvent) => void
  onFocus?: (e: SyntheticEvent) => void
  onDragOver?: (e: React.DragEvent) => void
  onDragEnter?: (e: React.DragEvent) => void
  onDragLeave?: (e: React.DragEvent) => void
  onDrop?: (e: React.DragEvent) => void
  draggable?: boolean
  highlight?: boolean
  loading?: boolean
  disabled?: boolean
  editable?: boolean
  isDraggedOver?: boolean
  showBorderTop?: boolean
  hideStageIcon?: boolean
  prefixContent?: ReactNode
  dateContent?: ReactNode
  icon: ReactNode
  mainContentPrefix?: ReactNode
  mainContent: ReactNode
  mainContentSubLine?: ReactNode
  integrationStatus?: ReactNode
  hasIntegrationError?: boolean
  endContent?: ReactNode
  suffixContent?: ReactNode
}

export const TaskItemLayout = ({
  'data-testid': dataTestId,
  onClick,
  onFocus,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
  draggable,
  editable,
  highlight,
  isDraggedOver,
  loading,
  disabled,
  showBorderTop,
  prefixContent,
  dateContent,
  icon,
  mainContentPrefix,
  mainContent,
  mainContentSubLine,
  integrationStatus,
  hasIntegrationError,
  endContent,
  suffixContent
}: TaskItemLayoutProps) => (
  <TaskItemContainer
    data-testid={dataTestId}
    onClick={onClick}
    onFocus={onFocus}
    onDragOver={onDragOver}
    onDragEnter={onDragEnter}
    onDragLeave={onDragLeave}
    onDrop={onDrop}
    draggable={draggable}
    $loading={loading}
    highlight={highlight}
    disabled={disabled}
    showBorderTop={showBorderTop}
    className={`${editable ? 'editable' : ''} ${isDraggedOver ? 'dragging-over' : ''}`}
  >
    <Box width="30px" css="flex: 1 0 30px">
      {prefixContent}
    </Box>
    <Box width="110px" flex={false} align="end">
      {dateContent}
    </Box>
    <Box flex={false}>{icon}</Box>
    {mainContentPrefix && <Box flex={false}>{mainContentPrefix}</Box>}
    <Box
      direction="row"
      gap="xxsmall"
      align="center"
      css={`
        flex: 1 1 ${integrationStatus ? (hasIntegrationError ? '35%' : '70%') : '100%'};
        position: relative;
      `}
    >
      {mainContent}
      <Box css="position: absolute; top: 18px;" data-testid="task-content-box">
        {mainContentSubLine}
      </Box>
    </Box>
    {integrationStatus && (
      <Box
        direction="row"
        gap="xsmall"
        align="center"
        css={`
          flex: 1 1 ${hasIntegrationError ? '65%' : '30%'};
          min-width: 160px;
        `}
      >
        {integrationStatus}
      </Box>
    )}
    <Box direction="row" gap="xsmall" align="center" css="flex: 0 0 auto;">
      {endContent}
      <Box
        width="32px"
        height="32px"
        alignSelf="center"
        align="center"
        justify="center"
        flex={false}
        css={`
          .dragging-user .editable & {
            > div {
              opacity: 0.3;
            }
            border-radius: 50%;
            outline: 2px dashed ${themeColor('text-disabled')};
          }
          .dragging-over.editable & {
            outline: 2px dashed ${themeColor('text')};
          }
        `}
      >
        {suffixContent}
      </Box>
    </Box>
  </TaskItemContainer>
)

const TaskItemContainer = styled(Box).attrs(() => ({
  align: 'center',
  direction: 'row',
  gap: 'xsmall',
  height: '52px',
  pad: { right: 'xsmall', top: 'xsmall', bottom: 'xsmall' }
}))<{ showBorderTop?: boolean; $loading?: boolean; highlight?: boolean; disabled?: boolean; hideStageIcon?: boolean }>`
  background-color: ${({ highlight, theme }) => highlight && resolveColor('primary-bg', theme)} !important;
  border-top: ${({ showBorderTop, theme }) => showBorderTop && `1px solid ${resolveColor('bg-2', theme)}`};
  margin-right: 16px;
  opacity: ${({ $loading, disabled }) => ($loading || disabled) && 0.5};
  outline: none;
  flex: 0 0 auto;

  .task-item-display-on-task-hover {
    display: none;
  }

  .task-item-remove-on-task-hover {
    display: block;
  }

  &:hover {
    background-color: ${({ theme }) => resolveColor('bg-1', theme)};

    .task-item-bg {
      background-color: ${({ theme }) => resolveColor('bg-1', theme)};
    }

    .task-item-display-on-task-hover {
      display: block;
    }

    .task-item-remove-on-task-hover {
      display: none;
    }
  }
`
