import { eventManager } from 'event-manager'

import { Avatar, AvatarButton, Box, SidebarIconButton, Tooltip } from '@cutover/react-ui'
import { useSidebarNavContext } from './nav-context'
import { useCurrentUser } from 'main/recoil/data-access'
import { useGlobalConfig, useLanguage } from 'main/services/hooks'

export const Footer = ({
  hasSettings,
  toggleSettings,
  isShowingSettings
}: {
  hasSettings: boolean
  toggleSettings: (showSettings?: boolean) => void
  isShowingSettings: boolean
}) => {
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'footer' })
  const { leftSidebarSize, toggleLeftSidebar } = useSidebarNavContext()

  const config = useGlobalConfig()
  const hasIntercom = !!config.intercomAppId

  const currentUser = useCurrentUser()

  const handleClickUserAvatar = () => {
    eventManager.emit('angular-toggle-user-profile')
  }

  const handleClickSettings = () => {
    toggleSettings()

    if (leftSidebarSize === 'small') {
      toggleLeftSidebar()
    }
  }

  const handleClickLogout = () => {
    eventManager.emit('angular-logout')
  }

  const handleClickHelp = () => {
    window.open('https://help.cutover.com', '_blank')
  }

  return (
    <Box
      direction={leftSidebarSize === 'small' ? 'column-reverse' : 'row'}
      height={leftSidebarSize === 'small' ? '192px' : '64px'}
      justify="between"
      responsive={false}
      align="center"
      pad="12px"
      css={`
        min-height: ${leftSidebarSize === 'small' ? '192px' : '64px'};
      `}
    >
      <Tooltip content={t('tooltip.profile')} placement="right" disableOnClickAsTriggerProp>
        <AvatarButton onClick={handleClickUserAvatar} label={<Avatar subject={currentUser} size="small" />} />
      </Tooltip>
      <Box direction={leftSidebarSize === 'small' ? 'column' : 'row'} gap="2px">
        {hasSettings && (
          <SidebarIconButton
            tipPlacement={leftSidebarSize === 'small' ? 'right' : 'top'}
            isActive={isShowingSettings}
            onClick={handleClickSettings}
            icon="settings"
            label={t('tooltip.settings')}
          />
        )}
        <SidebarIconButton
          tipPlacement={leftSidebarSize === 'small' ? 'right' : 'top'}
          onClick={!hasIntercom ? handleClickHelp : undefined}
          icon="help"
          id="help"
          label={t('tooltip.help')}
        />
        <SidebarIconButton
          tipPlacement={leftSidebarSize === 'small' ? 'right' : 'top'}
          onClick={handleClickLogout}
          icon="logout"
          label={t('tooltip.logout')}
        />
      </Box>
    </Box>
  )
}
