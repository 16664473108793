import { useCallback, useEffect, useMemo, useState } from 'react'

import { toCamelCase } from '@cutover/api'
import { IntegrationStatusProps } from '@cutover/react-ui'
import { IntegrationConnectionConfig, useGlobalConfig } from 'main/services/hooks'
import {
  INTEGRATION_FINISHED_STATUSES,
  IntegrationFinishedStatus,
  IntegrationStatus,
  TaskListTask
} from 'main/services/queries/types'
import { RunbookViewModel, TaskTypeModel } from 'main/data-access'

const INTEGRATION_STATUS_COLOR_LOOKUP: Record<IntegrationFinishedStatus, string> = {
  success: 'success',
  failed: 'error',
  cancelled: 'warning'
}

export const useTaskListItemIntegrationStatus = (task: TaskListTask) => {
  const request = RunbookViewModel.get('integrationRequest')
  const removeRequest = RunbookViewModel.onAction('integrationRequest:remove')
  const taskType = TaskTypeModel.get(task.task_type_id)

  const { integrations } = useGlobalConfig()

  const tryResetRequest = useCallback(() => {
    const hasRequest = request?.hasOwnProperty(task.id)
    if (!hasRequest) return

    const lastEvent = task.integration_events.length > 0 && task.integration_events[task.integration_events.length - 1]

    if (
      (request[task.id] === 'refire' && !INTEGRATION_FINISHED_STATUSES.includes(lastEvent?.status?.toLowerCase())) ||
      (request[task.id] === 'cancel' && lastEvent?.status?.toLowerCase() === 'cancelled')
    ) {
      removeRequest(task.id)
    }
  }, [request, task])

  const memoizedResult = useMemo(() => {
    const actionItem = taskType.integration_action_items[0]
    const action = actionItem?.integration_action

    const connectionConfig = (action &&
      integrations.filter(integration =>
        integration.klass.startsWith(action.substring(0, action.lastIndexOf('::')))
      )[0]) as IntegrationConnectionConfig

    const actionItemConfig = connectionConfig?.actions.find(act => act.klass === action)

    const options = {
      ...actionItemConfig?.options,
      ...toCamelCase(actionItem?.option_overrides)
    }

    const lastEvent = task.integration_events.length > 0 && task.integration_events[task.integration_events.length - 1]

    const hasRequest = request?.hasOwnProperty(task.id)
    const eventStatus: IntegrationStatus =
      hasRequest || (!lastEvent && task.stage === 'in-progress') ? 'connect' : lastEvent?.status?.toLowerCase()
    const status = actionItemConfig?.statusMessages[eventStatus]
    let statusProps: IntegrationStatusProps | undefined = undefined

    if (status) {
      statusProps = {
        status: status,
        color: INTEGRATION_STATUS_COLOR_LOOKUP[eventStatus as IntegrationFinishedStatus] || 'text-light',
        error: hasRequest ? undefined : lastEvent?.error_reason
      }
    }

    return {
      integrationActionItem: actionItem,
      integrationOptions: options,
      integrationStatusProps: statusProps,
      integrationEventStatus: eventStatus
    }
  }, [taskType, integrations, task, request])

  const [result, setResult] = useState<typeof memoizedResult | null>(null)

  useEffect(() => {
    tryResetRequest()
    setResult(memoizedResult)
  }, [memoizedResult, tryResetRequest])

  return result
}
