import { memo, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { keyBy, unescape } from 'lodash'

import { Box, IconButton, ListItem, Pill } from '@cutover/react-ui'
import {
  sortFiltersDefaultFirst,
  useApplySavedFilter,
  useCurrentUserId,
  useDefaultSavedFilter,
  useRemoveSavedFilterFromGroup,
  useSavedFilterState,
  useToggleDefaultSavedFilter,
  useToggleGlobalSavedFilter
} from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { FormModal } from 'main/components/shared/form'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { SavedFilter } from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModel } from 'main/data-access'

export const SavedFiltersGroup = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters' })
  const [savedFilters, setSavedFilters] = useSavedFilterState()
  const defaultFilter = useDefaultSavedFilter()

  useEffect(() => {
    setSavedFilters(sortFiltersDefaultFirst(savedFilters))
  }, [])

  return savedFilters?.length ? (
    <FilterAccordionPanel label={t('title')}>
      <SavedFilterGroupList filters={savedFilters} defaultId={defaultFilter?.id} />
    </FilterAccordionPanel>
  ) : null
})

/* Group content - saved filter list */

const SavedFilterGroupList = ({ filters, defaultId }: { filters: SavedFilter[]; defaultId?: number }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters' })
  const filterLookup = keyBy(filters, 'id')
  const orderedIds = filters.map(f => f.id)
  // @ts-ignore - location state isn't typed which makes this rename not work
  const { state: navigationState } = useLocation()
  const [replaceDefaultModalOpen, setReplaceDefaultModalOpen] = useState(false)
  const [changeToDefaultFilterId, setChangeToDefaultFilterId] = useState<number | null>(null)

  const initialActiveId = navigationState?.navDefault ? defaultId ?? null : null
  const stateActiveFilterId = navigationState?.activeFilterId ?? null
  const activeFilterId = navigationState?.navDefault ? initialActiveId : stateActiveFilterId

  const currentUserId = useCurrentUserId()
  const canUpdate = ActiveRunbookModel.can('update')

  const handleClickApplyFilter = useApplySavedFilter()
  const handleClickDelete = useRemoveSavedFilterFromGroup()
  const handleClickToggleDefault = useToggleDefaultSavedFilter()
  const handleClickToggleGlobal = useToggleGlobalSavedFilter()

  const onClickDefault = useCallback(
    (filterId: number) => {
      if (defaultId && defaultId !== filterId) {
        setChangeToDefaultFilterId(filterId)
        setReplaceDefaultModalOpen(true)
      } else {
        handleClickToggleDefault(filterId)
      }
    },
    [defaultId, handleClickToggleDefault]
  )

  return (
    <Box role="list" a11yTitle={t('listName')}>
      {orderedIds.map(id => {
        const filter = filterLookup[id]

        return (
          <SavedFilterGroupListItem
            key={id}
            filterId={filter.id}
            name={unescape(filter.name)}
            isSelected={filter.id === activeFilterId}
            isDefault={filter.id === defaultId}
            isGlobal={canUpdate ? filter.global : null}
            onClickFilter={handleClickApplyFilter}
            onClickSetDefault={canUpdate ? onClickDefault : undefined}
            onClickSetGlobal={canUpdate ? handleClickToggleGlobal : undefined}
            onClickDelete={canUpdate || filter.user_id === currentUserId ? handleClickDelete : undefined}
          />
        )
      })}

      {replaceDefaultModalOpen && (
        <ReplaceSavedFilterDefaultModal
          closeModal={() => {
            setReplaceDefaultModalOpen(false)
            setChangeToDefaultFilterId(null)
          }}
          onClickSave={async () => {
            if (changeToDefaultFilterId) {
              await handleClickToggleDefault(changeToDefaultFilterId)
            }
            setReplaceDefaultModalOpen(false)
          }}
        />
      )}
    </Box>
  )
}

const ReplaceSavedFilterDefaultModal = ({
  closeModal,
  onClickSave
}: {
  closeModal: () => void
  onClickSave: () => Promise<void>
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters.confirmReplaceDefaultModal' })

  // Using form modal because it handles async
  return (
    <FormModal
      formElementWrapper={false}
      description={t('message')}
      title={t('title')}
      onClose={closeModal}
      focusConfirmButton
      open
      confirmText={t('confirm')}
      onSubmit={async () => {
        await onClickSave()
      }}
    />
  )
}

/* Individual saved filter item */

type SavedFilterGroupListItemProps = {
  filterId: number
  name: string
  isSelected: boolean | null
  isDefault: boolean | null
  isGlobal: boolean | null
  onClickFilter: (filterId: number) => void
  onClickDelete?: (filterId: number) => void
  onClickSetDefault?: (filterId: number) => void
  onClickSetGlobal?: (filterId: number) => void
}

const SavedFilterGroupListItem = memo(
  ({
    filterId,
    name,
    isSelected,
    isDefault,
    isGlobal,
    onClickFilter,
    onClickDelete,
    onClickSetDefault,
    onClickSetGlobal
  }: SavedFilterGroupListItemProps) => {
    const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters.actionButtonLabels' })

    const isGlobalDefault = isGlobal && isDefault

    return (
      <ListItem
        role="listitem"
        a11yTitle={name}
        endComponents={[
          ...(isDefault ? [<Pill label={t('defaultPill')} color="text-light" size="small" />] : []),
          ...(onClickSetDefault
            ? [
                <IconButton
                  label={t('default', { context: isDefault ? 'unset' : 'set' })}
                  tipPlacement="top"
                  icon={isDefault ? 'pin-filled' : 'pin'}
                  onClick={e => {
                    e.stopPropagation()
                    onClickSetDefault(filterId)
                  }}
                  size="small"
                />
              ]
            : []),
          ...(onClickSetGlobal
            ? [
                <IconButton
                  css={`
                    &:hover {
                      background: ${isGlobalDefault && 'transparent'};
                      cursor: ${isGlobalDefault && 'default'};
                    }
                  `}
                  label={t('global', { context: isGlobalDefault ? 'default' : isGlobal ? 'private' : 'public' })}
                  tipPlacement="top"
                  icon={isGlobal ? 'star-filled' : 'star'}
                  onClick={e => {
                    e.stopPropagation()

                    if (!isGlobalDefault) {
                      onClickSetGlobal(filterId)
                    }
                  }}
                  size="small"
                />
              ]
            : [])
        ]}
        onClick={() => onClickFilter(filterId)}
        size="small"
        active={!!isSelected}
        prominence={isSelected ? 'high' : undefined} // Hack until design change as above, should use 'selected' prop
        title={name}
        onClickRemove={onClickDelete ? () => onClickDelete(filterId) : undefined}
        onClickRemoveTooltip={t('remove')}
        onClickRemoveTooltipPlacement="top"
      />
    )
  }
)
