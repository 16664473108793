import { Ref, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useMeasure, useWindowSize } from 'react-use'
import { useMergeRefs } from 'use-callback-ref'
import { format } from 'date-fns'
import {
  arrow as arrowMiddleware,
  autoUpdate,
  computePosition,
  FloatingArrow,
  FloatingPortal,
  useFloating,
  useInteractions,
  useRole
} from '@floating-ui/react'

import { Box, IconButton, OverlayBox, resolveColor, Text, useTheme } from '@cutover/react-ui'
import { StreamListStream, TaskListTask, TaskType } from 'main/services/queries/types'
import { useNodeMapController } from './node-map-controller'
import { INFO_DIALOG_WIDTH } from './node-map-state'
import { InfoDialogPosition, Position, ViewportDimensions } from './node-map-types'
import { useLanguage } from 'main/services/hooks'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { buildCriticalPath } from 'main/services/tasks/critical-path'
import { TaskLineItem } from 'main/components/shared/task-line-item'
import { formatTimeDifference } from './node-map-utils'
import {
  useActiveRightPanelValue,
  useAppliedFilters,
  useFilteredTasksState,
  useTaskListLookupState,
  useTaskListTask
} from 'main/recoil/data-access'
import { ActiveRunModel, CurrentRunbookVersionModel, StreamModel, TaskTypeModel } from 'main/data-access'

const NODE_MAP_HEADERS_HEIGHT = 200

export const NodeMap = () => {
  const taskLookup = useTaskListLookupState()
  const streamLookup = StreamModel.getLookup()
  const taskTypeLookup = TaskTypeModel.getLookup()
  const filteredTasks = useFilteredTasksState()
  const filteredTaskIds = filteredTasks.map(task => task.id)

  return (
    taskLookup &&
    streamLookup &&
    taskTypeLookup && (
      <NodeMapInner
        taskLookup={taskLookup}
        filteredTaskIds={filteredTaskIds}
        streamLookup={streamLookup}
        taskTypeLookup={taskTypeLookup}
      />
    )
  )
}

const NodeMapInner = ({
  taskLookup,
  filteredTaskIds,
  streamLookup,
  taskTypeLookup
}: {
  taskLookup: Record<string, TaskListTask>
  filteredTaskIds: number[]
  streamLookup: Record<number, StreamListStream>
  taskTypeLookup: Record<number, TaskType>
}) => {
  const theme = useTheme()
  const filterState = useAppliedFilters()
  const [nodeMapContainerRef, { width: viewportWidth, height: viewportHeight }] = useMeasure()
  const nodeMapContainerNativeRef = useRef<HTMLElement | null>(null)
  const { height } = useWindowSize()
  const rightPanelValue = useActiveRightPanelValue()
  const nodeMapController = useNodeMapController()
  const scrollSize = height - NODE_MAP_HEADERS_HEIGHT

  const left = nodeMapContainerNativeRef.current?.getBoundingClientRect().left
  const top = nodeMapContainerNativeRef.current?.getBoundingClientRect().top

  const dimensions: ViewportDimensions = useMemo(
    () => ({
      left: left ?? 0,
      top: top ?? 0,
      width: viewportWidth - 16,
      height: viewportHeight + 64,
      devicePixelRatio: window.devicePixelRatio ? window.devicePixelRatio : 1,
      ratio: viewportWidth / viewportHeight
    }),
    [viewportWidth, viewportHeight]
  )

  /* ------------------------------- Info Dialog ------------------------------ */

  const [infoTask, setInfoTask] = useState<TaskListTask | undefined>(undefined)
  const [dialogPosition, setDialogPosition] = useState<InfoDialogPosition>({ x: 0, y: 0, placement: 'right' })
  const [selectedDependency, setSelectedDependency] = useState<number | null>(null)
  const arrowRef = useRef(null)

  const dialogMiddleware = (position: Position) => ({
    name: 'tooltipMiddleware',
    fn() {
      return {
        x: position.x,
        y: position.y
      }
    }
  })

  const { refs, isPositioned, context } = useFloating({
    placement: 'right-start',
    open: !!infoTask,
    middleware: [arrowMiddleware({ element: arrowRef })],
    whileElementsMounted: autoUpdate
  })
  const role = useRole(context, { role: 'dialog' })
  const { getReferenceProps, getFloatingProps } = useInteractions([role])

  useEffect(() => {
    const referenceEl = refs.reference.current
    const floatingEl = refs.floating.current
    if (referenceEl && floatingEl && !!infoTask && isPositioned) {
      const cleanup = autoUpdate(referenceEl, floatingEl, () => {
        computePosition(referenceEl, floatingEl, {
          middleware: [dialogMiddleware(dialogPosition), arrowMiddleware({ element: arrowRef })]
        }).then(({ x, y }) => {
          Object.assign(floatingEl.style, {
            left: `${x}px`,
            top: `${y - 16}px`
          })
        })
      })

      return cleanup
    }
  }, [!!infoTask, dialogPosition, refs, isPositioned])

  const setInfoDialog = (id: number | undefined) => {
    setInfoTask(id ? taskLookup[id] : undefined)
  }

  const positionDialog = ({ x, y, placement }: InfoDialogPosition) => {
    setDialogPosition({ x, y, placement })
  }

  const onClickDependency = (id: number) => {
    setSelectedDependency(id)
  }

  /* ----------------------------- Update node map ---------------------------- */

  /**
   * Initial render to return the cleanup function
   */

  useEffect(() => {
    const cleanup = nodeMapController({
      taskLookup,
      filteredTaskIds,
      streamLookup,
      taskTypeLookup,
      filterState,
      dimensions,
      setInfoDialog,
      positionDialog,
      selectedDependency,
      setSelectedDependency,
      rightPanelValue
    })

    return () => {
      if (cleanup) {
        cleanup()
      }
    }
  }, []) // Empty dependency array ensures this effect runs only on mount and unmount

  /**
   * Re-render on props changed
   */
  useEffect(() => {
    if (dimensions.width > 0 && dimensions.height > 0) {
      nodeMapController({
        taskLookup,
        filteredTaskIds,
        streamLookup,
        taskTypeLookup,
        filterState,
        dimensions,
        setInfoDialog,
        positionDialog,
        selectedDependency,
        setSelectedDependency,
        rightPanelValue
      })
    }
  }, [
    dimensions,
    taskLookup,
    filteredTaskIds,
    streamLookup,
    taskTypeLookup,
    filterState,
    selectedDependency,
    rightPanelValue
  ])

  const mergedRef = useMergeRefs([
    nodeMapContainerNativeRef,
    nodeMapContainerRef as Ref<HTMLElement>,
    refs.setReference
  ])

  return (
    <Box
      ref={mergedRef}
      style={{ height: `${scrollSize}px` }}
      {...getReferenceProps()}
      css={`
        #maincanvas {
          box-shadow: none !important;
        }
      `}
    >
      <Box id="map" data-testid="map" />
      {!!infoTask && (
        <FloatingPortal>
          <OverlayBox
            {...getFloatingProps({
              ref: refs.setFloating
            })}
            css={`
              position: absolute;
              z-index: 2;
              border: 1px solid rgba(0, 0, 0, 0.05);
              overflow: visible;
              width: ${INFO_DIALOG_WIDTH}px;
              min-height: 100px;
            `}
          >
            <InfoDialogContent
              task={infoTask}
              taskLookup={taskLookup}
              streamLookup={streamLookup}
              onClickDependency={onClickDependency}
            />
            <FloatingArrow
              ref={arrowRef}
              context={context}
              fill="white"
              stroke={resolveColor('bg-2', theme)}
              strokeWidth={1}
              css={`
                top: 10px !important;
                ${dialogPosition.placement === 'left' &&
                `
                left: ${INFO_DIALOG_WIDTH - 4}px;
                transform: rotate(270deg) !important;
                `}
              `}
            />
          </OverlayBox>
        </FloatingPortal>
      )}
    </Box>
  )
}

type InfoDialogDependenciesContent = 'predecessors' | 'successors' | 'critical-path'

export const InfoDialogContent = ({
  task,
  taskLookup,
  streamLookup,
  onClickDependency
}: {
  task: TaskListTask
  taskLookup: Record<string, TaskListTask>
  streamLookup: Record<number, StreamListStream>
  onClickDependency: (id: number) => void
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'nodeMap' })
  const { dateLabel, diff } = useComputeInfoDialogLabel(task.id)
  const { openRightPanel } = useSetActiveRightPanelState()
  const [dependenciesContent, setDependenciesContent] = useState<InfoDialogDependenciesContent>('predecessors')

  const { taskIds: criticalPath } = buildCriticalPath(Object.values(taskLookup), {
    from: 0,
    to: task.internal_id,
    float: 0
  })
  const critialPathWithoutCurrentTask = criticalPath.filter(id => id !== task.id).reverse()

  const toggleDependenciesContent = (event: SyntheticEvent, content: InfoDialogDependenciesContent) => {
    event.preventDefault()
    event.stopPropagation()
    setDependenciesContent(content)
  }

  const handleClickEdit = () => {
    openRightPanel({ type: 'task-edit', taskId: task.id })
  }

  // Prevent event from propagating to canvas when clicking inside the dialog
  const handleMouseUp = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <Box direction="column" onMouseUp={handleMouseUp}>
      <Box onClick={handleClickEdit}>
        <Text>
          #{task.internal_id} {task.name}
        </Text>
        <Text color="text-light" size="small">
          {streamLookup[task.stream_id].name}
        </Text>
        <Box direction="row" gap="xsmall">
          <Text color="text-light" size="small">
            {dateLabel}
          </Text>
          {diff && (
            <Text color={diff.late ? 'warning' : 'success'} size="small">
              {diff.late ? '+' : '-'}
              {diff.label}
            </Text>
          )}
        </Box>
      </Box>
      <Box direction="row" gap="xsmall" css="margin: 8px 0;">
        <IconButton
          label={t('predecessors')}
          icon="predecessors"
          size="small"
          onClick={event => toggleDependenciesContent(event, 'predecessors')}
          isActive={dependenciesContent === 'predecessors'}
          disableTooltip
        />
        <IconButton
          label={t('successors')}
          icon="successors"
          size="small"
          onClick={event => toggleDependenciesContent(event, 'successors')}
          isActive={dependenciesContent === 'successors'}
          disableTooltip
        />
        <IconButton
          label={t('criticalPath')}
          icon="critical-path"
          size="small"
          onClick={event => toggleDependenciesContent(event, 'critical-path')}
          isActive={dependenciesContent === 'critical-path'}
          disableTooltip
        />
        <IconButton label={t('editTask')} icon="new-message" size="small" onClick={handleClickEdit} disableTooltip />
      </Box>
      <Box css="max-height: 122px; overflow: auto;">
        {dependenciesContent === 'predecessors' && (
          <>
            {task.predecessor_ids.length === 0 ? (
              <Text size="small" color="text-light">
                {t('noPredecessors')}
              </Text>
            ) : (
              <>
                {task.predecessor_ids.map(id => (
                  <TaskLineItem key={id} taskId={id} onClick={() => onClickDependency(id)} />
                ))}
              </>
            )}
          </>
        )}
        {dependenciesContent === 'successors' && (
          <>
            {task.successor_ids.length === 0 ? (
              <Text size="small" color="text-light">
                {t('noSuccessors')}
              </Text>
            ) : (
              <>
                {task.successor_ids.map(id => (
                  <TaskLineItem key={id} taskId={id} onClick={() => onClickDependency(id)} />
                ))}
              </>
            )}
          </>
        )}
        {dependenciesContent === 'critical-path' && (
          <>
            {critialPathWithoutCurrentTask.length === 0 ? (
              <Text size="small" color="text-light">
                {t('noCriticalPath')}
              </Text>
            ) : (
              <Box direction="column">
                {critialPathWithoutCurrentTask.map(id => (
                  <TaskLineItem key={id} taskId={id} onClick={() => onClickDependency(id)} css="height: 30px;" />
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

type DurationDiff =
  | {
      label: string
      late: boolean
    }
  | undefined

const useComputeInfoDialogLabel = (id: number): { dateLabel: string; diff: DurationDiff } => {
  const { t } = useLanguage('runbook', { keyPrefix: 'nodeMap' })
  const run = ActiveRunModel.get()
  const { timing_mode: timingMode } = CurrentRunbookVersionModel.get()
  const {
    stage,
    completion_type: completionType,
    start_display: startDisplay,
    start_latest_planned: startLatestPlanned,
    end_display: endDisplay,
    duration
  } = useTaskListTask(id)

  let dateLabel = ''
  let diff: DurationDiff = undefined

  const taskStartDiff =
    startLatestPlanned && (stage === 'in-progress' || stage === 'complete')
      ? startDisplay - startLatestPlanned
      : undefined

  const taskEndDiff =
    startLatestPlanned && stage === 'complete' ? endDisplay - (startLatestPlanned + duration) : undefined

  if (timingMode === 'unscheduled' && run?.run_type !== 'rehearsal') {
    dateLabel = ''
  } else {
    switch (stage) {
      case 'default':
        dateLabel = t('forecastStart', { date: format(startDisplay * 1000, 'd MMM HH:mm') })
        break
      case 'startable':
        dateLabel = t('startableNow')
        break
      case 'in-progress':
        dateLabel = t('startedDate', { date: format(startDisplay * 1000, 'd MMM HH:mm') })
        diff = formatTimeDifference(taskStartDiff)
        break
      case 'complete':
        switch (completionType) {
          case 'complete_abandoned':
            dateLabel = t('abandonedDate', { date: format(endDisplay * 1000, 'd MMM HH:mm') })
            diff = formatTimeDifference(taskEndDiff)
            break
          case 'complete_skipped':
            dateLabel = t('skippedDate', { date: format(endDisplay * 1000, 'd MMM HH:mm') })
            diff = formatTimeDifference(taskEndDiff)
            break
          default:
            dateLabel = t('completedDate', { date: format(endDisplay * 1000, 'd MMM HH:mm') })
            diff = formatTimeDifference(taskEndDiff)
        }
        break
      default:
        dateLabel = ''
    }
  }

  return {
    dateLabel,
    diff
  }
}
