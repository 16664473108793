import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook, RunbookTypeType } from 'main/services/queries/types'

type UseDuplicateRunbooksValidationProps = {
  context: 'single' | 'bulk-single' | 'bulk'
  singleRunbook?: RunbookListRunbook
  runbookIds?: number[] | null
  runbookType?: RunbookTypeType
  templateType?: string
}

export const useDuplicateRunbooksValidation = ({
  context,
  singleRunbook,
  runbookIds,
  runbookType,
  templateType
}: UseDuplicateRunbooksValidationProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })
  const { runbooks, runbookLookup } = useWorkspaceData()
  const { disabledRunbookTypes } = useAccountRunbookTypes()
  const isSnippet = templateType === 'snippet'

  const isSingleRunbookTemplateNotApproved =
    context === 'single' && singleRunbook?.is_template && singleRunbook.template_status !== 'template_approved'

  const isBulkRunbooksTemplateNotApproved = () => {
    return (
      context !== 'single' &&
      runbooks?.some(r => runbookIds?.includes(r.id) && r.is_template && r.template_status !== 'template_approved')
    )
  }

  const isSingleRunbookTypeDisabled = context === 'single' && !!runbookType?.disabled
  const isBulkRunbooksTypeDisabled = () => {
    return (
      context !== 'single' &&
      runbookIds?.some(runbookId => {
        const runbookTypeId = runbookLookup?.[runbookId]?.runbook_type_id
        if (!runbookTypeId) {
          return false
        }
        return disabledRunbookTypes?.map(rbt => rbt.id).includes(runbookTypeId)
      })
    )
  }

  const handleErrors = () => {
    return [
      context !== 'single' && runbookIds && runbookIds.length > 100 && t('runbookLimitExceededError'),
      !isSnippet && (isSingleRunbookTypeDisabled || isBulkRunbooksTypeDisabled()) && t('disabledRunbookTypeError')
    ].filter(Boolean) as string[]
  }

  const errorMessage = handleErrors()

  const warningMessage =
    !errorMessage.length && (isSingleRunbookTemplateNotApproved || isBulkRunbooksTemplateNotApproved())
      ? [t('unapprovedTemplateWarning')]
      : undefined

  return {
    errorMessage,
    warningMessage
  }
}
