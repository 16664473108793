import * as yup from 'yup'

import { useLanguage } from 'main/services/hooks'

const certificateRegex =
  /^(?:(?!-{3,}(?:BEGIN|END) CERTIFICATE)[\s\S])*(-{3,}BEGIN CERTIFICATE(?:(?!-{3,}END CERTIFICATE)[\s\S])*?-{3,}END CERTIFICATE-{3,})(?![\s\S]*?-{3,}BEGIN CERTIFICATE[\s\S]+?-{3,}END CERTIFICATE[\s\S]*?$)/

export const samlConfigFormValidationSchema = (t: typeof useLanguage, mode?: string, selectedSource?: string) => {
  return yup.object().shape({
    _step: yup.number().notRequired(),
    idp_sso_service_url: yup.string().when('_step', {
      is: (_step?: number) => ['manualEntry', undefined].includes(selectedSource),
      then: schema =>
        schema
          .required(t('samlConfigurations.formData.idpSsoServiceServiceUrl.errors.requiredError'))
          .matches(/^http/, t('samlConfigurations.formData.idpSsoServiceServiceUrl.errors.regexError')),
      otherwise: schema => schema.notRequired()
    }),
    allowed_clock_drift: yup.number().when('_step', {
      is: (_step?: number) => ['manualEntry', undefined].includes(selectedSource),
      then: schema => schema.required().typeError(t('samlConfigurations.formData.allowedClockDrift.errors.typeError')),
      otherwise: schema => schema.notRequired()
    }),
    name: yup.string().when('_step', {
      is: (_step?: number) => ['manualEntry', undefined].includes(selectedSource),
      then: schema =>
        schema
          .required(t('samlConfigurations.formData.displayName.errors.requiredError'))
          .max(16, t('samlConfigurations.formData.displayName.errors.lengthError')),
      otherwise: schema => schema.notRequired()
    }),
    idp_cert_multi: yup.object().when('_step', {
      is: (_step?: number) => ['manualEntry', undefined].includes(selectedSource),
      then: schema =>
        schema.shape({
          signing: yup
            .array()
            .of(yup.string())
            .min(1, t('samlConfigurations.formData.idpCertMulti.errors.missingError'))
        }),
      otherwise: schema => schema.notRequired()
    }),
    certificate: yup.string().when('_step', {
      is: (_step?: number) => ['manualEntry', undefined].includes(selectedSource),
      then: schema =>
        schema.matches(certificateRegex, t('samlConfigurations.formData.idpCertMulti.errors.formatError')),
      otherwise: schema => schema.notRequired()
    }),
    custom_acs_url:
      mode === 'edit'
        ? yup.string().when('_step', {
            is: (_step?: number) => ['manualEntry', undefined].includes(selectedSource),
            then: schema =>
              schema.matches(/^http/, t('samlConfigurations.formData.customAcsUrl.errors.httpRegexError')),
            otherwise: schema => schema.notRequired()
          })
        : yup.string().notRequired(),
    sp_entity_id:
      mode === 'edit'
        ? yup.string().when('_step', {
            is: (_step?: number) => ['manualEntry', undefined].includes(selectedSource),
            then: schema => schema.matches(/^http/, t('samlConfigurations.formData.spEntityId.errors.httpRegexError')),
            otherwise: schema => schema.notRequired()
          })
        : yup.string().notRequired(),
    default: yup.bool().when('_step', {
      is: (_step?: number) => ['manualEntry', undefined].includes(selectedSource),
      then: schema => schema.required(),
      otherwise: schema => schema.notRequired()
    }),
    url: yup.string().notRequired(),
    rawText: yup.string().notRequired(),
    files: yup.mixed().notRequired()
  })
}
