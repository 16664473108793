import { useMatch } from 'react-router-dom'

import { useFeature } from 'main/services/hooks'

export const TASK_LIST_MIGRATION_ROUTE = 'react-list'
export const NODE_MAP_MIGRATION_ROUTE = 'react-map'
export const RUNBOOK_DASHBOARD_MIGRATION_ROUTE = 'react-dashboard'
export const WORKSPACE_RUNBOOKS_MIGRATION_ROUTES = ['list', 'table', 'dashboard', 'timeline'] // 'list', 'timeline', 'table', 'dashboard'
export const RUNBOOK_MIGRATION_ROUTES = [
  TASK_LIST_MIGRATION_ROUTE,
  NODE_MAP_MIGRATION_ROUTE,
  RUNBOOK_DASHBOARD_MIGRATION_ROUTE
]

export const useIsDatasourcesMatch = () => {
  const routeDatasourceMatch = useMatch({ path: 'app/settings/data-sources', end: false })
  return routeDatasourceMatch?.pathname === '/app/settings/data-sources'
}

export const useIsRoleMappingsMatch = () => {
  const roleMappingMatch = useMatch({ path: 'app/settings/role_mappings' })
  return roleMappingMatch?.pathname === '/app/settings/role_mappings'
}

export const useIsMyTasksMatch = () => {
  const { isEnabled } = useFeature()
  const isMyTasksEnabled = isEnabled('my_tasks')
  const myTasksMatch = useMatch({ path: 'app/my-tasks' })

  return isMyTasksEnabled && myTasksMatch?.pathname === '/app/my-tasks'
}

export const useIsConnectSettingsMatch = () => {
  const { isEnabled } = useFeature()
  const routeConnectMatch = useMatch({ path: 'app/settings/cutover-connect', end: false })
  return isEnabled('connect_settings') && routeConnectMatch?.pathname === '/app/settings/cutover-connect'
}

export const useSamlMatch = () => {
  const routeSamlMatch = useMatch({ path: 'app/settings/saml', end: false })
  return routeSamlMatch?.pathname === '/app/settings/saml'
}

export const useIsIntegrationConnectionsMatch = () => {
  const routeDatasourceMatch = useMatch({ path: 'app/settings/integration_connections', end: false })
  return routeDatasourceMatch?.pathname === '/app/settings/integration_connections'
}

export const useIsSystemParametersMatch = () => {
  const { isEnabled } = useFeature()
  const routeSystemParamsMatch = useMatch({ path: 'app/settings/system-parameters', end: false })
  return isEnabled('cutover_parameter_store') && routeSystemParamsMatch?.pathname === '/app/settings/system-parameters'
}

export const useIsAppPageMatch = () => {
  const routeAppPageMatch = useMatch({ path: 'app/integration_action_item', end: false })
  return routeAppPageMatch?.pathname === '/app/integration_action_item'
}

export const useIsReactMigrationWorkspaceMatch = () => {
  const { isEnabled } = useFeature()
  const isReactWorkspaceEnabled = isEnabled('react_workspace')

  // end is false because dashboard routes are dashboard/:dashboardId
  const workspaceRunbooksMatch = useMatch({ path: '/app/:accountSlug/runbooks/:workspaceRunbooksPage', end: false })
  // NOTE: for now, we are only migrating the runbook/template/snippet pages of the workspace
  // const centralTeamsMatch = useMatch('app/:accountSlug/settings/teams')
  const workspaceRunbooksDisplay = workspaceRunbooksMatch?.params.workspaceRunbooksPage
  return (
    isReactWorkspaceEnabled &&
    workspaceRunbooksDisplay &&
    WORKSPACE_RUNBOOKS_MIGRATION_ROUTES.includes(workspaceRunbooksDisplay)
  )
}

export const useIsReactMigrationRunbookMatch = () => {
  const { isEnabled } = useFeature()
  const isReactRunbookEnabled = isEnabled('react_runbook')
  // NOTE: will not match ../:runbookVersionId/page/content/:contentId. Need to add another matcher for that to migrate it.
  const runbookMatch = useMatch({
    path: '/app/:accountSlug/runbooks/:runbookId/:runbookVersionId/tasks/:runbookPage',
    end: false
  })
  const runbooksDisplay = runbookMatch?.params.runbookPage
  return isReactRunbookEnabled && runbooksDisplay && RUNBOOK_MIGRATION_ROUTES.includes(runbooksDisplay)
}

export const useIsReactMigrationDefaultFilterRoute = () => {
  const { isEnabled } = useFeature()
  const isReactRunbookEnabled = isEnabled('react_runbook')

  // Will want to update this to any react specific routes which we want forward to a default filter
  const runbookMatch = useMatch({
    path: `/app/:accountSlug/runbooks/:runbookId/:runbookVersionId/tasks/${TASK_LIST_MIGRATION_ROUTE}`,
    end: true
  })

  return isReactRunbookEnabled && !!runbookMatch
}
